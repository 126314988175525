<template>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
        <v-card>

            <card-title
              :title="title"
            />
            <v-card-text
              class="pa-4 black--text py-8"
            >
                <p
                  class="text-center"
                  v-html="message"
                />
                <v-row v-if="options.changesPending">
                    <v-col>
                        <v-row
                          v-for="(prop_id, index) in Object.keys(options.changesPending)"
                          :key="index"
                          class="justify-space-between cb-row-bordered mb-2 py-1"
                        >
              <span
                v-if="options.propsName && options.propsName.find(p => p.id == prop_id) != null"
                class="my-auto"
              >{{options.propsName.find(p => p.id == prop_id).name}}</span>

                            <span
                              v-else
                              class="my-auto"
                            >{{prop_id}}</span>
                            <span
                              class="font-weight-medium my-auto text-truncate"
                              style="max-width: 70%"
                            >{{
                                    options.changesPending[prop_id]
                                }}</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row
                  v-if="options.password"
                  class="mt-4"
                  justify="center"
                >
                    <v-col class="text-center">
                        <span>Esta acción requiere confirmación con contraseña, introdúzcala para continuar:</span>
                        <v-row justify="center">
                            <v-col cols="8">
                                <v-text-field
                                  v-model="password"
                                  autocomplete="off"
                                  class="mt-2"
                                  outlined
                                  placeholder="Contraseña"
                                  type="password"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <dialog-card-footer
              :close-button-custom-icon="options.cancelIcon"
              :close-button-custom-text="options.cancelText"
              :close-button-visible="true"
              :save-button-color="options.saveColor"
              :save-button-custom-icon="options.saveIcon"
              :save-button-custom-text="options.saveText"
              :save-button-disabled="options.password == true && helpers.nullOrEmpty(password) "
              :save-button-visible="true"
              @close="cancel"
              @save="agree"
            />
        </v-card>
    </v-dialog>
</template>

<script>
import DialogCardFooter from '@/components/repository/dialogs/DialogCardFooter'
import helpers from '@/plugins/helpers'
import CardTitle from '@/components/repository/cards/CardTitle'
import service_user from '@/services/service_user'

export default {
    name: 'ConfirmDialog',
    components: {CardTitle, DialogCardFooter},
    data() {
        return {
            helpers,
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                width: 600,
                zIndex: 200,
                noconfirm: false,
                saveText: 'Guardar',
                saveIcon: 'save',
                saveColor: 'primary',
                cancelText: 'Cerrar',
                cancelIcon: 'clear'
            },
            password: ''
        }
    },
    computed: {},
    methods: {
        open(title, message, options) {
            this.dialog = true
            this.title = title
            this.message = message
            this.options = Object.assign(this.options, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            if (this.options.password == true) {
                service_user.checkPassword(this.password).then((value) => {
                    if (value) {
                        this.resolve(true)
                        this.dialog = false
                    }
                })
            } else {
                this.resolve(true)
                this.dialog = false
            }
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        },
    },
}
</script>