<template>

  <v-col>
    <!-- Image -->
    <v-img
      v-if="file.fileType.includes('image')"
      :key="content"
      :src="displayContent"
      contain
      max-height="740px"
    ></v-img>

    <!-- PDF -->
    <div v-else-if="file.fileType.includes('pdf') && displayContent">
      <v-overlay
        :value="loading"
        absolute="absolute"
      >
        <v-progress-circular
          color="red"
          indeterminate
        />
      </v-overlay>
      <v-row justify="center">
        <v-col>
          <vue-pdf-embed
            id="pdfcontainer"
            ref="pdfRef"
            :height="pdfHeight"
            :page="pdfPage"
            :source="displayContent"
            @rendered="handleDocumentRender"
          />
        </v-col>

        <v-col
          class="text-center"
          cols="12"
        >
          <v-icon
            :disabled="pdfPage == 1"
            large
            @click="pdfChangePage(false)"
          >chevron_left
          </v-icon>
          <v-icon
            :disabled="pdfPage == pageCount"
            large
            @click="pdfChangePage(true)"
          >chevron_right
          </v-icon>
        </v-col>
      </v-row>
    </div>

    <!-- HTML -->
    <div
      v-else-if="file.fileType.includes('html')"
      class="file-content"
    >
      <html-viewer :display-content="displayContent"/>
    </div>
    <!-- Text -->
    <div
      v-else-if="file.fileType.includes('text')"
      class="file-content"
    >
      <p
        v-for="(line, index) in displayContent.split('\n')"
        :key="index"
        class="p-0 my-0"
      >
        {{line}}
      </p>

    </div>

    <div
      v-else-if="file.fileType.includes('json')"
      class="file-content"
    >
      <v-jsoneditor :options="{mode:'code'}" :value="JSON.parse(displayContent)"
                    height="600px"/>

    </div>

    <div
      v-else
      style="height: 100%"
    >
      <v-row
        align="center"
        class="pa-0"
        justify="center"
        style="height: 100%; width: 100%"
      >
        <v-col>
          <v-row
            align="center"
            class="d-flex flex-column"
            dense
            justify="center"
          >
            <p>
              No se puede previsualizar el tipo de fichero seleccionado
            </p>
          </v-row>
        </v-col>
      </v-row>
    </div>

  </v-col>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import HtmlViewer from '@/components/repository/htmlViewer.vue'
import helpers from '@/plugins/helpers'

window.Buffer = window.Buffer || require("buffer").Buffer;

export default {
  name: 'FileViewer',
  components: {HtmlViewer, VuePdfEmbed},
  props: {
    file: {
      type: Object
    }
  },

  computed: {
    displayContent() {
      if (this.file.fileType.includes('pdf')) {
        return this._base64ToArrayBuffer(this.file.fileContent)
      }
      if (this.file.fileType.includes('text') || this.file.fileType.includes('json')) {
        return Buffer.from(this.file.fileContent, 'base64').toString('ascii')
      }

      return this.content
    },

    fileExtension() {
      return this.file.fileName.split('.')[1]
    },

    pdfHeight() {
      if (this.$vuetify.breakpoint.name == 'xl') {
        return 1024
      }

      if (this.$vuetify.breakpoint.name == 'lg') {
        return 840
      }

      if (this.$vuetify.breakpoint.name == 'md') {
        return 500
      }

      if (this.$vuetify.breakpoint.name == 'sm') {
        return 520
      }

      return 200
    }
  },

  data() {
    return {
      content: null,
      pdfPage: 1,
      pageCount: null,
      loading: false,
      height: 740
    }
  },

  created() {
    if (this.file.fileType.includes('image')) {
      this.content = `data:${this.file.fileType};base64,${this.file.fileContent}`
    }

    if (this.file.fileType.includes('text')) {
      this.content = this.file.fileContent
    }
  },

  watch: {
    file() {
      this.reset()
      if (this.file.fileType.includes('image')) {
        this.content = `data:${this.file.fileType};base64,${this.file.fileContent}`
      }
      this.loading = false
    }
  },

  methods: {
    _base64ToArrayBuffer(base64) {
      this.loading = true
      const binary_string = helpers.filecontent(base64)
      const len = binary_string.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
      }
      return new Uint8Array(bytes.buffer)
    },

    handleDocumentRender() {
      this.loading = false
      this.pageCount = this.$refs.pdfRef.pageCount
    },

    pdfChangePage(next) {
      if (next) {
        if ((this.pdfPage + 1) <= this.pageCount) {
          this.pdfPage++
        }

      } else {
        if ((this.pdfPage - 1) > 0) {
          this.pdfPage--
        }
      }
    },

    reset() {
      this.loading = true
      this.pdfPage = 1
      this.pageCount = null
    },


  }
}
</script>

<style
  lang="scss"
  scoped
>
</style>
