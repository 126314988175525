<template>

  <v-chip-group
    v-model="internalValue"
    :multiple="multiple"
    active-class="primary--text"
    mandatory
  >
    <v-chip
      v-for="item in items"
      :key="item_value != null ? item[item_value] : item"
      :color="item.color != null ? item.color : 'primary'"
      :value="item_value != null ? item[item_value] : item"
      active-class="font-weight-medium primary"
      label
      outlined
      small
    >
      <v-icon
        v-if="item.icon"
        :color="item.color != null ? item.color : 'primary'"
        class="mr-2"
        small
      >{{ item.icon }}
      </v-icon>
      {{ item_name != null ? item[item_name] : item }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import helpers from '@/plugins/helpers'

export default {
  name: 'fieldChipGroup',
  props: {
    items: Array,
    item_name: String,
    item_value: String,
    multiple: {
      default: false,
      type: Boolean
    },
    value: [String, Number, Array]
  },
  data() {
    return {
      internalValue: null
    }
  },
  watch: {
    internalValue(val) {
      if (typeof val != 'object' && helpers.nullOrEmpty(this.items.find(i => i[this.item_value] == val))) {
        val = null
      }
      this.$emit('input', val)
    }
  },
  created() {
    if (helpers.nullOrEmpty(this.value)) {
      this.internalValue = this.items[0][this.item_value]
    } else if (this.value instanceof Array) {
      this.internalValue = this.value
    } else {
      this.internalValue = this.value.toString()
    }
  }
}
</script>


