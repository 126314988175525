import helpers from '@/plugins/helpers'

const MAX_PROD_VERSION = 'max_prod'
const MAX_DEV_VERSION = 'max_dev'
const MAX_TEST_VERSION = 'max_test'
const VERSION_TAG = 'tag'

export default Object.freeze({
  ENTITY_NAME: 'template',
  CODE: 'code',
  DESCRIPTION: 'workflow_description',

  PROCESSES: 'processes',
  LATEST_VERSION: 'latest_version',

  TEMPLATE_STRING: 'templateString',

  VERSION_VERSION: 'version',
  VERSION_LAST_CHANGE: 'lastTagChange',
  VERSION_LAST_CHANGE_ALTERNATIVE: 'last_tag_change',
  VERSION_TAG,
  VERSION_UPLOADED_BY: 'uploaded_by',
  VERSION_CHANGELOG: 'changelog',
  MAX_PROD_VERSION,
  MAX_DEV_VERSION,
  MAX_TEST_VERSION,
  PARAM_NAME: 'name',
  PARAM_TYPE: 'type',
  PARAM_VALIDATIONS: 'validations',
  PARAM_DISPLAY_CERT: 'display_on_certificate',
  PARAM_PRETTY: 'pretty_print',
  PARAM_DEFAULT_VAL: 'test_default_value',

  getTemplateColor(template) {
    if (template[VERSION_TAG] == 'PRODUCCION') {
      return { name: 'PRO', color: 'error' }
    }
    if (template[VERSION_TAG] == 'PRUEBAS') {
      return { name: 'TEST', color: 'warning' }
    }
    return { name: 'DEV', color: 'info' }
  },
  getParamUsages(param_key, template) {
    return JSON.stringify(template).split('%%' + param_key + '%%').length - 1
  },
  getTypeColor(type) {
    switch (type) {
      case'string':
        return 'success'
      case 'file':
        return 'purple'
      case 'boolean':
        return 'warning'
      case 'list':
        return 'success'
      default: {
        return 'contrast_high'
      }
    }
  },
  getParamTypeFormatted(type) {
    switch (type) {
      case'string':
        return { color: 'success', name: 'ST' }
      case 'file':
        return { color: 'purple', name: 'FI' }
      case 'array_file':
        return { color: 'purple', name: 'AF' }
      case 'boolean':
        return { color: 'warning', name: 'BO' }
      case 'list':
        return { color: 'error', name: 'LI' }
      case 'email':
        return { color: 'orange', name: 'EM' }
      case 'mobile_number':
        return { color: 'blue', name: 'MN' }
      case 'numeric':
        return { color: 'red', name: 'NU' }
      case 'timeperiod':
        return { color: 'purple', name: 'TP' }
      default: {
        return { color: 'contrast_high', name: helpers.getInitials(type) }
      }
    }
  },
})
