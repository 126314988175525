<template>
  <v-col>

    <h1 class="title">¿Qué se puede hacer desde esta vista?</h1>
    <v-list
      class="mt-2 cb-help-list"

    >
      <v-list-item>
        <v-list-item-subtitle>
          <strong>Crear, editar y deshabilitar</strong> las unidades de facturación de las cuentas raíz
          del portal
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-subtitle>
          Generar <strong>informes</strong> personalizados con distintos filtros y campos
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
    <h1 class="title mt-4">¿Qué se debe tener en cuenta?</h1>
    <v-list
      class="mt-2 cb-help-list"

    >
      <v-list-item>
        <v-list-item-subtitle>
          Una cuenta raíz <strong class="error--text">no puede tener</strong> dos unidades de facturación con el
          <strong>mismo código</strong>
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-col>
</template>

<script>

export default {
  name: 'BillingUnitsInfo',
}
</script>


