/**
 * Site Name
 * @type {string}
 */
// eslint-disable-next-line no-unused-vars

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueRouter from 'vue-router'
import router from './router'
import Axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import TreeView from 'vue-json-tree-view'
import VueClipboard from 'vue-clipboard2'
import store from './store'
import JSONView from 'vue-json-component'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import ShortKeys from '@/assets/resources/config_files/shortkeys'
import VJsoneditor from 'v-jsoneditor'
import VueApexCharts from 'vue-apexcharts'
import FlagIcon from 'vue-flag-icon'
import vuex_authentication from '@/store/modules/vuex_authentication'
import helpers, {createExpirationTimeout} from '@/plugins/helpers'
import component_events from '@/constants/component_events'
import vuex_global from '@/store/modules/sections/vuex_global'
import user_config from '@/constants/user_config'
import icons from '@/constants/icons'
import VueCookies from 'vue-cookies'

export const ServerBus = new Vue()
if (navigator.userAgent.search('Firefox') >= 0) {
    document.getElementById('body').classList.add('firefox')
}
Vue.config.productionTip = false
Axios.defaults.withCredentials = true
Axios.interceptors.request.use(
    (config) => {
        createExpirationTimeout()
        store.dispatch(`${vuex_authentication.constants.NAMESPACE}/${vuex_authentication.constants.A_SET_SESSION_EXPIRED}`, false)
        store.dispatch(`${vuex_authentication.constants.NAMESPACE}/${vuex_authentication.constants.A_SET_SESSION_EXPIRED_COMPLETELY}`, false)
        let token = Vue.$cookies.get('act')
        let username =
            store.state[vuex_authentication.constants.NAMESPACE][
                vuex_authentication.constants.S_USERNAME
                ]
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        if (username) {
            config.headers['username'] = username
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

Axios.interceptors.response.use(
    (response) => {
        if (process.env.VUE_APP_DEBUG_MODE == 'true') {
            console.log(
                '[' + response.status + '] ' + response.config.url,
                '-',
                response.data
            )
        }
        let filterMessages = [
            'resources'
        ]
        if ((response.config.method == 'delete' || response.config.method == 'post') && response.data.message != null && filterMessages.find(m => m == response.data.message.toLowerCase()) == null) {
            let status = response.data.status
            if (helpers.nullOrEmpty(status)) status = response.data.error
            helpers.setAlertMessage(status, response.data.message)
        }

        return response
    },
    (error) => {
        console.log(error)
        if (error.response) {

            if (error.response.status == 400 || error.response.status == 401 || error.response.status == 404) {

                store.dispatch(vuex_authentication.constants.NAMESPACE + '/' + vuex_authentication.constants.A_SET_LOGOUT)

            } else if (error.response.status == 403) {
                helpers.redirect('forbidden')
            }
        } else {
            return Promise.reject(error)
        }
    }
)

const moment = require('moment')
moment.updateLocale('es')
Vue.component('moment', moment)

Vue.use(VueRouter)
Vue.use(Vuetify)
Vue.use(VueAxios, Axios)
Vue.use(TreeView)
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(Chartkick.use(Chart))
Vue.use(JSONView)
Vue.use(require('vue-shortkey'))
Vue.use(FlagIcon)
Vue.use(VueApexCharts)
Vue.use(VJsoneditor)
Vue.use(VueCookies, {
    expires: '1d',
    path: '/',
    domain: '',
    secure: process.env.VUE_APP_DEBUG_MODE === 'false',
    sameSite: 'Strict'
})
Vue.component('apexchart', VueApexCharts)

let env_color = '#ddddd'
let env_name = ''
let env_id = process.env.VUE_APP_ENDPOINT.split('.')[1]

switch (env_id.toLowerCase()) {
    case 'local':
        env_color = '#494949'
        env_name = 'local'
        break
    case 'dev':
        env_color = '#aab000'
        env_name = 'dev'
        break
    case 'int':
        env_color = '#f88e00'
        env_name = 'integración'
        break
    case 'testing':
        env_color = '#ff6be3'
        env_name = 'testing'
        break
    case 'pre':
        env_color = '#509660'
        env_name = 'preproduccion'
        break
    default:
        env_id = 'pro'
        env_color = '#394ca6'
        env_name = 'producción'
        break
}
const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#077b86',
                secondary: '#C8E3DE',
                accent: '#077b86',
                error: '#C9616A',
                success: '#509660',
                warning: '#e58728',
                bg_primary: '#C8E3DE',
                bg_clear: '#f1f1f1',
                contrast_medium: '#c6c6d0',
                contrast_medium_low: '#dadada',
                contrast_low: '#f3f4f6',
                bg_dark: '#5c5c5c',
                env_color: env_color,
            },
            dark: {},
        },

        dark: false,
    },
    icons: {
        iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    lang: {
        locales: store.getters['location/getLocationsContent'],
        current: 'es', //store.getters["location/getLocation"].currentLocation,
    },
})
Vue.prototype.$shortKeys = ShortKeys
Vue.prototype.$events = component_events
Vue.prototype.$vuetify = vuetify
Vue.prototype.$moment = moment
Vue.prototype.$icons = icons
Vue.prototype.$env = {
    name: env_id,
    complete_name: env_name,
    color: env_color,
}
Vue.filter('moment', (value, format) => {
    if (value == null) {
        return '-'
    }
    const timestamp = parseInt(value.toString().slice(0, 10))
    if (format == 'from') {
        return moment.unix(timestamp).from()
    }
    return moment.unix(timestamp).format(format)
});
if (store.state[vuex_global.constants.NAMESPACE][vuex_global.constants.S_USER_CONFIG][user_config.constants.GLOBAL][user_config.constants.COMPACT_MODE]) {
    document.body.classList.add('compact-mode')
}
export const bus = new Vue()
new Vue({
    vuetify,
    render: (h) => h(App),
    router: router,
    store,
}).$mount('#app')
