const constants = Object.freeze({
  NAMESPACE: 'vuex_instances',

  S_INSTANCES_OPENED: 'state_instances_opened',
  S_SEARCH_FILTERS: 'state_search_filters',

  M_SET_INSTANCES_OPENED: 'mutation_set_instances_opened',
  M_SET_SEARCH_FILTERS: 'mutation_set_search_filters',

  A_SET_INSTANCES_OPENED: 'action_set_instances_opened',
  A_SET_SEARCH_FILTERS: 'action_set_search_filters',
})

const state = {
  [constants.S_INSTANCES_OPENED]: [{
    id: 'explorer',
    name: 'Listado de instancias'
  }],
  [constants.S_SEARCH_FILTERS]: {}
}

const mutations = {
  [constants.M_SET_INSTANCES_OPENED](state, payload) {
    state[constants.S_INSTANCES_OPENED] =
      payload
  },
  [constants.M_SET_SEARCH_FILTERS](state, payload) {
    state[constants.S_SEARCH_FILTERS] =
      payload
  },
}

const actions = {
  [constants.A_SET_INSTANCES_OPENED](state, instances) {
    state.commit(constants.M_SET_INSTANCES_OPENED, instances)
  },
  [constants.A_SET_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_SEARCH_FILTERS, filters)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  constants,
}
