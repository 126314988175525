<template>
    <v-container class="cb-view-container-without-tabs">
        <ExplorerFiltersRow
          :actions="actions"
          model="usuarios"
          @[$events.EXPLORER_ACTION_CLICKED]="actionClicked"
        />
        <v-row>
            <DataTable
              :data="dataTableData"
              :data-table-options="dataTableOptions"
              :headers="dataTableHeaders"
              :loading="false"
              model="grupos"
              @deleteGroup="deleteGroup"
              @[$events.DATA_TABLE_REFRESH]="getGroups"
            >
            </DataTable>
        </v-row>
        <NewGroupDialogDeveloperPortal
          v-if="showNewGroupDialog"
          :fields="dataTableHeaders"
          @close="showNewGroupDialog=false"
          @refresh="getGroups()"
        />
    </v-container>
</template>
<script>

import service_devPortal from "@/services/service_devPortal";
import DataTable from "@/components/repository/tables/DataTable.vue";
import ExplorerFiltersRow from "@/components/repository/explorers/ExplorerFiltersRow.vue";
import helpers_resolution from "@/plugins/helpers_resolution";
import service_user from "@/services/service_user";
import {P_ADMINISTRATION} from "@/constants/group_permissions";
import icons from "@/constants/icons";
import NewGroupDialogDeveloperPortal from "@/components/administration/developerPortal/components/NewGroupDialog.vue";

export default {
    name: 'GroupsDeveloperPortal',
    components: {NewGroupDialogDeveloperPortal, ExplorerFiltersRow, DataTable},
    data() {
        return {
            name: '',
            description: '',
            dataTableData: {
                results: [],
                total: 0
            },
            dataTableHeaders: [
                {
                    text: 'Nombre',
                    value: 'name',
                    sortable: true,
                    type: 'text',
                    width: '30%'
                },
                {
                    text: 'Descripción',
                    value: 'description',
                    sortable: true,
                    type: 'text',
                    width: '70%'
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                    custom: true,
                    width: '40px',
                    actions: () => {

                        let entities = []

                        if (service_user.checkGroupPermission(P_ADMINISTRATION)) {

                            entities.push({
                                id: 'deleteGroup',
                                icon: icons.I_DELETE,
                                title: 'Borrar grupo',
                                enabled: true,
                            })
                        }
                        return entities

                    }
                }],
            dataTableOptions: {
                rpp: helpers_resolution.getTableRowsPerPage(),
                sortDesc: [false],
                page: 1,
                sortBy: ['name']
            },
            showNewGroupDialog: false,
        }
    },
    computed: {

        actions() {
            let entities = []
            if (service_user.checkGroupPermission(P_ADMINISTRATION)) {

                entities.push({
                    id: 'new_group',
                    icon: this.$icons.I_GROUP,
                    text: 'Crear grupo',
                    enabled: true,
                })
            }
            return entities
        }
    },
    mounted() {
        this.getGroups()
    },
    methods: {
        getGroups() {
            service_devPortal.getGroups().then(res => {
                console.log(res)
                this.dataTableData.results = res.data.extra.results
                this.dataTableData.total = res.data.extra.results.length
            })
        },
        async deleteGroup(id) {
            await service_devPortal.deleteGroup(id)

            this.getGroups()
        },
        actionClicked(action_id) {
            if (action_id == 'new_group') {
                this.showNewGroupDialog = true

            }
        },
    }
}
</script>
