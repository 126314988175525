<template>
    <v-dialog
      v-model="show"
      inset
      scrollable
      width="900px"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
        <v-card
          class="cb-filter-card"
        >
            <card-title
              :close="true"
              icon="menu"
              title="Crear nuevo grupo"
              @close="$emit('close')"
            />
            <v-card-text class="pa-4">
                <v-row justify="space-between">
                    <v-col cols="5">
                        <span>Nombre del grupo</span>
                        <v-text-field
                          v-model="newGroup['name']"
                          autofocus
                          dense
                          hide-details
                          outlined
                          placeholder="Nombre de grupo"
                        />
                    </v-col>
                    <v-col
                      class="my-auto pl-4"
                      cols="7"
                    >
                        <p class="ma-0 cb-font-size-4">Nombre de grupo de acceso a la plataforma.</p>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col cols="5">
                        <span>Descripción</span>
                        <v-text-field
                          v-model="newGroup['description']"
                          autofocus
                          dense
                          hide-details
                          outlined
                          placeholder="Descripción"
                        />
                    </v-col>
                    <v-col
                      class="my-auto pl-4"
                      cols="7"
                    >
                        <p class="ma-0 cb-font-size-4">Descripción del grupo.</p>
                    </v-col>
                </v-row>
            </v-card-text>
            <dialog-card-footer
              :close-button-visible="true"
              :edit-button-visible="false"
              :save-button-disabled="!isValidGroup"
              :save-button-visible="true"
              @close="$emit('close')"
              @save="save"
            />
        </v-card>
    </v-dialog>
</template>

<script>
import helpers from '@/plugins/helpers'
import CardTitle from '@/components/repository/cards/CardTitle'
import DialogCardFooter from '@/components/repository/dialogs/DialogCardFooter'
import service_devPortal from "@/services/service_devPortal";

export default {
    name: 'NewGroupDialogDeveloperPortal',
    components: {DialogCardFooter, CardTitle},
    props: {
        loading: Boolean,
        fields: Array,
        response_filters: Array
    },
    data() {
        return {
            helpers,
            show: true,
            newGroup: {
                name: null,
                description: null
            },
        }
    },
    computed: {
        isValidGroup() {
            if (helpers.nullOrEmpty(this.newGroup['name']) || this.newGroup['name'].length > 200) {
                return false
            }
            return !(helpers.nullOrEmpty(this.newGroup['description']) || this.newGroup['description'].length > 200);


        },
    },

    methods: {

        async save() {
            await service_devPortal.createGroup(this.newGroup['name'], this.newGroup['description'])

            this.newGroup = {}
            this.$emit('refresh')
            this.$emit('close')
        },
    },
}
</script>
