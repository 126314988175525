import {COMMSERVICES} from '@/constants/categories'
import {
    P_COMMSERVICES_COMMSTRACKER_LIST,
    P_COMMSERVICES_EXCLUSION_LIST,
    P_COMMSERVICES_USERS_LIST
} from '@/constants/group_permissions'
import icons from '@/constants/icons'
import CommstrackerDomainsView from "@/views/commservices/CommstrackerDomainsView.vue";
import ExclusionListView from "@/views/commservices/ExclusionListView.vue";

const Users = () => import(/* webpackChunkName: "Commservices"*/ '@/views/commservices/CommservicesUsersView.vue')


const ROOT = '/commservices/'
const CATEGORY = COMMSERVICES
export default [
    {
        path: ROOT + 'users',
        name: 'users',
        component: Users,
        meta: {
            perm: P_COMMSERVICES_USERS_LIST,
            title: 'Gestión de usuarios de Commservices',
            title_short: 'Usuarios CommS.',
            navigable: true,
            icon: icons.I_USER,
            category: CATEGORY,
            requiresAuth: true
        }
    },
    {
        path: ROOT + 'commstracker',
        name: 'commstracker',
        component: CommstrackerDomainsView,
        meta: {
            perm: P_COMMSERVICES_COMMSTRACKER_LIST,
            title: 'Gestión de dominios de Commstracker',
            title_short: 'Commstracker',
            navigable: true,
            icon: icons.I_COMMSTRACKER,
            category: CATEGORY,
            requiresAuth: true
        }
    },
    {
        path: ROOT + 'exclusion',
        name: 'exclusion',
        component: ExclusionListView,
        meta: {
            perm: P_COMMSERVICES_EXCLUSION_LIST,
            title: 'Gestión y consulta de la lista de exclusión',
            title_short: 'Lista de exclusión',
            navigable: true,
            icon: icons.I_COMMSTRACKER,
            category: CATEGORY,
            requiresAuth: true
        }
    },
]
