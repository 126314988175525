import { render, staticRenderFns } from "./ShowJsonTemplateDialog.vue?vue&type=template&id=354d5be6&scoped=true"
import script from "./ShowJsonTemplateDialog.vue?vue&type=script&lang=js"
export * from "./ShowJsonTemplateDialog.vue?vue&type=script&lang=js"
import style0 from "./ShowJsonTemplateDialog.vue?vue&type=style&index=0&id=354d5be6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354d5be6",
  null
  
)

export default component.exports