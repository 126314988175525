export default {
    I_PROCESS: 'computer',
    I_PROJECT: 'folder',
    I_INSTANCE: 'rocket',
    I_TEMPLATE: 'integration_instructions',
    I_APPEARANCE: 'satellite',
    I_LANGUAGE: 'language',
    I_COMMSTRACKER: 'link',
    I_COMMSERVICES: 'settings_input_antenna',
    I_BILLING: 'account_balance',
    I_NOTIFICATIONS: 'phone',
    I_BILLING_UNIT: 'account_balance',
    I_CUSTOM_BILLING: 'card_giftcard',
    I_PROVIDERS: 'satellite_alt',
    I_LAUNCH: 'play_arrow',
    I_MASTER_ACCOUNT: 'park',
    I_SUBACCOUNT: 'forest',
    I_LOG: 'receipt_long',
    I_SHORTCUT: 'open_in_new',
    I_SEARCH: 'search',
    I_OPEN_SIDEBAR: 'open_in_full',
    I_CLOSE_SIDEBAR: 'close_fullscreen',
    I_SMS: 'phone',
    I_HOME: 'home',

    I_GROUP: 'people',
    I_USER: 'person',
    I_PERMISSION: 'lock_outline',
    I_OVERVIEW: 'info',
    I_WEB: 'monitor',
    I_CONFIGURATION: 'settings',
    I_CALLBACKS: 'phone',
    I_RESOURCES: 'inventory',
    I_TEST: 'play_arrow',
    I_HELP: 'help_outline',
    I_FILTER: 'filter_alt',
    I_B2B: 'co_present',
    I_POSTAMAIL: 'print',
    I_TAB_LIST: 'manage_search',
    I_ACTIONS: 'menu',
    I_CERTIFICATE: 'workspace_premium',
    I_SOURCE: 'home',
    I_BACK: 'arrow_back',
    I_RESET: 'restart_alt',
    I_CANCEL: 'clear',
    I_CREATE: 'add',
    I_EDIT: 'create',
    I_CLONE: 'content_copy',
    I_DELETE: 'delete',
    I_PASSWORD: 'password',
    I_UNLOCK: 'lock_open',
    I_SAVE: 'save',
    I_VIEW: 'visibility',
    I_EXPORT: 'ios_share',
    I_IMPORT: 'system_update_alt',
    I_GENERATE: 'download',
    I_BATCH_UPLOAD: 'all_inbox',
    I_WHATSAPP: 'whatsapp',

    I_WAITING: 'schedule',
    I_EXPIRED: 'event_busy',
    I_CHECK: 'check_circle',
    I_CHECK_OK: 'task_alt',
    I_CHECK_KO: 'highlight_off',
    I_ALERT: 'warning',
    I_STATS: 'query_stats',
    I_HOSTS: 'computer',
    I_LOGS: 'receipt_long',
    I_EMAIL: 'email',
    I_DISABLED: 'highlight_off',
    I_ERROR: 'error',
    I_DOCUMENT: 'description',
    I_CHECKLIST: 'checklist',

    I_TICKER: 'published_with_changes',
    I_SCHEDULES: 'event_repeat',
    I_NOTARIAL_DEPOSIT: 'gavel'


}

