import helpers from '@/plugins/helpers'

export const datatableMixin = dataTableConfig => ({
  data() {
    return {
      helpers,

      responseFilters: null,
      visualOptions: null,
      searchText: null,

      showItemDialog: false,
      showExportDialog: false,

      loading: true,
      dataTableData: null,
      dataTableOptions: dataTableConfig['options'],

      findOnCreate: dataTableConfig['findOnCreate'] != undefined ? dataTableConfig['findOnCreate'] : true,
      itemSelected: null,
      isEditing: false,
    }
  },
  computed: {
    searchFilters: {
      get() {
        return dataTableConfig['vuex'] != null ? dataTableConfig['vuex'].state : {}
      },
      set(val) {
        if (dataTableConfig['vuex'] != null) {
          this.$store.dispatch(dataTableConfig['vuex'].setter, val).then(() => {
            this.find()
          })
        }
      }
    },
  },
  created() {
    if (this.findOnCreate) {
      this.find()
    }
  },
  methods: {
    changeFilters(new_filters) {
      if (!helpers.nullOrEmpty(new_filters) && JSON.stringify(this.searchFilters) !== JSON.stringify(new_filters)) {
        Object.keys(new_filters).forEach(key => {
          this.$set(this.searchFilters, key, new_filters[key])
        })
      }
      this.find()
    },
    removeFilter(filter) {
      this.$delete(this.searchFilters, filter)
      this.find()
    },
    searchByText(searchText) {
      let fields = null
      this.searchText = searchText
      if (!helpers.nullOrEmpty(this.searchText)) {
        fields = {}
        const headers = this.dataTableHeaders.filter(field => !field.custom)
        if (headers.length == 1) {
          this.$set(this.searchFilters, headers[0].value, this.searchText)
        } else {
          headers.map(a => a.value).forEach(field_key => {
            fields[field_key + '[~]'] = this.searchText
          })
          this.$set(this.searchFilters, 'OR', fields)
        }
      } else {
        this.$delete(this.searchFilters, 'OR')
      }
      this.find()
    },
    find() {
      let filters = {}

      if (this.searchFilters != null) {
        filters = this.searchFilters
      }
      this.loading = true
      if (this.requiredFilters != null) {
        Object.keys(this.requiredFilters).forEach(key => {
          filters[key] = this.requiredFilters[key]
        })
      }
      dataTableConfig['finder'](this.dataTableHeaders.filter(dt => !dt.custom).map(a => a.value), filters, this.dataTableOptions).then((extra) => {
        this.dataTableData = extra
        this.responseFilters = extra.filters
        if (this.itemSelected != null) {
          let foundItem = this.dataTableData.results.find(item => item.id == this.itemSelected.id)
          if (foundItem != null) {
            this.openItem(foundItem)
          }
        }

        this.loading = false
      })
    },
    openItem(item) {
      this.itemSelected = item
    },
    editItem(item) {
      this.isEditing = true
      this.itemSelected = item
    },
    closeItem() {
      this.itemSelected = null
      this.find()
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$set(this.dataTableData, null)
    this.changeFilters({})
    next()
  }
})