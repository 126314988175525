import { EXPLORERS } from '@/constants/categories'
import { P_INSTANCES, P_PROCESSES, P_PROTOCOL_ACCOUNTS, P_TEMPLATES } from '@/constants/group_permissions'
import icons from '@/constants/icons'

const Processes = () => import(/* webpackChunkName: "Operations"*/ '@/views/operations/Processes')
const Templates = () => import(/* webpackChunkName: "Operations"*/ '@/views/operations/Templates')
const Instances = () => import(/* webpackChunkName: "Operations"*/ '@/views/operations/Instances')
const MasterAccounts = () => import(/* webpackChunkName: "Operations"*/ '@/views/operations/MasterAccounts')

const ROOT = '/operations/'
const CATEGORY = EXPLORERS

export default [
  {
    path: ROOT + 'instances',
    name: 'instances',
    component: Instances,
    meta: {
      perm: P_INSTANCES,
      title: 'Explorador de instancias',
      title_short: 'Instancias',
      navigable: true,
      icon: icons.I_INSTANCE,
      category: CATEGORY,
      requiresAuth: true
    },
  },
  {
    path: ROOT + 'templates',
    name: 'templates',
    component: Templates,
    meta: {
      perm: P_TEMPLATES,
      title: 'Explorador de plantillas',
      title_short: 'Plantillas',
      navigable: true,
      category: CATEGORY,
      icon: icons.I_TEMPLATE,
      requiresAuth: true
    },
  },
  {
    path: ROOT + 'processes',
    name: 'processes',
    component: Processes,
    meta: {
      perm: P_PROCESSES,
      title: 'Explorador de procesos',
      title_short: 'Procesos',
      navigable: true,
      icon: icons.I_PROCESS,
      category: CATEGORY,
      requiresAuth: true
    }
  },
  {
    path: ROOT + 'accounts',
    name: 'accounts',
    component: MasterAccounts,
    meta: {
      perm: P_PROTOCOL_ACCOUNTS,
      title: 'Gestión de cuentas',
      title_short: 'Cuentas',
      icon: icons.I_MASTER_ACCOUNT,
      navigable: true,
      category: CATEGORY,
      requiresAuth: true
    },
  },
]
