const constants = Object.freeze({
  NAMESPACE: 'vuex_protocol',

  S_CURRENT_MASTER_ACCOUNT_DATA: 'state_current_process_data',

  S_MASTER_ACCOUNTS_OPENED: 'state_master_accounts_opened',
  S_SEARCH_FILTERS_MASTER_ACCOUNTS: 'state_search_filters_master_accounts',

  S_SUBACCOUNTS_OPENED: 'state_subaccounts_opened',
  S_SEARCH_FILTERS_SUBACCOUNTS: 'state_search_filters_subaccounts',

  S_BILLING_UNITS_SEARCH_FILTERS: 'state_billing_units_search_filters',
  S_BILLING_UNITS_OPENED: 'state_billing_units_opened',

  S_LOGS_SEARCH_FILTERS: 'state_logs_search_filters',
  S_TICKER_SEARCH_FILTERS: 'state_ticker_search_filters',
  S_SCHEDULES_SEARCH_FILTERS: 'state_schedules_search_filters',
  S_ENVELOPES_SEARCH_FILTERS: 'state_envelopes_search_filters',
  S_COMMSTRACKER_DOMAINS_SEARCH_FILTERS: 'state_commstracker_domains_search_filters',
  S_GLOBAL_VARIABLES_SEARCH_FILTERS: 'state_global_variables_search_filters',
  S_NOTIFICATIONS_SEARCH_FILTERS: 'state_notifications_search_filters',
  S_APPEARANCES_SEARCH_FILTERS: 'state_appearances_search_filters',
  S_CERTIFICATE_ISSUERS_SEARCH_FILTERS: 'state_certificate_issuers_search_filters',

  S_EMAIL_PROVIDERS_FILTERS: 'state_email_providers_filters',
  S_POSTAMAIL_FILTERS: 'state_postamail_filters',
  S_CALLBACKS_FILTERS: 'state_callbacks_filters',

  S_BATCH_UPLOADS_SEARCH_FILTERS: 'state_batch_uploads_search_filters',

  M_SET_MASTER_ACCOUNTS_OPENED: 'mutation_set_master_accounts_opened',
  M_SET_SEARCH_FILTERS_MASTER_ACCOUNTS: 'mutation_set_search_filters_master_accounts',

  M_SET_SUBACCOUNTS_OPENED: 'mutation_set_subaccounts_opened',
  M_SET_SEARCH_FILTERS_SUBACCOUNTS: 'mutation_set_search_filters_subaccounts',

  M_SET_BILLING_UNIT_FILTERS: 'mutation_set_billing_unit_filters',
  M_SET_BILLING_UNITS_OPENED: 'mutation_set_billing_unit_opened',
  M_SET_LOGS_SEARCH_FILTERS: 'mutation_set_logs_search_filters',
  M_SET_TICKER_SEARCH_FILTERS: 'mutation_set_ticker_search_filters',
  M_SET_SCHEDULES_SEARCH_FILTERS: 'mutation_set_schedules_search_filters',
  M_SET_ENVELOPES_SEARCH_FILTERS: 'mutation_set_envelopes_search_filters',
  M_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS: 'mutation_set_commstracker_domains_search_filters',
  M_SET_GLOBAL_VARIABLES_SEARCH_FILTERS: 'mutation_set_global_variables_search_filters',
  M_SET_NOTIFICATIONS_SEARCH_FILTERS: 'mutation_set_notifications_search_filters',
  M_SET_APPEARANCES_SEARCH_FILTERS: 'mutation_set_appearances_search_filters',
  M_SET_CERTIFICATE_ISSUERS_SEARCH_FILTERS: 'mutation_set_certificate_issuers_search_filters',

  M_SET_BATCH_UPLOADS_SEARCH_FILTERS: 'mutation_set_batch_uploads_search_filters',

  M_MUTATE_CURRENT_MASTER_ACCOUNT_DATA: 'mutation_current_process_data',

  M_EMAIL_PROVIDERS_FILTERS: 'mutation_email_providers_filters',
  M_POSTAMAIL_FILTERS: 'mutation_email_providers_filters',
  M_SET_CALLBACKS_FILTERS: 'mutation_set_callbacks_filters',

  A_SET_MASTER_ACCOUNTS_OPENED: 'action_set_master_accounts_opened',
  A_SET_SEARCH_FILTERS_MASTER_ACCOUNTS: 'action_set_search_filters_master_accounts',
  A_SET_SUBACCOUNTS_OPENED: 'action_set_subaccounts_opened',
  A_SET_SEARCH_FILTERS_SUBACCOUNTS: 'action_set_search_filters_subaccounts',

  A_SET_CURRENT_MASTER_ACCOUNT_DATA: 'action_set_current_process_data',
  A_SET_BILLING_UNITS_OPENED: 'action_set_billing_units_opened',
  A_SET_BILLING_UNIT_FILTERS: 'action_set_billing_unit_filters',
  A_SET_LOGS_SEARCH_FILTERS: 'action_set_logs_search_filters',
  A_SET_TICKER_SEARCH_FILTERS: 'action_set_ticker_search_filters',
  A_SET_SCHEDULES_SEARCH_FILTERS: 'action_set_schedules_search_filters',
  A_SET_ENVELOPES_SEARCH_FILTERS: 'action_set_envelopes_search_filters',
  A_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS: 'action_set_commstracker_domains_search_filters',
  A_SET_GLOBAL_VARIABLES_SEARCH_FILTERS: 'action_set_global_variables_search_filters',
  A_SET_NOTIFICATIONS_SEARCH_FILTERS: 'action_set_notifications_search_filters',
  A_SET_APPEARANCES_SEARCH_FILTERS: 'action_set_appearances_search_filters',
  A_SET_CERTIFICATE_ISSUERS_SEARCH_FILTERS: 'action_set_certificate_issuers_search_filters',

  A_SET_BATCH_UPLOADS_SEARCH_FILTERS: 'action_set_batch_uploads_search_filters',
  A_SET_EMAIL_PROVIDERS_FILTERS: 'action_set_email_providers_filters',
  A_SET_POSTAMAIL_FILTERS: 'action_set_postamail_filters',
  A_SET_CALLBACKS_FILTERS: 'action_set_callbacks_filters',

})

const state = {
  [constants.S_MASTER_ACCOUNTS_OPENED]: [{
    id: 'explorer',
    name: 'Listado de cuentas raíz'
  }],
  [constants.S_SUBACCOUNTS_OPENED]: [{
    id: 'explorer',
    name: 'Listado de subcuentas'
  }],
  [constants.S_BILLING_UNITS_OPENED]: [{
    id: 'explorer',
    name: 'Listado de unid. fact.'
  }],
  [constants.S_SEARCH_FILTERS_MASTER_ACCOUNTS]: {},
  [constants.S_SEARCH_FILTERS_SUBACCOUNTS]: {},

  [constants.S_CURRENT_MASTER_ACCOUNT_DATA]: null,
  [constants.S_BILLING_UNITS_SEARCH_FILTERS]: {},

  [constants.S_EMAIL_PROVIDERS_FILTERS]: {},
  [constants.S_POSTAMAIL_FILTERS]: {},
  [constants.S_CALLBACKS_FILTERS]: {},
  [constants.S_LOGS_SEARCH_FILTERS]: {},
  [constants.S_TICKER_SEARCH_FILTERS]: {},
  [constants.S_SCHEDULES_SEARCH_FILTERS]: {},
  [constants.S_ENVELOPES_SEARCH_FILTERS]: {},
  [constants.S_COMMSTRACKER_DOMAINS_SEARCH_FILTERS]: {},
  [constants.S_GLOBAL_VARIABLES_SEARCH_FILTERS]: {},
  [constants.S_NOTIFICATIONS_SEARCH_FILTERS]: {},
  [constants.S_APPEARANCES_SEARCH_FILTERS]: {},
  [constants.S_CERTIFICATE_ISSUERS_SEARCH_FILTERS]: {},
  [constants.S_BATCH_UPLOADS_SEARCH_FILTERS]: {},
}

const mutations = {
  [constants.M_SET_MASTER_ACCOUNTS_OPENED](state, payload) {
    state[constants.S_MASTER_ACCOUNTS_OPENED] =
      payload
  },
  [constants.M_SET_SEARCH_FILTERS_MASTER_ACCOUNTS](state, payload) {
    state[constants.S_SEARCH_FILTERS_MASTER_ACCOUNTS] =
      payload
  },
  [constants.M_SET_SUBACCOUNTS_OPENED](state, payload) {
    state[constants.S_SUBACCOUNTS_OPENED] =
      payload
  },
  [constants.M_SET_SEARCH_FILTERS_SUBACCOUNTS](state, payload) {
    state[constants.S_SEARCH_FILTERS_SUBACCOUNTS] =
      payload
  },

  [constants.M_MUTATE_CURRENT_MASTER_ACCOUNT_DATA](state, payload) {
    state[constants.S_CURRENT_MASTER_ACCOUNT_DATA] = payload
  },
  [constants.M_SET_BILLING_UNITS_OPENED](state, payload) {
    state[constants.S_BILLING_UNITS_OPENED] =
      payload
  },
  [constants.M_SET_LOGS_SEARCH_FILTERS](state, payload) {
    state[constants.S_LOGS_SEARCH_FILTERS] =
      payload
  },
  [constants.M_SET_BILLING_UNIT_FILTERS](state, payload) {
    state[constants.S_BILLING_UNITS_SEARCH_FILTERS] = payload
  },

  [constants.M_EMAIL_PROVIDERS_FILTERS](state, payload) {
    state[constants.S_EMAIL_PROVIDERS_FILTERS] = payload
  },

  [constants.M_POSTAMAIL_FILTERS](state, payload) {
    state[constants.S_POSTAMAIL_FILTERS] = payload
  },
  [constants.M_SET_CALLBACKS_FILTERS](state, payload) {
    state[constants.S_CALLBACKS_FILTERS] = payload
  },
  [constants.M_SET_TICKER_SEARCH_FILTERS](state, payload) {
    state[constants.S_TICKER_SEARCH_FILTERS] = payload
  },
  [constants.M_SET_SCHEDULES_SEARCH_FILTERS](state, payload) {
    state[constants.S_SCHEDULES_SEARCH_FILTERS] = payload
  },
  [constants.M_SET_ENVELOPES_SEARCH_FILTERS](state, payload) {
    state[constants.S_ENVELOPES_SEARCH_FILTERS] = payload
  },
  [constants.M_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS](state, payload) {
    state[constants.S_COMMSTRACKER_DOMAINS_SEARCH_FILTERS] = payload
  },
  [constants.M_SET_GLOBAL_VARIABLES_SEARCH_FILTERS](state, payload) {
    state[constants.S_GLOBAL_VARIABLES_SEARCH_FILTERS] = payload
  },
  [constants.M_SET_NOTIFICATIONS_SEARCH_FILTERS](state, payload) {
    state[constants.S_NOTIFICATIONS_SEARCH_FILTERS] = payload
  },
  [constants.M_SET_APPEARANCES_SEARCH_FILTERS](state, payload) {
    state[constants.S_APPEARANCES_SEARCH_FILTERS] = payload
  },
  [constants.M_SET_CERTIFICATE_ISSUERS_SEARCH_FILTERS](state, payload) {
    state[constants.S_CERTIFICATE_ISSUERS_SEARCH_FILTERS] = payload
  },
  [constants.M_SET_BATCH_UPLOADS_SEARCH_FILTERS](state, payload) {
    state[constants.S_BATCH_UPLOADS_SEARCH_FILTERS] = payload
  }
}

const actions = {
  [constants.A_SET_MASTER_ACCOUNTS_OPENED](state, master_accounts) {
    state.commit(constants.M_SET_MASTER_ACCOUNTS_OPENED, master_accounts)
  },
  [constants.A_SET_SEARCH_FILTERS_MASTER_ACCOUNTS](state, filters) {
    state.commit(constants.M_SET_SEARCH_FILTERS_MASTER_ACCOUNTS, filters)
  },
  [constants.A_SET_SUBACCOUNTS_OPENED](state, master_accounts) {
    state.commit(constants.M_SET_SUBACCOUNTS_OPENED, master_accounts)
  },
  [constants.A_SET_SEARCH_FILTERS_SUBACCOUNTS](state, filters) {
    state.commit(constants.M_SET_SEARCH_FILTERS_SUBACCOUNTS, filters)
  },

  [constants.A_SET_CURRENT_MASTER_ACCOUNT_DATA]({ commit }, value) {
    commit(constants.M_MUTATE_CURRENT_MASTER_ACCOUNT_DATA, value)
  },
  [constants.A_SET_BILLING_UNITS_OPENED](state, filters) {
    state.commit(constants.M_SET_BILLING_UNITS_OPENED, filters)
  },
  [constants.A_SET_BILLING_UNIT_FILTERS](state, filters) {
    state.commit(constants.M_SET_BILLING_UNIT_FILTERS, filters)
  },
  [constants.A_SET_LOGS_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_LOGS_SEARCH_FILTERS, filters)
  },

  [constants.A_SET_EMAIL_PROVIDERS_FILTERS](state, filters) {
    state.commit(constants.M_EMAIL_PROVIDERS_FILTERS, filters)
  },

  [constants.A_SET_POSTAMAIL_FILTERS](state, filters) {
    state.commit(constants.M_POSTAMAIL_FILTERS, filters)
  },
  [constants.A_SET_CALLBACKS_FILTERS](state, filters) {
    state.commit(constants.M_SET_CALLBACKS_FILTERS, filters)
  },
  [constants.A_SET_TICKER_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_TICKER_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_SCHEDULES_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_SCHEDULES_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_ENVELOPES_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_ENVELOPES_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_GLOBAL_VARIABLES_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_GLOBAL_VARIABLES_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_NOTIFICATIONS_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_NOTIFICATIONS_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_APPEARANCES_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_APPEARANCES_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_CERTIFICATE_ISSUERS_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_CERTIFICATE_ISSUERS_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_BATCH_UPLOADS_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_BATCH_UPLOADS_SEARCH_FILTERS, filters)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  constants,
}
