export default Object.freeze({
  ENTITY_NAME: 'host',

  ID: 'id',
  HOSTNAME: 'hostname',
  API: 'api',
  API_CERT: 'api_cert',
  ORCHESTRATION: 'orchestration',
  FUNCTIONALITY: 'functionality',
  CALLBACK: 'callback',
  SERVICE: 'service',
  TELEMETRY: 'telemetry',
  TICKER: 'ticker',
  WORKER_SPAWNER: 'worker_spawner',
  RUNNING_COMMIT: 'running_commit',
  LAST_CHECK: 'last_check',
  STATUS: 'status'
})
