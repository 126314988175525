import VueRouter from 'vue-router'
import routes from './routes'
import service_user from '@/services/service_user'
import helpers from '@/plugins/helpers'
import Vue from 'vue'
import store from '@/store'
import vuex_authentication from '@/store/modules/vuex_authentication'
import jwt_decode from "vue-jwt-decode";
import moment from "moment";

const router = new VueRouter({
    mode: 'history',
    base: '/' + process.env.VUE_APP_DEVELOPER,
    routes,
})

router.beforeEach((to, from, next) => {
    let logged = false
    const cookie = Vue.$cookies.get('act')
    const act = cookie != null ? jwt_decode.decode(cookie) : null
    if (act != null && act.exp != null) {
        if (moment.unix(act.exp).isAfter(moment())) {
            logged = true
        } else {
            store.dispatch(
                vuex_authentication.constants.NAMESPACE +
                '/' +
                vuex_authentication.constants.A_SET_LOGOUT,
                true
            )
        }
    }


    if (logged) {
        let routes = router.options.routes.filter(
            (r) =>
                r.meta.navigable && service_user.checkGroupPermission(r.meta.perm)
        )

        if (to.name == 'login' && routes.length != 1) {
            next('/')
        }
        if (routes.length == 1 && to.name != routes[0].name && to.name != 'account') {
            next(routes[0].path)
        }

        if (to.meta.perm && !service_user.checkGroupPermission(to.meta.perm)) {
            next('forbidden')
        }
    } else {
        if (to.meta.requiresAuth) {
            next('/login')
        }

    }

    helpers.setActiveRoute([{name: to.meta.title}])
    if (!helpers.nullOrEmpty(to.meta.title_short)) {
        document.title = 'APP Center | ' + to.meta.title_short
    } else {
        document.title = 'APP Center'
    }
    next()
})

export default router
