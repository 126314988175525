import Axios from 'axios'
import helpers from '@/plugins/helpers'
import { objectToFormData } from 'object-to-formdata'
import service_explorers from '@/services/global/service_explorers'

export default {

  openTemplate(code, version) {
    let section = null
    if (!helpers.nullOrEmpty(version)) {
      section = 'version_explorer'
    }
    helpers.open('templates', {
      id: code,
      name: code,
      section: section,
      version: version
    })
  },

  getTemplateInfo(code, version) {
    return Axios
      .get(
        process.env.VUE_APP_ENDPOINT +
        'app-center/templates/' +
        code +
        '/version/' +
        version
      )
      .then((response) => {
        return response.data.extra
      })
  },
  updateTemplate(template, changeLog, username) {

    let form_data_object = objectToFormData({
      'templateInfo': template,
      'changelog': changeLog,
      'uploaded_by': username
    }, { indices: true })
    return Axios
      .post(
        process.env.VUE_APP_ENDPOINT +
        'app-center/templates',
        form_data_object
      )
      .then((response) => {
        return response.data
      })
  },
  promoteVersion(code, version) {
    return Axios
      .post(
        process.env.VUE_APP_ENDPOINT +
        'app-center/templates/' +
        code +
        '/version/' +
        version + '/promote'
      )
      .then((response) => {
        return response.data.extra
      })
  },
  getTemplateVersions(code) {
    return Axios.get(`${process.env.VUE_APP_ENDPOINT}app-center/templates/${code}/versions`).then((response) => {
      return response.data.reverse()
    })
  },
  findTemplates(fields, filters, options) {
    return service_explorers.find('app-center/templates', fields, filters, options)
  },
}
