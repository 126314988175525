import Vue from 'vue'

const constants = Object.freeze({
  NAMESPACE: 'vuex_statistics',

  S_MASTER_ACCOUNT_SELECTED: 'state_master_account_selected',
  S_AVAILABLE_MASTER_ACCOUNTS: 'state_available_master_accounts',
  S_STATISTICS_RESULT: 'state_statistics_result',
  S_SEARCH_TYPE: 'state_search_type',

  S_PROCESS_SELECTED: 'state_process_selected',
  S_AVAILABLE_PROCESSES: 'state_available_processes',

  M_SET_MASTER_ACCOUNT_SELECTED: 'mutation_set_master_account_selected',
  M_SET_AVAILABLE_MASTER_ACCOUNTS: 'mutation_set_available_master_accounts',
  M_SET_STATISTICS_RESULT: 'mutation_set_statistics_result',
  M_SET_SEARCH_TYPE: 'mutation_set_search_type',

  M_SET_PROCESS_SELECTED: 'mutation_set_process_selected',
  M_SET_AVAILABLE_PROCESSES: 'mutation_set_available_processes',

  A_SET_MASTER_ACCOUNT_SELECTED: 'action_set_master_account_selected',
  A_SET_AVAILABLE_MASTER_ACCOUNTS: 'action_set_available_master_accounts',
  A_SET_STATISTICS_RESULT: 'action_set_statistics_result',
  A_SET_SEARCH_TYPE: 'action_set_search_type',

  A_SET_PROCESS_SELECTED: 'action_set_process_selected',
  A_SET_AVAILABLE_PROCESSES: 'action_set_available_processes',
})

const state = {
  [constants.S_MASTER_ACCOUNT_SELECTED]: null,
  [constants.S_AVAILABLE_MASTER_ACCOUNTS]: null,
  [constants.S_STATISTICS_RESULT]: null,
  [constants.S_SEARCH_TYPE]: null,

  [constants.S_PROCESS_SELECTED]: null,
  [constants.S_AVAILABLE_PROCESSES]: null,
}

const mutations = {
  [constants.M_SET_MASTER_ACCOUNT_SELECTED] (state, payload) {
    Vue.set(
      this.state[constants.NAMESPACE],
      constants.S_MASTER_ACCOUNT_SELECTED,
      payload
    )
  },
  [constants.M_SET_AVAILABLE_MASTER_ACCOUNTS] (state, payload) {
    Vue.set(
      this.state[constants.NAMESPACE],
      constants.S_AVAILABLE_MASTER_ACCOUNTS,
      payload
    )
  },
  [constants.M_SET_STATISTICS_RESULT] (state, payload) {
    Vue.set(
      this.state[constants.NAMESPACE],
      constants.S_STATISTICS_RESULT,
      payload
    )
  },
  [constants.M_SET_SEARCH_TYPE] (state, payload) {
    Vue.set(this.state[constants.NAMESPACE], constants.S_SEARCH_TYPE, payload)
  },
  [constants.M_SET_PROCESS_SELECTED] (state, payload) {
    Vue.set(
      this.state[constants.NAMESPACE],
      constants.S_PROCESS_SELECTED,
      payload
    )
  },
  [constants.M_SET_AVAILABLE_PROCESSES] (state, payload) {
    Vue.set(
      this.state[constants.NAMESPACE],
      constants.S_AVAILABLE_PROCESSES,
      payload
    )
  },
}

const actions = {
  [constants.A_SET_MASTER_ACCOUNT_SELECTED] (state, details) {
    state.commit(constants.M_SET_MASTER_ACCOUNT_SELECTED, details)
  },
  [constants.A_SET_AVAILABLE_MASTER_ACCOUNTS] (state, details) {
    state.commit(constants.M_SET_AVAILABLE_MASTER_ACCOUNTS, details)
  },
  [constants.A_SET_STATISTICS_RESULT] (state, details) {
    state.commit(constants.M_SET_STATISTICS_RESULT, details)
  },
  [constants.A_SET_SEARCH_TYPE] (state, details) {
    state.commit(constants.M_SET_SEARCH_TYPE, details)
  },
  [constants.A_SET_AVAILABLE_PROCESSES] (state, details) {
    state.commit(constants.M_SET_AVAILABLE_PROCESSES, details)
  },
  [constants.A_SET_PROCESS_SELECTED] (state, details) {
    state.commit(constants.M_SET_PROCESS_SELECTED, details)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  constants,
}
