import model_template from '@/constants/models/model_template'
import model_masteraccount from '@/constants/models/model_masteraccount'

function getColWidth(fieldWidth) {

  return 'overflow:hidden;min-width:' + fieldWidth + '!important;max-width:' + fieldWidth + '!important;width:' + fieldWidth + '!important;'
}

export default {
  getColWidth,

  getTableLessRowsPerPage() {
    return 12
  },
  getTableRowsPerPage() {
    return 15
  },
  getTableMoreRowsItemsPerPage() {
    return 15
  },


  setVersionsTableColsWidth() {
    return {
      [model_template.VERSION_VERSION]: '8vw',
      [model_template.VERSION_TAG]: '5vw',
      [model_template.VERSION_LAST_CHANGE]: '9vw',
      [model_template.VERSION_UPLOADED_BY]: '6vw',
      [model_template.VERSION_CHANGELOG]: '28vw',
    }
  },
  setTemplateParamsColsWidth() {
    return {
      [model_template.PARAM_NAME]: '28%',
      [model_template.PARAM_TYPE]: '18%',
      [model_template.PARAM_VALIDATIONS]: '27%',
      [model_template.PARAM_PRETTY]: '27%',
    }
  },
  setMasterAccountsPostamailTableColsWidth() {
    return {
      [model_masteraccount.NAME]: '35%',
      [model_masteraccount.POSTAMAIL_USER]: '20%',
      [model_masteraccount.POSTAMAIL_PASSWORD]: '20%',
      [model_masteraccount.POSTAMAIL_SFTP_USER]: '20%',
      [model_masteraccount.POSTAMAIL_SFTP_PASSWORD]: '20%',
    }
  }
}

