/**
 * @author sramos
 * @mixin
 */
import store from '../store'
import router from '@/router'
import vuex_global from '@/store/modules/sections/vuex_global'
import vuex_authentication from '@/store/modules/vuex_authentication'
import Vue from 'vue'
import step_events from '@/assets/resources/config_files/step_events'
import helpers from '@/plugins/helpers'
import moment from "moment"

window.Buffer = window.Buffer || require("buffer").Buffer;

/**
 * @description Muestra un mensaje de alerta en pantalla
 * @param status
 * @param message
 */
function setAlertMessage(status, message) {
    store.dispatch(
        vuex_global.constants.NAMESPACE +
        '/' +
        vuex_global.constants.A_SET_ALERT_DIALOG_DATA,
        {status: status, message: message}
    )
}

/**
 *
 * @param str
 * @returns {string}
 */
function stringToColor(str) {
    if (this.nullOrEmpty(str)) {
        return ''
    }
    var hash = 0
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    var colour = '#'
    for (let i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF
        colour += ('00' + value.toString(16)).substr(-2)
    }
    return colour
}

/**
 * Formatear cantidades
 * @param bytes
 * @param decimals
 * @returns {string}
 */
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * @description Obtiene el contenido del fichero desde un B64
 * @param b64Data
 * @param contentType
 * @param sliceSize
 * @returns {Blob}
 */
function filecontent(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, {type: contentType})
    return blob
}

export default {
    setAlertMessage,
    stringToColor,
    formatBytes,
    filecontent,
    /**
     * @description Activa la pantalla de carga global (overlay)
     * @param value
     */
    setLoading() {
    },
    /**
     * @description Obtiene dos iniciales de un texto
     * @param text
     * @returns {string|*}
     */
    getInitials: function (text) {
        let exploded = text.split(' ')

        if (text.split(' ').length > 1) {
            return exploded[0][0] + exploded[1][0]
        }
        exploded = text.split('_')
        if (exploded.length > 1) {
            return (exploded[0].substring(0, 1) + exploded[1].substring(0, 1)).toUpperCase()
        }
        return text.substring(0, 2).toUpperCase()
    },

    /**
     * @description Cambia la ruta de la página sin refrescar, usando el router
     * @param route
     * @param params
     * @param query
     */
    redirect(route, params = [], query = []) {
        let oldRoute = router.currentRoute.name
        router
            .replace({
                name: route,
                params: params,
                query: Object.assign({}, query),
            })
            .catch(() => {
                window.location.reload()
            })
        if (oldRoute == route) {
            window.location.reload()
        }
    },
    /**
     * @description Abre una nueva pestaña
     * @param route
     * @param query
     */
    open(route, query = []) {
        let routeData = router.resolve({name: route, query: query})
        window.open(routeData.href, '_blank')
    },
    /**
     * @description Abre una nueva ventana desde una url dada
     * @param route
     */
    openWithUrl(route) {
        let routeData = router.resolve(route)
        window.open(routeData.href, '_blank')
    },

    /**
     * @description Establece la ruta activa visible en el header
     * @param val
     */
    setActiveRoute(val) {
        store.dispatch(
            vuex_global.constants.NAMESPACE +
            '/' +
            vuex_global.constants.A_SET_ACTIVE_ROUTE,
            val
        )
    },

    async replaceQueryParams(params) {
        let query = Object.assign({}, router.currentRoute.query)
        if (Object.keys(params).length != 0) {
            Object.keys(params).forEach(k => {
                if (params[k] != null) {
                    query[k] = params[k]
                } else {
                    delete query[k]
                }
            })
        }
        if (JSON.stringify(query) != JSON.stringify(router.currentRoute.query)) {
            await router.replace({'query': query})
        }

        return true
    },
    /**
     * @description Formatea un numero dado con "."
     * @param x
     * @returns {string}
     */
    numberWithCommas(x) {
        return helpers.nullOrEmpty(x) ? '0' : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    /**
     * @description Devuelve el texto dado resaltado
     * @param search
     * @param text
     * @returns {string|*}
     */
    highlightText(search, text) {
        if (search == null) {
            return text
        }
        return text
            .toUpperCase()
            .replace(
                search.toUpperCase(),
                '<mark>' + search.toUpperCase() + '</mark>'
            )
    },


    getFormattedTag(tag, compact) {
        let color = 'inherit'
        let name = tag

        if (tag.indexOf('PROD') !== -1) {
            color = '#ed7a72'
            if (compact) {
                name = 'PRO'
            }
        } else if (tag.indexOf('DES') !== -1) {
            color = '#009688'
            if (compact) {
                name = 'DEV'
            }
        } else if (tag.indexOf('PRU') !== -1) {
            color = '#b452db'
            if (compact) {
                name = 'PRU'
            }
        }
        return (
            '<span class=\'v-chip v-size--x-small chipEnvironment mx-2\' style=\'background-color: ' +
            color +
            ';cursor:pointer\'><span class=\'v-chip--content\'>' +
            name +
            '</span></span>'
        )
    },

    /**
     * Función que devuelve si una variable dada está vacia, null, undefined o el array.length = 0
     * @param val
     * @returns {Boolean}
     */
    nullOrEmpty(val) {
        let result = false
        result = val == null || val === '' || val == undefined || val.length == 0
        return result
    },
    /**
     * Devuelve la letra del abecedario
     * @returns {String}
     */
    getLetter(index) {
        let letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 'u', 'v', 'w', 'x', 'y', 'z']
        return letters[index] ? letters[index].toUpperCase() : undefined
    },
    /**
     * Devuelve los meses del año
     * @returns {Array}
     */
    getMonths() {
        return [
            {
                code: 1,
                name: 'Enero',
            },
            {
                code: 2,
                name: 'Febrero',
            },
            {
                code: 3,
                name: 'Marzo',
            },
            {
                code: 4,
                name: 'Abril',
            },
            {
                code: 5,
                name: 'Mayo',
            },
            {
                code: 6,
                name: 'Junio',
            },
            {
                code: 7,
                name: 'Julio',
            },
            {
                code: 8,
                name: 'Agosto',
            },
            {
                code: 9,
                name: 'Septiembre',
            },
            {
                code: 10,
                name: 'Octubre',
            },
            {
                code: 11,
                name: 'Noviembre',
            },
            {
                code: 12,
                name: 'Diciembre',
            },
        ]
    },

    getYears() {
        let years = []
        let year = 2019

        while (year <= moment().year()) {
            years.push(year)
            year++
        }
        return years
    },

    /**
     * Devuelve un objeto ordenado
     * @param property
     * @param desc
     * @returns {function(*, *)}
     */
    dynamicSort(property, desc) {
        let sortOrder = 1
        if (property[0] === '-') {
            sortOrder = -1
            property = property.substr(1)
        }
        return function (a, b) {
            let result
            if (desc) {
                result =
                    a[property] > b[property] ? -1 : a[property] > b[property] ? 1 : 0
            } else {
                result =
                    a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
            }
            return result * sortOrder
        }
    },
    /**
     *
     * @param value
     * @returns String
     */
    capitalize(value) {
        return (value.charAt(0).toUpperCase() + value.slice(1)).toString();
    },
    /**
     * Transforma los shortcodes en timestamps
     * @param timestampShortcode
     * @returns {{from: null, to: null}}
     */
    getTimestampValues(timestampShortcode) {
        let dates = {
            from: null,
            to: null,
            type: 'custom'
        }

        if (timestampShortcode.indexOf('next') != -1) {
            dates.from = moment()
            switch (timestampShortcode) {
                case 'next_10_minutes':
                    dates.to = moment().add(10, 'minutes')
                    break
                case 'next_24_hours':
                    dates.to = moment().add(1, 'day')
                    break
                case 'next_week':
                    dates.to = moment().add(1, 'week')
                    break
                case 'next_month':
                    dates.to = moment().add(1, 'month')
                    break
            }
        } else {
            switch (timestampShortcode) {
                case 'last_10_minutes':
                    dates.from = moment().subtract(10, 'minutes')
                    break
                case 'last_24_hours':
                    dates.from = moment().subtract(1, 'day')
                    break
                case 'last_week':
                    dates.from = moment().subtract(1, 'week')
                    break
                case 'last_month':
                    dates.from = moment().subtract(1, 'month')
                    break
            }
            dates.to = moment()
        }

        dates.from = dates.from.unix()
        dates.to = dates.to.unix()
        return dates
    },

    /**
     * Check de si una variable es JSON
     * @param value
     * @returns {boolean}
     */
    isJson(value) {
        try {
            if (
                typeof value == 'object' ||
                value.substr(0, 1) == '{'
            ) {
                value = JSON.parse(value)
                return true
            }
        } catch (e) {
            return false
        }
        return false
    },
    copyCellVal(ref, text) {
        let container = ref
        if (typeof text == 'object') {
            text = JSON.stringify(text)
        }
        Vue.prototype.$copyText(text, container).then(function () {
            setAlertMessage(0, 'Valor copiado')
        }, function () {
            setAlertMessage(1, 'Valor no copiado')
        })
    }
}

export const createExpirationTimeout = (/* msTime = 840000 14mins */) => {
    const now = moment().add(13, 'minutes')
    store.dispatch(`${vuex_authentication.constants.NAMESPACE}/${vuex_authentication.constants.A_SET_EXPIRATION_TIMEOUT}`, moment(now))
}

export const downloadFile = (file) => {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.href = window.URL.createObjectURL(
        filecontent(file.fileContent, file.fileType)
    )

    a.setAttribute('download', file.fileName)
    a.click()

    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
}

export const isParameter = value => {
    return /%%\w+%%/.test(value)
}

export const isStep = value => {
    return /%%\w+\.\w+%%/.test(value)
}

export const lockType = lock => {
    if (/EVENT@/gi.test(lock)) {
        return 'event'
    } else if (/VAR@/gi.test(lock)) {
        return 'var'
    }
}

export const parseLockString = lock => {
    return lock.replace(/\w+@/i, '')
}

export const lockSyntaxHighlight = lockString => {
    const parsedString = parseLockString(lockString)
    if (lockType(lockString) == 'var') {
        const equalTo = `<span class="purple--text">${parsedString.slice(parsedString.indexOf('=') + 1)}</span>`
        const varName = `<span class="blue--text">${parsedString.slice(0, parsedString.indexOf('='))}</span>`

        return `<span>${varName} = ${equalTo}</span>`
    }

    if (lockType(lockString) == 'event') {
        return `<span class="brown--text">${parsedString}</span>`
    }
}


export const codeTypeColor = type => {
    switch (type) {
        case 'string':
            return 'blue--text'

        case 'boolean':
            return 'red--text'

        case 'number':
            return 'purple--text'

        case 'parameter':
            return 'green--text'

        case 'step':
            return 'lime--text'

        default:
            return ''
    }
}


export const getTemplateStepDescription = (workerCode) => {
    if (step_events[workerCode].description) {
        return step_events[workerCode].description
    }
    return 'Descripción'
}

export const parseJSONOrArray = (str) => {
    try {
        const parsedObject = JSON.parse(str);

        if (Array.isArray(parsedObject)) {
            return {type: 'array', data: parsedObject};
        } else if (typeof parsedObject === 'object' && parsedObject !== null) {
            return {type: 'json', data: parsedObject};
        } else {
            return {type: 'invalid', data: null};
        }
    } catch (error) {
        return {type: 'invalid', data: str};
    }
}

export function decodeBase64WithTildes(base64String) {
    try {
        const buffer = Buffer.from(base64String, 'base64'); // Decodifica desde Base64
        const decoder = new TextDecoder('utf-8', {ignoreBOM: true});
        return decoder.decode(buffer);
    } catch (error) {
        console.error("Error al decodificar Base64:", error);
        return null;
    }
}

export function generateUniqueID(prefix, length = 24) {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; // Lista de letras en mayúscula
    let uniqueID = prefix;

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * letters.length);
        uniqueID += letters[randomIndex];
    }

    return uniqueID;
}

export function formatTimeDifference(timestamp1, timestamp2) {
    const moment1 = moment.unix(parseInt(timestamp1));
    const moment2 = moment.unix(parseInt(timestamp2));
    const diffInDays = moment2.diff(moment1, 'days');
    const diffInHours = moment2.diff(moment1, 'hours');
    const diffInMinutes = moment2.diff(moment1, 'minutes');
    if (diffInDays > 0) {
        return `${diffInDays} día${diffInDays > 1 ? 's' : ''}`;
    } else if (diffInHours > 0) {
        return `${diffInHours} hora${diffInHours > 1 ? 's' : ''}`;
    } else {
        return `${diffInMinutes} minuto${diffInMinutes > 1 ? 's' : ''}`;
    }
}