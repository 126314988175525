<template>
    <tr
      ref="instance_callbacks_row"
      class="cb-data-table-row cb-row-with-copy"

    >
        <td
          v-for="(field, index) in fields.filter(f => !f.hide)"
          :key="index"
          :style="field.value == 'actions' || field.value == model_callback.constants.STATUS_CODE ? ';padding: 0 !important;text-align:center' : helpers_resolution.getColWidth(field.width) "
          class="text-truncate"
        >
      <span
        v-if="field.value == model_callback.constants.STATUS_CODE"
        :title="callback[model_callback.constants.STATUS_CODE]"
        class="font-weight-medium mx-auto"
      >
        <v-chip
          :color="callback[model_callback.constants.STATUS_CODE]  == '200'  ?'success' : 'error'"
          label
          link
          small
          title="ver callback"
          @click="openCallback"
        ><span
          class="cb-font-size-5"
          style="color: white !important;"
        >{{callback[model_callback.constants.STATUS_CODE]}}</span></v-chip>
      </span>
            <span v-else-if="field.value == model_callback.constants.TIMESTAMP">
        {{callback[field.value] | moment('DD/MM/YYYY HH:mm:ss')}} ({{callback[field.value] | moment('from')}})
      </span>
            <v-row
              v-else-if="field.value == 'open'"
              justify="end"
            >
                <v-btn
                  icon
                  small
                  title="ver detalles del callback"
                  @click="openCallback"
                >
                    <v-icon
                      color="primary"
                      small
                    >visibility
                    </v-icon>
                </v-btn>
            </v-row>
            <span v-else-if="field.value == model_callback.constants.PARAMETERS">
        <a
          v-if="preview"
          @click="openCallback"
        >{{
                callback[field.value]
            }}</a>
        <span v-else>{{
                callback[field.value]
            }}</span>
          <v-icon
            class="cb-copy-cell-val"
            title="copiar celda"
            @click="helpers.copyCellVal($refs.instance_callbacks_row, callback[field.value])"
          >
            copy_all
          </v-icon>
      </span>
            <span
              v-else-if="field.value == 'actions'"
            >
            <MenuModelA
              :actions="rowActions"
              :icon="$icons.I_ACTIONS"
              @actionLaunched="actionLaunched"
            />
          </span>
            <span
              v-else
              :title="callback[field.value]"
            >
          <span v-if="callback[field.value] != null">{{callback[field.value]}}</span>
        <span
          v-else
          class="cb-text-descriptor"
        >Vacío</span>
          <v-icon
            class="cb-copy-cell-val"
            title="copiar celda"
            @click="helpers.copyCellVal($refs.instance_callbacks_row, callback[field.value])"
          >
            copy_all
          </v-icon>
        </span>

        </td>
    </tr>
</template>

<script>
import helpers from '@/plugins/helpers'
import model_callback from '@/constants/models/protocol/model_callback'
import MenuModelA from '@/components/repository/buttons/MenuModelA.vue'
import icons from '@/constants/icons'
import service_user from '@/services/service_user'
import {P_INSTANCES} from '@/constants/group_permissions'
import helpers_resolution from '@/plugins/helpers_resolution'


export default {
    name: 'callbacksTableRow',
    components: {MenuModelA},
    data() {
        return {
            model_callback,
            helpers,
            helpers_resolution
        }
    },
    props: {item: Object, fields: Array, preview: Boolean},
    computed: {
        callback() {
            let callback = JSON.parse(JSON.stringify(this.item))
            if (typeof this.item[model_callback.constants.PARAMETERS] != 'string') {
                callback[model_callback.constants.PARAMETERS] = JSON.stringify(this.item[model_callback.constants.PARAMETERS])
            }
            return callback
        },
        rowActions() {
            let actions = []
            actions.push({
                title: 'Ver detalles',
                icon: icons.I_VIEW,
                id: 'open',
                enabled: true,
            })
            if (service_user.checkGroupPermission(P_INSTANCES)) {

                actions.push({
                    title: 'Ver instancia',
                    icon: icons.I_INSTANCE,
                    id: 'show_instances',
                    enabled: true,
                })
            }


            return actions
        }
    },
    methods: {
        actionLaunched(action) {
            if (action == 'open') {
                this.openCallback()
            }
            if (action == 'show_instances') {
                this.openInstance()
            }
        },
        openCallback() {
            this.$emit('openCallback', this.callback)
        },
        openInstance() {
            helpers.open('instances', {
                name: this.callback[model_callback.constants.INSTANCE_CODE],
                id: this.callback[model_callback.constants.INSTANCE_CODE]
            })

        }
    },
    filters: {}
}
</script>
