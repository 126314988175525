import Helpers from '@/plugins/helpers'
import helpers from '@/plugins/helpers'
import Axios from 'axios'
import service_user from '@/services/service_user'
import {P_INSTANCE_REDIS, P_INSTANCE_RESOURCES} from '@/constants/group_permissions'
import service_explorers from '@/services/global/service_explorers'

export default {

  openInstance(code, section) {
    helpers.open('instances', {
      id: code,
      name: code,
      section: section
    })
  },

  findInstances(fields, filters, options) {
    return service_explorers.find('app-center/instances', fields, filters, options)

  },
  getInstanceDetails(code) {
    return Axios
      .get(process.env.VUE_APP_ENDPOINT + 'app-center/instances/' + code)
      .then((response) => {
        if (response.data.error == 1) {
          helpers.setAlertMessage(1, response.data.message)
          return false
        } else {
          return response.data.extra
        }
      })
  },
  /**
   * Devuelve el código de la instancia en base al id de postamail
   * @param postamail_id
   * @return {Promise<AxiosResponse<instance_code>>}
   */
  getInstanceFromPostamailId(postamail_id) {
    return Axios
      .get(process.env.VUE_APP_ENDPOINT + 'app-center/postal/shipment/' + postamail_id)
      .then((response) => {
        if (response.data.error == 1) {
          helpers.setAlertMessage(1, response.data.message)
        } else {
          return response.data.extra.instance_code
        }
      })
  },
  getInstanceRedisInfo(instance_code) {
    service_user.checkGroupPermission(P_INSTANCE_REDIS, true)

    return Axios.get(
      process.env.VUE_APP_ENDPOINT +
      'app-center/instances/' +
      instance_code +
      '/redis'
    )
      .then((response) => {
        return response.data.extra
      })

  },
  getInstanceRedisKeyInfo(instance_code, context, key) {
    service_user.checkGroupPermission(P_INSTANCE_REDIS, true)

    return Axios
      .get(
        process.env.VUE_APP_ENDPOINT +
        'app-center/instances/' +
        instance_code +
        '/rediskey', {
          params: {
            context: context,
            key: key
          }
        }
      )
      .then((response) => {
        return response.data.extra
      })

  },
  getInstanceResources(instance_code) {
    service_user.checkGroupPermission(P_INSTANCE_RESOURCES, true)
    return Axios
      .get(
        process.env.VUE_APP_ENDPOINT +
        'app-center/instances/' +
        instance_code +
        '/resources'
      )
      .then((response) => {
        if (response.data.error == 0) {
          return response.data.extra
        }
      })
  },
  getResourceFile(instance_code, path) {
    service_user.checkGroupPermission(P_INSTANCE_RESOURCES, true)

    Axios
      .get(
        process.env.VUE_APP_ENDPOINT +
        'app-center/instances/' +
        instance_code +
        '/resources/' + btoa(path)
      )
      .then(async (response) => {
        if (response.data.error == 1) {
          helpers.setAlertMessage(1, response.data.message)
          return
        }
        const a = document.createElement('a')
        a.style.display = 'none'
        document.body.appendChild(a)
        a.href = window.URL.createObjectURL(
          Helpers.filecontent(response.data.extra.filecontent, response.data.extra.filetype)
        )

        a.setAttribute('download', response.data.extra.filename)
        a.click()

        window.URL.revokeObjectURL(a.href)
        document.body.removeChild(a)
      })
  },
  getBackup(instance_code) {
    return Axios
      .get(
        process.env.VUE_APP_ENDPOINT +
        'app-center/instances/' +
        instance_code +
        '/backup'
      )
      .then((response) => {
        return response.data.extra
      })
  },
  downloadCertificate(instance_code) {
    return Axios
      .get(
        process.env.VUE_APP_ENDPOINT +
        'app-center/instances/' +
        instance_code +
        '/certificate'
      )
      .then((response) => {
        if (response.data.error === 0) {
          window.open(response.data.extra.certificateUrl, '_blank')
        }
      })
  },
  downloadPee(instance_code, id) {
    return Axios
      .get(
        process.env.VUE_APP_ENDPOINT +
        'app-center/instances/' +
        instance_code +
        '/pee/' + id
      )
      .then((response) => {
        if (response.data.error === 0) {
          window.open(response.data.extra.peeUrl, '_blank')
        }
      })
  },
  cancelTransaction(instance_code) {
    return Axios
      .post(
        process.env.VUE_APP_ENDPOINT +
        'app-center/instances/' +
        instance_code +
        '/cancel'
      )
      .then((response) => {
        if (response.data.error === 0) {
          helpers.setAlertMessage(response.data.error, response.data.message)
          window.location.reload()
        }
      })
  },
}
