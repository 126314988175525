var color_ok = '#6ba84f'
var color_ko = '#e76767'
var color_warning = '#e89357'
//var color_info = '#8ec0b7'

export default {
  'communication_send_email': {
    'description': 'Paso utilizado para el envío de email',
    'events': {
      'delivered': {
        'description': 'El email ha sido recibido por el destinatario',
        'color': color_ok

      },
      'complaint': {
        'description': 'Se ha recibido una queja sobre el email',
        'color': color_ko
      },
      'queued': {
        'description': 'El email ha sido encolado en la bandeja de salida',
        'color': color_warning
      },
      'deferred': {
        'description': 'El servidor de correo del destinatario ha rechazado temporalmente la entrega del email. Se reintentará la enterga en las próximas horas.',
        'color': color_warning
      },
      'bounce': {
        'description': 'El email ha sido rebotado.',
        'color': color_ko
      },
      'sent': {
        'description': 'El email ha sido enviado.',
        'color': color_ok
      },
      'sender_not_allowed': {
        'description': 'El remitente no es válido.',
        'color': color_ko
      },
      'open': {
        'description': 'El email ha sido abierto por el destinatario.'
      },
      'block': {
        'description': 'El email ha sido bloqueado por diversas causas (bloqueado por el servidor, por el usuario o por el contenido del email).',
        'color': color_ko
      },
      'unsubscribe': {
        'description': 'El destinatario ha rechazado pertenecer a la listas de envio del remitente.'
      },
      'spam': {
        'description': 'El email ha sido marcado como spam.',
        'color': color_ko
      },
      'hard_blacklisted': {
        'description': 'El email está en una lista de exclusión debido a un hardbounce',
        'color': color_ko
      },
      'complaint_blacklisted': {
        'description': 'El email está en una lista de exclusión por quejas',
        'color': color_ko
      }
    }
  },
  'commstracker_generate_url': {
    'description': 'Paso utilizado para la generación de enlaces trazados',
    'events': {
      'click': {
        'description': 'Se ha hecho click sobre el enlace generado'
      },
      'generated': {
        'description': 'Se ha generado el enlace trazado'
      }
    }
  },
  'web_framework': {
    'description': 'Paso utilizado para generar una estructura web (Framework Web)',
    'icon': 'web',
    'color': 'gray',
    'events': {
      'generated': {
        'description': 'Se ha generado el Framework Web'
      },
      'solicitud_otp': {
        'description': 'El usuario solicita un código OTP'
      },
      'signable_document_loaded': {
        'description': 'Se ha cargado el documento para su firma'
      },
      'otp_simply_signature_ok': {
        'description': 'Firma correcta a través de código OTP',
        'color': color_ok
      },
      'document_signed': {
        'description': 'Documento firmado grafométricamente',
        'color': color_ok
      },
      'certificate_based_identification': {
        'description': 'Identificación mediante certificado correcta'
      },
      'document_based_identification': {
        'description': 'Identificación mediante imagen de documento correcta'
      },
      'certificate_based_identification_failed': {
        'description': 'Identificación mediante certificado fallida',
        'color': color_ko
      },
      'document_based_identification_failed': {
        'description': 'Identificación mediante imagen de documento fallida',
        'color': color_ko
      },
      'form_based_identification_failed': {
        'description': 'Identificación mediante campo de formulario fallida',
        'color': color_ko
      },
      'web_framework_expired': {
        'description': 'El Framework Web ha caducado'
      },
      'documentation_downloaded': {
        'description': 'Se ha descargado la documentación del Framework Web'
      },
      'electronic_notification_rejected': {
        'description': 'Se ha rechazado la notificación electrónica de documentación'
      },
      'gdpr_formulary_commmited': {
        'description': 'El formulario de aceptación GDPR se ha enviado'
      },
      'sepa_formulary_commmited': {
        'description': 'El formulario de aceptación SEPA se ha enviado'
      },
      'requested_otp_code': {
        'description': 'Código OTP requerido para la firma de documentación desde el Framework Web.'
      },
      'entered_wrong_otp_code': {
        'description': 'El código OTP introducido es incorrecto'
      },
      'entered_timedout_otp_code': {
        'description': 'Se ha introducido un código OTP expirado'
      },
      'entered_correct_otp_code': {
        'description': 'El código OTP introducido es correcto'
      },
      'signature_rejected': {
        'description': 'Proceso de firma rechazado.'
      },
      'requested_otp_code_too_early': {
        'description': 'Se ha solicitado un código OTP por parte del usuario demasiado pronto'
      },
      'capture_data_commited_no_response': {
        'description': 'Intento de enviar datos sin respuesta'
      },
      'capture_data_commited': {
        'description': 'Envio de datos realizado con éxito'
      },
      'commited': {
        'description': 'Respuesta registrada'
      },
      'form_based_identification': {
        'description': 'Identificación mediante campo de formulario correcta'
      },
      'document_simply_signed': {
        'description': 'Documento firmado correctamente',
        'color': color_ok
      },
      'entered_wrong_otp_identification_code': {
        'description': 'Se ha introducido un código OTP incorrecto para identificarse'
      },
      'entered_timedout_otp_identification_code': {
        'description': 'Se ha introducido un código OTP expirado para identificarse'
      },
      'entered_correct_otp_identification_code': {
        'description': 'Identificación correcta a través de código OTP'
      }
    }
  },
  'communication_send_sms': {
    'description': 'Paso utilizado para el envío de SMS',
    'icon': 'sms',
    'color': 'gray',
    'events': {
      'sent': {
        'description': 'Se ha enviado el SMS',
        'icon': 'chat_bubble',
        'color': color_ok
      },
      'not_sent': {
        'description': 'No se ha enviado el SMS',
        'icon': 'sms_failed',
        'color': color_ko,
      },
      'delivered': {
        'description': 'Se ha entregado el SMS al destinatario',
        'icon': 'mark_chat_read',
        'color': color_ok
      },
      'not_delivered': {
        'description': 'No se ha entregado el SMS al destinatario',
        'icon': 'sms_failed',
        'color': color_ko
      }
    }
  },
  'publish_embeddable_file': {
    'description': 'Paso utilizado para embeber un documento y poder visualizarlo',
    'icon': 'description',
    'events': {
      'generated': {
        'description': 'Se ha embebido el documento'
      }
    }
  },
  'publish_downloadable_file': {
    'description': 'Paso utilizado para publicar un documento y poder descargarlo',
    'icon': 'description',
    'color': 'gray',
    'events': {
      'generated': {
        'description': 'Se ha publicado el documento a disposición de usuario',
        'icon': 'upload_file'
      },
      'open': {
        'description': 'Se ha descargado el documento publicado',
        'icon': 'file_download',
        'color': 'success',
      }
    }
  },
  'generate_certificate': {
    'description': 'Paso utilizado para generar un certificado de las evidencias del proceso',
    'icon': 'workspace_premium',
    'color': 'warning',
    'events': {
      'generated': {
        'description': 'Se ha generado el certificado de evidencias.'
      }
    }
  },
  'document_upload_s3': {
    'description': 'Paso utilizado para subir un certificado a S3 y poder descargarlo',
    'icon': 'cloud_upload',
    'color': 'info',
    'events': {
      'uploaded': {
        'description': 'Se ha subido el certificado a S3'
      }
    }
  },
  'instance_delete': {
    'description': 'Paso utilizado para eliminar la instancia despues de un tiempo específico',
    'events': {
      'deleted': {
        'description': 'Se ha eliminado la instancia correctamente'
      }
    }
  },
  'document_sign': {
    'description': 'Paso utilizado para incrustar una firma en un documento específico',
    'events': {
      'signed': {
        'description': 'Se ha firmado el documento correctamente'
      }
    }
  },
  'compose_dynamic_document': {
    'description': 'Paso utilizado para componer dinamicamente un documento con unos datos previamente especificados',
    'events': {
      'composed': {
        'description': 'El documento se ha compuesto correctamente'
      }
    }
  },
  'identification_create': {
    'description': 'Paso utilizado para generar un proceso de identificación',
    'events': {
      'identification_generated': {
        'description': 'El proceso de identificación del usuario se ha generado correctamente'
      }
    }
  },
  'communication_send_postal': {
    'description': 'Paso utilizado para el envío de comunicaciones postales',
    'events': {
      'generated': {
        'description': 'Se ha generado el envio postal',
        'color': color_ok
      },
      'sent_to_factory': {
        'description': 'El envio ha sido remitido a fábrica'
      },
      'printed': {
        'description': 'Se ha impreso la comunicación postal'
      },
      'pre_admission_requested': {
        'description': 'Pre admision solicitada al operador logístico'
      },
      'pre_admitted': {
        'description': 'Se ha pre admitido la comunicación'
      },
      'sent_to_logistic_operator': {
        'description': 'La comunicaión postal ha sido enviada al operador logístico'
      },
      'on_the_way_to_logistic_operator': {
        'description': 'La comunicación postal está de camino al operador logístico'
      },
      'received_by_logistic_operator': {
        'description': 'El operador logistico ha recibido la comunicación postal'
      },
      'not_received_by_logistic_operator': {
        'description': 'El operador logistico no ha recibido la comunicación postal',
        'color': color_ko
      },
      'on_delivery': {
        'description': 'La comunicación está en reparto'
      },
      'delivered_to_recipient': {
        'description': 'La comunicación postal ha sido entregada al destinatario'
      },
      'returned_to_postal_office': {
        'description': 'La comunicación ha sido devuelta a la oficina postal'
      },
      'delivered_into_postal_office': {
        'description': 'La comunicacion ha sido recogida en la oficina postal'
      },
      'generic_delivered': {
        'description': 'La comunicación ha sido entregada por el operador postal'
      },
      'returned': {
        'description': 'La comunicación ha sido devuelta por parte del operador postal'
      },
      'canceled': {
        'description': 'La comunicación ha sido cancelada',
        'color': color_ko
      },
      'lost': {
        'description': 'La comunicación se ha perdido',
        'color': color_ko
      },
      'expired': {
        'description': 'La comunicación ha expirado',
        'color': color_ko
      },
      'rejected': {
        'description': 'La comunicación ha sido rechazada',
        'color': color_ko
      },
      'stolen': {
        'description': 'La comunicación ha sido robada',
        'color': color_ko
      },
      'outside_sicer': {
        'description': 'El envío está fuera del ámbito sicer',
        'color': color_ko
      },
      'bad_postal_address': {
        'description': 'La dirección postal no es válida o está incompleta',
        'color': color_ko
      },
      'passed_away': {
        'description': 'El destinatario de la comunicación ha fallecido',
        'color': color_ko
      },
      'other': {
        'description': 'Otros eventos'
      },
      'no_information': {
        'description': 'No hay información sobre la comunicación'
      },
      'received_pee': {
        'description': 'Prueba electronica de entrega recibida',
        'color': color_ok
      },
      'certificate_returned': {
        'description': 'Se generado un certificado de devolución',
        'color': color_ok
      },
      'unknown': {
        'description': 'Estado desconocido'
      }
    }
  },
  'communication_send_telegram': {
    'description': 'Paso utilizado para el envío de comunicaciones a través de Telegram',
    'events': {
      'delivered': {
        'description': 'Mensaje entregado via Telegram'
      },
      'not_delivered': {
        'description': 'No pudo entregase el mensaje via Telegram'
      }
    }
  },
  'document_upload_alastria': {
    'description': 'Paso utilizado para subir documentación a la red Alastria',
    'events': {
      'uploaded': {
        'description': 'Se ha subido la documentación a la red Alastria'
      }
    }
  },
  'loop_add': {
    'description': 'Paso utilizado para iterar un numero determinado de veces según los tiempos establecidos. Normalmente se utiliza para ejecutar reintentos',
    'events': {
      'added': {
        'description': 'El bloque de reintentos ha comenzado'
      },
      'tick': {
        'description': 'Se ha ejecutado una iteración/reintento'
      },
      'timeout': {
        'description': 'Se ha ejecutado la ultima iteración/reintento'
      }
    }
  },
  'document_upload_sftp': {
    'description': 'Paso utilizado para la subida de ficheros a SFTP',
    'events': {
      'uploaded': {
        'description': 'El documento se ha subido a SFPT'
      }
    }
  },
  'generate_onespan_transaction': {
    'description': '',
    'events': {
      'transaction_create': {
        'description': 'Se ha creado correctamente la transacción de firma'
      },
      'transaction_create_error': {
        'description': 'Se ha producido un error al crear la transacción de firma/no creada'
      },
      'transaction_complete': {
        'description': 'Se ha completado la transacción de firma'
      },
      'transaction_expire': {
        'description': 'La transaccion de firma ha expirado'
      },
      'transaction_decline': {
        'description': 'Un firmante ha decidido rechazar la transacción de firma'
      },
      'transaction_email_bounce': {
        'description': 'El email de la transacción de firma ha rebotado'
      },
      'transaction_opt_out': {
        'description': 'Un firmante no ha completado el proceso de firma'
      },
      'transaction_delete': {
        'description': 'La transacción de firma ha sido eliminada'
      },
      'transation_desactivate': {
        'description': 'La transacción de firma ha sido desactivada'
      },
      'kba_failure': {
        'description': 'La transacción ha sido bloqueada por motivos de seguridad'
      },
      'transaction_sent': {
        'description': 'Transacción de firma enviada'
      },
      'transaction_sent_error': {
        'description': 'Se ha producido un error al enviar la transacción'
      },
      'package_trash': {
        'description': ''
      }
    }
  },
  'get_onespan_files': {
    'description': '',
    'events': {
      'files_onespan_generated': {
        'description': 'Se han generado los ficheros de OneSpan'
      }
    }
  },
  'zip_files_path': {
    'description': 'Paso utilizado para comprimir ficheros',
    'events': {
      'zipped': {
        'description': 'Los ficheros se han comprimido correctamente'
      }
    }
  },
  'generate_otp_code': {
    'description': 'Paso utilizado para generar un código OTP',
    'events': {
      'otp_generated': {
        'description': 'Se ha generado el código OTP'
      }
    }
  },
  'orchestrator_new_communications': {
    'description': '',
    'icon': 'add_circle',
    'events': {
      'communication_received': {
        'description': 'Transacción admitida'
      }
    }
  },
  'robinson_list': {
    'description': '',
    'events': {
      'accepted': {
        'description': 'El destinatario no está en la lista Robinson'
      },
      'declined': {
        'description': 'El destinatario está en la lista Robinson'
      },
      'no_data': {
        'description': 'El destinatario no está en la lista Robinson / Sin datos'
      }
    }
  },
  'file_delete': {
    'description': 'Paso utilizado para eliminar ficheros previamente especificados',
    'events': {
      'deleted': {
        'description': 'Se ha eliminado el fichero especificado'
      }
    }
  },
  'loop_cancel': {
    'description': 'Paso utilizado cancelar un paso de reintentos (loop_add) atendiendo a unos eventos previamente especificados',
    'events': {
      'canceled': {
        'description': 'Se ha cancelado el looper especificado'
      }
    }
  },
  'test_load': {
    'description': 'Paso utilizado para realizar test de carga',
    'events': {
      'processed': {
        'description': 'Test de carga realizado con éxito'
      }
    }
  },
  'unzip_file': {
    'description': 'Paso utilizado para extraer ficheros',
    'events': {
      'extracted': {
        'description': 'Se ha extraido el fichero'
      }
    }
  },
  'communicalia_consent': {
    'description': 'Paso utilizado para recoger el consentimiento de Communicalia',
    'events': {
      'accepted': {
        'description': 'Tiene consentimiento por parte de Communicalia'
      },
      'declined': {
        'description': 'No tiene consentimiento por parte de Communicalia'
      },
      'no_data': {
        'description': 'No hay datos en Communicalia'
      }
    }
  },
  'disable_framework_web': {
    'description': 'Paso utilizado deshabilitar el Framework Web',
    'events': {
      'framework_web_disabled': {
        'description': 'Se ha deshabilidato el Framework Web'
      }
    }
  },
  'intromission_api': {
    'description': 'APi para intervenir sobre los reintentos',
    'events': {
      'retries_muted': {
        'description': 'Se han desactivado los reintentos'
      },
      'retries_unmuted': {
        'description': 'Se han activado los reintentos'
      },
      'retries_canceled': {
        'description': 'Se ha cancelado la intancia'
      }
    }
  }

}
