<template>
  <v-col class="pa-0">
    <v-row>
      <p>
        En el explorador de instancias puedes buscar y filtrar instancias facilmente además de conocer sus detalles. A
        tener en cuenta:
      </p>
      <ul class="cb-list">
        <li>
          Para realizar búsquedas <strong>siempre</strong> debe haber seleccionada una <strong>cuenta raíz</strong>
        </li>
        <li>
          Al abrir una nueva instancia desde
          <v-icon color="primary">open_in_new</v-icon>
          se abrirá en una nueva <strong>pestaña interna</strong> en la aplicación. El <strong>límite</strong> es
          <strong>6</strong>
          pestañas
        </li>
      </ul>
      <span>ss</span>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'InstancesHelper'
}
</script>


