import {objectToFormData} from 'object-to-formdata'
import Axios from 'axios'
import model_process from '@/constants/models/model_process'
import model_masteraccount from '@/constants/models/model_masteraccount'
import model_subaccount from '@/constants/models/model_subaccount'
import model_project from '@/constants/models/model_project'
import service_protocol from '@/services/service_protocol'
import helpers, {downloadFile} from '@/plugins/helpers'
import model_billingUnit from '@/constants/models/protocol/model_billingUnit'
import model_callback from '@/constants/models/protocol/model_callback'
import service_explorers from '@/services/global/service_explorers'
import vuex_processes from '@/store/modules/sections/vuex_processes'
import store from '@/store'

export default {

    openProcess(code, name, section) {
        helpers.open('processes', {
            id: code,
            name: name,
            section: section
        })
    },

    openProcesses(searchFilters) {
        store.dispatch(vuex_processes.constants.NAMESPACE + '/' +
            vuex_processes.constants.A_SET_SEARCH_FILTERS, searchFilters).then(() => {
            helpers.open('processes')
        })
    },
    createProcess(info) {
        const formData = objectToFormData(info)
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/processes', formData
        ).then((response) => {
            return response.data
        })
    },
    createProject(info) {
        const formData = objectToFormData(info)
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/projects', formData
        ).then((response) => {
            return response.data
        })
    },
    findProcesses(fields, filters, options) {
        return service_explorers.find('app-center/processes', fields, filters, options)
    },
    getProcessInfo(process_code) {
        return Axios.get(
            process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code
        )
    },
    getRelevantStatuses(process_code) {
        return Axios
            .get(
                process.env.VUE_APP_ENDPOINT +
                'app-center/processes/' +
                process_code +
                '/relevant-events'
            )
            .then((response) => {
                if (response.data.error == 0) {
                    let events = []
                    response.data.extra.events.forEach((te) => {
                        let fre = response.data.extra.relevant_events.find((re) => re.name == te)
                        events.push({
                            name: te,
                            milestone:
                                fre != null &&
                                (fre.treatment === 1 ||
                                    fre.treatment === 2 ||
                                    fre.treatment === 3),
                            macrostate:
                                fre != null && (fre.treatment === 2 || fre.treatment === 3),
                            terminal: fre != null && fre.treatment === 3,
                        })
                    })
                    return {
                        events: events,
                        eventsActive: response.data.extra.relevant_events
                    }
                }
                helpers.setAlertMessage(2, 'Alerta: "' + response.data.message + '"')

                return {
                    events: [],
                    eventsActive: []
                }
            })
    },

    getProcessResources(process_code, language) {
        return Axios
            .get(
                process.env.VUE_APP_ENDPOINT +
                'app-center/processes/' +
                process_code +
                '/resources/' +
                language
            )

    },
    getLanguages(process_code) {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/languages')
    },
    deleteFile(process_code, language, fileName) {
        return Axios.delete(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/resources/' + language + '/file/' + fileName)
    },
    downloadFile(process_code, language, fileName) {
        Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/resources/' + language + '/file/' + fileName)
            .then((response) => {
                const a = document.createElement('a')
                a.style.display = 'none'
                document.body.appendChild(a)
                a.href = window.URL.createObjectURL(
                    helpers.filecontent(
                        response.data.extra.filecontent,
                        response.data.extra.filetype
                    )
                )
                a.setAttribute('download', response.data.extra.filename)
                a.click()
                window.URL.revokeObjectURL(a.href)
                document.body.removeChild(a)
            })
    },
    generateAndSendDocumentation(process_code, data) {
        return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/documentation', objectToFormData(data))
    },
    downloadSwagger(process_code) {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/swagger')
            .then((response) => {
                downloadFile({
                    fileName: 'Swagger_' + process_code + '.yml',
                    fileContent: response.data.file,
                    fileType: 'yml'
                })
            })
    },

    getTemplateVersions(template_code) {
        return Axios.get(`${process.env.VUE_APP_ENDPOINT}app-center/templates/${template_code}/versions`).then((response) => {
            let versions = response.data.reverse()
            let results = []
            versions.forEach(v => {
                results.push({
                    name: v.tag + ' (V' + v.version + ')',
                    tag: v.tag,
                    version: v.version,
                    description: v.description
                })
            })
            return results
        })
    },
    getTemplateApiFields(process_code, template_version) {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/launchInfo/' + template_version).then((response) => {
            return response.data.extra
        })
    },
    getProcessCallbacks(process_code) {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/callbacks')
            .then((response) => {
                return response.data.extra
            })
    },
    /* Actions */
    saveProcessProperties(process_code, properties) {
        let params = {}
        Object.keys(properties).forEach((key_prop) => {
            if (typeof properties[key_prop] == 'undefined') {
                properties[key_prop] = null
            }
            params[key_prop] = properties[key_prop]
        })
        let form_data_object = objectToFormData(params, {indices: true})
        return Axios.post(
            process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code,
            form_data_object
        )
    },
    setRelevantStatuses(process_code, events) {

        let eventsResult = []

        events.forEach((te) => {
            let treatment = 0

            if (te.milestone) {
                treatment = 1
            }
            if (te.macrostate && te.milestone) {
                treatment = 2
            }
            if (te.milestone && te.terminal) {
                treatment = 3
            }
            if (treatment != 0) {
                eventsResult.push({
                    name: te.name,
                    treatment: treatment,
                })
            }
        })

        const formData = objectToFormData({
            events: JSON.stringify(eventsResult),
        })
        return Axios
            .post(
                process.env.VUE_APP_ENDPOINT +
                'app-center/processes/' +
                process_code +
                '/relevant-events',
                formData
            )
    },
    setCallback(process_code, callback) {
        const formData = objectToFormData(callback)
        let url = process.env.VUE_APP_ENDPOINT +
            'app-center/processes/' +
            process_code +
            '/callbacks'
        if (callback[model_callback.constants.CODE] != null) {
            url += '/' + callback[model_callback.constants.CODE]
        }
        return Axios.post(url, formData)

    },
    deleteCallback(process_code, callback_code) {
        return Axios.delete(process.env.VUE_APP_ENDPOINT +
            'app-center/processes/' +
            process_code +
            '/callbacks/' + callback_code)

    },
    launchProcessInstance(process_code, fields) {
        const format_fields = objectToFormData(fields)
        return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/launch', format_fields)
    },
    activateLanguage(process_code, language_code) {
        const format_fields = objectToFormData({
            language: language_code
        })
        return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/languages', format_fields)
    },
    deleteLanguage(process_code, language_code) {
        return Axios.delete(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/languages/' + language_code)
    },

    setLanguageResources(process_code, language_code, resources) {
        if (resources.files.length == 0) {
            resources.files = null
        }
        if (resources.variables.length == 0) {
            resources.variables = null
        }
        let format_fields = objectToFormData(resources, {indices: true})
        return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/processes/' + process_code + '/resources/' + language_code, format_fields)
    },
    /* Checkers */
    async getRequiredProcessInfo(prop_id) {
        let data = null
        switch (prop_id) {
            case model_process.DEFAULT_LANGUAGE: {
                await service_protocol.getLanguages().then((languages) => {
                    data = {
                        items: languages,
                        item_text: 'language_name',
                        item_value: 'language_code',
                    }
                })
                break
            }
            case model_process.CERTIFICATE_LANGUAGE: {
                await service_protocol.getLanguages().then((languages) => {
                    languages.unshift({
                        language_code: 'transaction_default',
                        language_name: 'Idioma de transacción',
                    })
                    data = {
                        items: languages,
                        item_text: 'language_name',
                        item_value: 'language_code',
                    }
                })
                break
            }
            case model_process.CERT_ENTITY_NAME: {
                await service_protocol.getCertificationEntities().then((cert_entities) => {
                    data = {
                        items: cert_entities,
                        item_text: 'name',
                        item_value: 'code',
                    }
                })
                break
            }
            case model_process.TEMPLATE_CODE: {
                await service_protocol.getTemplates().then((templates) => {
                    data = {
                        items: templates,
                        item_text: 'code',
                        item_value: 'code',
                    }
                })
                break
            }
            case model_process.DEFAULT_CECO: {
                await service_protocol.findBillingUnits([model_billingUnit.CODE, model_billingUnit.NAME], {}).then((extra) => {
                    extra.results.unshift({
                        id: null,
                        code: '-',
                    })
                    data = {
                        items: extra.results,
                        item_text: 'code',
                        item_value: 'id',
                    }

                })
                break
            }
            case model_process.CECO_REQUIRED:
            case model_process.COMMUNICALIA_SYNC:
            case model_process.ALASTRIA: {
                data = {
                    items: [
                        {code: null, name: '-'},
                        {code: 0, name: 'No'},
                        {code: 1, name: 'Sí'},
                    ],
                    item_text: 'name',
                    item_value: 'code',
                }
                break
            }
            case model_process.API_INTROMISSION_PERMISSIONS.ADD_EXTERNAL_CERTIFICATE.id:
            case model_process.API_INTROMISSION_PERMISSIONS.ADD_EXTERNAL_DOCUMENT.id:
            case model_process.API_INTROMISSION_PERMISSIONS.TRANSACTION_CANCELLATION.id:
            case model_process.API_INTROMISSION_PERMISSIONS.RETRIES_MANIPULATING.id:
            case model_process.API_INTROMISSION_PERMISSIONS.DOWNLOAD_CERTIFICATE.id:
            case model_process.API_INTROMISSION_PERMISSIONS.CHECK_VARIABLES.id:
            case model_process.API_INTROMISSION_PERMISSIONS.UPDATE_FILL_LATER_VARIABLES.id:
            case model_process.API_INTROMISSION_PERMISSIONS.ADD_FRAMEWORK_SIGNALS.id: {
                data = {
                    items: [
                        {code: false, name: 'Desactivado'},
                        {code: true, name: 'Activado'},
                    ],
                    item_text: 'name',
                    item_value: 'code',
                }
                break
            }
            case model_process.STOPPED: {
                data = {
                    items: [
                        {code: 0, name: 'Activo'},
                        {code: 1, name: 'Parado'},
                    ],
                    item_text: 'name',
                    item_value: 'code',
                }
                break
            }
            case model_process.FORMAT_PDF_CERTIFICATES:
            case model_process.FORMAT_PDF_EVENTS: {
                data = {
                    items: [
                        {code: 'PDFA', name: 'PDFA'},
                        {code: 'PDF17', name: 'PDF17'},
                    ],
                    item_text: 'name',
                    item_value: 'code',
                }
                break
            }
            case model_process.EMAIL_ENTITY: {
                await service_protocol.getEmailEntities().then((entities) => {
                    entities.unshift({
                        id: null,
                        name: 'Ninguno',
                    })
                    data = {
                        items: entities,
                        item_text: 'name',
                        item_value: 'id',
                    }
                })
                break
            }
            case model_process.COMMSTRACKER_EMAIL:
            case model_process.COMMSTRACKER_SMS: {
                await service_protocol.getCommstackerDomains().then((domains) => {
                    domains.unshift({
                        domain: null,
                    })
                    data = {
                        items: domains,
                        item_text: 'domain',
                        item_value: 'domain',
                    }
                })
                break
            }
            case model_process.SMS_PROVIDER: {
                data = {
                    items: [
                        {code: 'altiria', name: 'Altiria'},
                        {code: 'link_mobility', name: 'Link Mobility'},
                    ],
                    item_text: 'name',
                    item_value: 'code',
                }
                break
            }
            default: {
                data = {}
            }
        }
        return data
    },
    checkProcessProps(process) {
        let arr_errors = {}

        Object.keys(process).forEach(prop_key => {
            let result = {
                error: false,
                message: '',
            }

            switch (prop_key) {
                case model_process.CERT_EXPIRATION_DAYS:
                case model_process.NAME:
                case model_process.CERT_ENTITY_NAME:
                case model_process.ALASTRIA: {
                    result.error = helpers.nullOrEmpty(process[prop_key])
                    result.message = 'El valor no puede estar vacío'
                    break
                }
                case model_process.DEFAULT_CECO: {
                    result.error = process[model_process.REQUIRES_COST_CENTER] == false && helpers.nullOrEmpty(process[prop_key])
                    result.message = 'No es requerido en API'
                    break
                }
            }
            arr_errors[prop_key] = result
        })
        return arr_errors

    },

    checkProcessProperty(category, process, extra = null) {
        let results = {
            disabled: {
                code: 0,
                color: 'contrast_medium'
            },
            ok: {
                code: 0,
                color: 'success'
            },
            error: {
                code: 1,
                color: 'error'
            },
            warning: {
                code: 2,
                color: 'warning'
            }
        }
        let result = results.disabled
        switch (category) {
            case 'email_provider': {

                if (helpers.nullOrEmpty(process[model_process.EMAIL_ENTITY])) {
                    result = results.disabled
                } else if (helpers.nullOrEmpty(process[model_process.EMAIL_LOGIN]) || helpers.nullOrEmpty(process[model_process.EMAIL_PASSWORD]) || helpers.nullOrEmpty(process[model_process.EMAIL_STREAM_TYPE])) {
                    result = results.error
                }

                if (helpers.nullOrEmpty(process[model_process.COMMSTRACKER_EMAIL]) ||
                    (!helpers.nullOrEmpty(process[model_process.COMMSTRACKER_EMAIL]) && helpers.nullOrEmpty(process[model_process.EMAIL_ENTITY]))) {
                    result = results.warning
                }

                if (!helpers.nullOrEmpty(process[model_process.EMAIL_ENTITY]) && !helpers.nullOrEmpty(process[model_process.EMAIL_LOGIN]) &&
                    !helpers.nullOrEmpty(process[model_process.EMAIL_PASSWORD]) && !helpers.nullOrEmpty(process[model_process.COMMSTRACKER_EMAIL])) {
                    result = results.ok
                }

                break
            }
            case 'sms_provider': {
                if (helpers.nullOrEmpty(process[model_process.SMS_PROVIDER])) {
                    result = results.disabled
                } else if (helpers.nullOrEmpty(process[model_process.COMMSTRACKER_SMS]) ||
                    !helpers.nullOrEmpty(process[model_process.COMMSTRACKER_SMS]) && helpers.nullOrEmpty(process[model_process.SMS_PROVIDER])) {
                    result = results.warning
                }
                break
            }
            case 'b2b': {
                if (process[model_process.IS_B2B] == 1) {
                    result = results.ok
                }
                if (process[model_process.IS_B2B] == 1 && helpers.nullOrEmpty(process[model_process.B2B_BEHAVIOR_LAYER])) {
                    result = results.warning
                }
                if (process[model_process.IS_B2B] == 1 && (
                    helpers.nullOrEmpty(process[model_process.B2B_TAGS]) || helpers.nullOrEmpty(process[model_process.B2B_IMAGE]
                        || helpers.nullOrEmpty(process[model_process.B2B_TRANSLATION_ID]) || helpers.nullOrEmpty(process[model_process.B2B_TRANSLATION_CATEGORY]))
                )) {
                    result = results.error
                }
                break
            }
            case 'api': {
                if (process[model_process.API_INTROMISSION] != 0 || process[model_process.API_ENQUIRY] != 0) {
                    result = results.ok
                }
                break
            }
            case 'security': {
                if (process[model_process.API_INTROMISSION] != 0 || process[model_process.API_ENQUIRY] != 0) {
                    result = results.ok
                }
                break
            }
            case 'events': {
                if (extra != null && extra.length != 0) {
                    result = results.ok
                }
                break
            }
            default: {
                result = results.ok
            }
        }
        return result
    },

    /* Format */
    formatProcesses(processes) {
        let result = []
        processes.forEach((p) => {
            result.push({
                [model_process.ENTITY_NAME]: {
                    [model_process.NAME]: p[model_process.NAME],
                    [model_process.CODE]: p[model_process.CODE],
                },
                [model_process.NAME]: p[model_process.NAME],
                [model_process.CODE]: p[model_process.CODE],
                [model_process.ESPECIAL_TEMPLATE_CODE]: p[model_process.ESPECIAL_TEMPLATE_CODE],
                [model_masteraccount.ESPECIAL_ENTITY_NAME]: p[model_masteraccount.ESPECIAL_ENTITY_NAME],
                [model_subaccount.ESPECIAL_ENTITY_NAME]: p[model_subaccount.ESPECIAL_ENTITY_NAME],
                [model_project.ENTITY_NAME]: p[model_project.ENTITY_NAME],
            })
        })
        return result
    },
}
