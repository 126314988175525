<template>
  <v-dialog
    v-model="sessionExpired"
    :persistent="expiredCompletely"
    style="border: none !important;"
    @click:outside="resetExpiration"
  >
    <v-row justify="center">
      <v-col
        v-if="expiredCompletely"
        class="pa-0"
        md="2"
      >
        <LoginForm
          mode="expiration"
          @loginSuccessful="resetCompleteExpiration"
        />
      </v-col>

      <v-col
        v-else
        class="pa-0"
        md="2"
      >
        <v-card
          class="my-auto cb-login-form-container"
          elevation="0"
          height="280"
          tile
          width="400"
        >
          <v-card-text>
            <v-row
              class="ma-0 justify-center"
              dense
            >
              <img
                alt="Logo"
                class="my-auto "
                src="../../assets/cts-logo.png"
                width="350px"
              />
            </v-row>
            <v-row
              class="ma-0 justify-center"
              dense
            >
              <v-col cols="12">
                <h1 class="text-center">¿Sigues ahí?</h1>
              </v-col>
              <v-col cols="12">
                <p class="text-center">La sesión está a punto de caducar si no lo impides clickando/tocando en cualquier
                  parte de la página.</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-dialog>
</template>

<script>
import LoginForm from '@/components/global_app/LoginForm'
import vuex_authentication from '@/store/modules/vuex_authentication'
import { mapActions } from 'vuex'
import { createExpirationTimeout } from '@/plugins/helpers'

export default {
  name: 'SessionExpirationDialog',
  components: { LoginForm },

  data() {
    return {
      expiredCompletely: false,
      timeoutForCompleteExpiration: null
    }
  },

  computed: {
    username() {
      return this.$store.state[vuex_authentication.constants.NAMESPACE][vuex_authentication.constants.S_USERNAME]
    },
    sessionExpirationTime() {
      return this.$store.state[vuex_authentication.constants.NAMESPACE][vuex_authentication.constants.S_EXPIRATION_TIMEOUT]
    },

    sessionExpired: {
      get() {
        return this.$store.state[vuex_authentication.constants.NAMESPACE][vuex_authentication.constants.S_SESSION_EXPIRED]
      },

      set(value) {
        this.$store.dispatch(`${vuex_authentication.constants.NAMESPACE}/${vuex_authentication.constants.A_SET_SESSION_EXPIRED}`, value)
      }
    },

    sessionExpiredCompletely: {
      get() {
        return this.$store.state[vuex_authentication.constants.NAMESPACE][vuex_authentication.constants.S_SESSION_EXPIRED_COMPLETELY]
      },

      set(value) {
        this.$store.dispatch(`${vuex_authentication.constants.NAMESPACE}/${vuex_authentication.constants.A_SET_SESSION_EXPIRED_COMPLETELY}`, value)
      }
    }
  },

  created() {/*
    setInterval(() => {
      let expiration = this.sessionExpirationTime

      if (typeof(expiration) == "string") {
        expiration = this.$moment(expiration)
      }

      if (this.$moment().isAfter(this.$moment(expiration).add(1, 'minutes'))) {
        this.expiredCompletely = true
      }

      if (this.username && this.$moment(this.$moment().format('DD-MM-YYYY HH:mm:ss'), 'DD-MM-YYYY HH:mm:ss') >= expiration) {
        this.sessionExpired = true
      }
    }, 5000)*/
  },

  methods: {
    ...mapActions({
      setTimeout: `${vuex_authentication.constants.NAMESPACE}/${vuex_authentication.constants.A_SET_EXPIRATION_TIMEOUT}`
    }),

    resetExpiration() {
      if (!this.expiredCompletely) {
        this.sessionExpired = false
        createExpirationTimeout()
      }

    },

    resetCompleteExpiration() {
      this.expired = false
      this.expiredCompletely = false
    }
  }
}
</script>

<style scoped>
.session-expired-card {
  width: 200px !important;
}
</style>
