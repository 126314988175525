export default Object.freeze({
  ENTITY_NAME: 'subaccount',
  NAME: 'name',
  CODE: 'code',
  PROJECTS: 'projects',
  PROCESSES: 'processes',
  PARENT_ACCOUNT_CODE: 'parent_account_code',
  IP_WHITELISTING_PROTECTED: 'ip_whitelisting_protected',
  IP_WHITELIST: 'ip_whitelist',

  MASTER_ACCOUNT_CODE: 'master_account_code',

  /* Para borrar */
  ESPECIAL_ENTITY_NAME: 'sub_account',
})
