import Axios from "axios"
import {objectToFormData} from 'object-to-formdata'

export const getResources = path => {
    let params = {action: "list", path}
    let payload = objectToFormData(params)

    return Axios.post(`${process.env.VUE_APP_ENDPOINT}app-center/resource-manager`, payload)
}

export const resourceAction = (action, path, files = null) => {
    // actions -> create_folder, delete_folder, delete_file, download_file, upload_files
    let params = {action, path}

    if (files) {
        params.files = files
    }
    let payload = objectToFormData(params)

    return Axios.post(`${process.env.VUE_APP_ENDPOINT}app-center/resource-manager`, payload)
}


export async function uploadJsonFile(name, fileContent, path) {
    const json = JSON.stringify(fileContent);
    const blob = new Blob([json], {type: 'application/json'});
    const file = new File([blob], name, {type: 'application/json'});

    await resourceAction('upload_files', path, [file])
}