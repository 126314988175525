import Vue from 'vue'

const constants = Object.freeze({
  NAMESPACE: 'vuex_support',

  S_ACTIVE_SECTION: 'state_active_section',
  S_ACTIVE_SUBSECTION: 'state_active_subsection',

  M_SET_ACTIVE_SECTION: 'mutation_set_active_section',
  M_SET_ACTIVE_SUBSECTION: 'mutation_set_active_subsection',

  A_SET_ACTIVE_SECTION: 'action_set_active_section',
  A_SET_ACTIVE_SUBSECTION: 'action_set_active_subsection',
})

const state = {
  [constants.S_ACTIVE_SECTION]: null,
  [constants.S_ACTIVE_SUBSECTION]: null,
}

const mutations = {
  [constants.M_SET_ACTIVE_SECTION] (state, section) {
    Vue.set(
      this.state[constants.NAMESPACE],
      constants.S_ACTIVE_SECTION,
      section
    )
  },
  [constants.M_SET_ACTIVE_SUBSECTION] (state, subsection) {
    Vue.set(
      this.state[constants.NAMESPACE],
      constants.S_ACTIVE_SUBSECTION,
      subsection
    )
  },
}

const actions = {
  [constants.A_SET_ACTIVE_SECTION] (state, groups) {
    state.commit(constants.M_SET_ACTIVE_SECTION, groups)
  },
  [constants.A_SET_ACTIVE_SUBSECTION] (state, payload) {
    state.commit(constants.M_SET_ACTIVE_SUBSECTION, payload)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  constants,
}
