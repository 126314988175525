const constants = {
    ENTITY_NAME: 'callback',

    TIMESTAMP: 'timestamp',

    STATUS_CODE: 'status_code',
    EVENT: 'status',
    FILES: 'files',
    INSTANCE_CODE: 'instance_code',
    MASTER_ACCOUNT_CODE: 'master_account_code',
    PARAMETERS: 'event',
    PROCESS_CODE: 'process_code',
    PROJECT_CODE: 'project_code',
    RETRIES: 'retries',
    SUBACCOUNT_CODE: 'subaccount_code',
    URL: 'url',
    CALLBACK_FORMAT: 'callback_format',
    AUTH: 'authentication',
    ERROR: 'error',

    CODE: 'callback_code',
    NAME: 'callback_name',
    STRING: 'callback_string',
    EVENTS: 'events',

    CALLBACK_TEMPLATES: [
        {
            name: 'Authentication basic',
            template: {
                'url': '%%PROCESS.URL_CALLBACK%%',
                'authentication': {
                    'type': 'basic',
                    'extra_info': {
                        'user': '',
                        'password': ''
                    }
                },
                'callback_format': 'json',
                'files': [],
                'parameters': {
                    'event': '%%__event_name%%',
                    'event_extra': '%%__event_evidences%%',
                    'timestamp': '%%__event_timestamp%%',
                    'references': {
                        'master_account_code': '%%MASTER_ACCOUNT_CODE%%',
                        'subaccount_code': '%%SUBACCOUNT_CODE%%',
                        'project_code': '%%PROJECT_CODE%%',
                        'process_code': '%%PROCESS_CODE%%',
                        'transaction_id': '%%INSTANCE_CODE%%'
                    }
                }
            }
        },
        {
            name: 'Without authentication',
            template: {
                'url': '%%PROCESS.URL_CALLBACK%%',
                'authentication': {
                    'type': 'NONE'
                },
                'callback_format': 'json',
                'files': [],
                'parameters': {
                    'event': '%%__event_name%%',
                    'event_extra': '%%__event_evidences%%',
                    'timestamp': '%%__event_timestamp%%',
                    'references': {
                        'master_account_code': '%%MASTER_ACCOUNT_CODE%%',
                        'subaccount_code': '%%SUBACCOUNT_CODE%%',
                        'project_code': '%%PROJECT_CODE%%',
                        'process_code': '%%PROCESS_CODE%%',
                        'transaction_id': '%%INSTANCE_CODE%%'
                    }
                }
            }
        },
        {
            name: 'Oauth 2',
            template: {
                'authentication': {
                    'type': 'oauth2',
                    'extra_info': {
                        'grant_type': 'password',
                        'client_id': '',
                        'client_secret': '',
                        'username': '',
                        'password': '',
                        'get_token_url': ''
                    }
                },
                'callback_format': 'json',
                'url': '',
                'files': [],
                'parameters': {
                    'status': '%%__event_name%%',
                    'status_adds': '',
                    'timestamp': '%%__event_timestamp%%',
                    'references': {
                        'transaction_id': '%%INSTANCE_CODE%%',
                        'master_account_code': '%%MASTER_ACCOUNT_CODE%%',
                        'subaccount_code': '%%SUBACCOUNT_CODE%%',
                        'project_code': '%%PROJECT_CODE%%',
                        'process_code': '%%PROCESS_CODE%%',
                        'instance_code': '%%INSTANCE_CODE%%'
                    },
                    'event': '%%__event_name%%',
                    'event_extra': []
                }
            }
        }
    ]
}


function getIcon(col) {
    const icons = {
        [constants.TIMESTAMP]: 'date_range',
        [constants.PARAMETERS]: 'api',
        [constants.STATUS_CODE]: 'lightbulb',
        [constants.RETRIES]: 'refresh',
        [constants.INSTANCE_CODE]: 'code',
        [constants.URL]: 'web',
        [constants.FILES]: 'description'
    }
    return icons[col]
}

export default Object.freeze({
    constants,
    getIcon
})
