<template>
  <tr
    ref="row"
    class="cb-data-table-row cb-row-with-copy"
  >
    <td
      v-for="(field, index) in fields.filter(f => !f.hide)"
      :key="index"
      :class="{[item['class']]:index == 0,'text-truncate': !notTruncateFields.includes(field.type)}"
      :style="getFieldStyle(index,field)"
    >

      <v-tooltip
        v-if="index == 0 && item['icon'] != null"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon
            :color="item['icon'].color"
            class="mr-1"
            x-small
            v-on="on"
          >
            {{item['icon'].name}}
          </v-icon>
        </template>
        <span v-html="item['icon'].text"></span>
      </v-tooltip>
      <span
        :title="item[field.value]"
      >
          <span v-if="field.value == 'actions'">
            <MenuModelA :actions="rowActions"
                        :icon="$icons.I_ACTIONS"
                        @actionLaunched="actionLaunched"
            />
          </span>
          <span v-else-if="item[field.value] != null">
            <a v-if="field.type == 'link'"
               @click="actionLaunched($events.DATA_TABLE_OPEN)">
            {{item[field.value]}}
            </a>
            <span v-else-if="field.type == 'image'">
              <img :src="item[field.value]"
                   :title="item[field.value]"
                   alt=""
                   class="cb-data-table-image"
                   height="20px"
                   width="20px"
              />
            </span>
             <span v-else-if="field.type == 'timestamp'">
                <span v-if="item[field.value] == null"
                      class="cb-text-descriptor">
                Sin registro
                </span>
                <span v-else>
                    <span class="small">
                    {{parseInt(item[field.value]) | moment('DD/MM/YY')}}
                    </span>
                <span class="font-weight-medium"> {{parseInt(item[field.value]) | moment('HH:mm:ss')}}</span>
                <span class="cb-text-descriptor ml-2 font-italic"> {{parseInt(item[field.value]) | moment('from')}}
                </span>
              </span>
            </span>
             <span v-else-if="field.type == 'timestamp_date'">
                <span v-if="item[field.value] == null"
                      class="cb-text-descriptor">
                Sin registro
                </span>
                <span v-else>
                    <span class="small">
                    {{parseInt(item[field.value]) | moment('DD/MM/YY')}}
                    </span>
                <span class="cb-text-descriptor ml-2 font-italic"> {{parseInt(item[field.value]) | moment('from')}}
                </span>
              </span>
            </span>
             <span v-else-if="field.type == 'seconds'">
              <span v-if="item[field.value] == null" class="cb-text-descriptor">
                Sin registro
              </span>
              <span v-else>
              <span
                class="small">{{getSecondsDate(item[field.value]) | moment('DD/MM/YY')}}
              </span>
                <span class="font-weight-medium">{{getSecondsDate(item[field.value]) | moment('HH:mm:ss')}}</span>
                <span class="cb-text-descriptor ml-2 font-italic">{{getSecondsDate(item[field.value]) | moment('from')}}
                </span>
              </span>
            </span>
            <span v-else-if="field.type=='email_name' && item[field.value].split('@').length == 2">
              <v-icon :color="helpers.stringToColor(item[field.value])"
                      class="mr-1"
                      small
                      style="margin-top: -2px;">
                person
              </v-icon>
              <span>
                {{item[field.value].split('@')[0]}}
              </span>
            </span>
            <span v-else-if="field.type=='app_center_user' && item[field.value].split('@').length == 2">
             <v-img
               :src="'https://r.mailcomms.io/app_center/img/avatars/' + item[field.value].split('@')[0].toLowerCase()+'.png'"
               class="d-inline-flex mr-1"
               max-height="20px"
               max-width="20px"
               style="border-radius: 50%;vertical-align: bottom;"
             ></v-img>
              <span class="text-uppercase">
                {{item[field.value].split('@')[0]}}
              </span>
            </span>
            <span v-else-if="field.type=='icon_color'" :title="item[field.value].name">
              <v-icon :color="item[field.value].color"
                      class="mr-1"
                      small
                      style="margin-top: -2px;">
                {{item[field.value].icon}}
              </v-icon>
            </span>
            <span v-else-if="field.type=='boolean'">
              <v-icon :color="item[field.value] == 1 ? 'success':'contrast_medium'"
                      class="mr-1"
                      small
                      style="margin-top: -2px;">
                {{item[field.value] == 1 ? 'check_circle' : 'highlight_off'}}
              </v-icon>
              <span :class="item[field.value] == 1 ? 'success--text':'contrast_medium--text'"
                    class="font-weight-medium">
                  <span
                    v-if="field.extra && field.extra.boolValues">{{item[field.value] == 1 ? field.extra.boolValues[1] : field.extra.boolValues[0]}}</span>
                  <span v-else>{{item[field.value] == 1 ? 'Activado' : 'Desactivado'}}</span>
              </span>
            </span>
            <span v-else-if="field.type=='boolean_warning'">
              <v-icon :color="item[field.value] == 1 ? 'warning':'contrast_medium'"
                      class="mr-1"
                      small
                      style="margin-top: -3px;">
                {{item[field.value] == 1 ? 'warning' : 'check'}}
              </v-icon>
              <span class="font-weight-medium my-auto">
                  <span v-if="field.extra && field.extra.boolValues"
                        :class="item[field.value] == 1 ? 'warning--text':'contrast_medium--text'"
                        class="">{{item[field.value] == 1 ? field.extra.boolValues[1] : field.extra.boolValues[0]}}</span>
                  <span v-else :class="item[field.value] == 1 ? 'warning--text':'contrast_medium--text'"
                        class="">{{item[field.value] == 1 ? 'Sí' : 'No'}}</span>
              </span>
            </span>
            <span v-else-if="field.type=='process_management_status' && pkcStatus" :title="pkcStatus?.name"
                  class="overflow-hidden">
              <v-icon :color="pkcStatus?.color"
                      small
                      style="margin-top: -2px;font-size: 24px !important">
                {{pkcStatus.icon}}
              </v-icon>
            </span>
            <span v-else-if="field.type =='editable_inline'">
            <v-text-field v-if="fieldEditing == field.value" id="editTextField" v-model="fieldEditingValue"
                          append-icon="save"
                          dense
                          hide-details
                          outlined
                          @focusout="cancelEdit()"
                          @keydown.enter="saveField()"
                          @click:append="saveField()"
            >
            </v-text-field>
              <span v-else>
              {{item[field.value]}}
              </span>
            </span>
            <span v-else>
            {{item[field.value]}}
            </span>
          </span>
          <span v-else
                class="cb-text-descriptor"
          >Vacío</span>
          <v-icon v-if="!field.hide_copy && !noCopyFields.includes(field.type) && field.value != 'actions'"
                  class="cb-copy-cell-val"
                  title="copiar celda"
                  @click="helpers.copyCellVal($refs.row, item[field.value])"
          >
            copy_all
          </v-icon>
        <v-icon v-if="field.type === 'editable_inline' && fieldEditing == null"
                class="cb-copy-cell-val"
                title="editar celda"
                @click="editField(field)"
        >
          create
        </v-icon>
        </span>
    </td>
  </tr>
</template>

<script>
import helpers from '@/plugins/helpers'
import helpers_resolution from '@/plugins/helpers_resolution'
import MenuModelA from '@/components/repository/buttons/MenuModelA.vue'
import model_pkcProcess, {statuses as pkc_statuses} from "@/constants/models/pkc/model_pkcProcess";


export default {
  name: 'StandardTableRow',
  data() {
    return {
      helpers,
      helpers_resolution,
      pkc_statuses,
      noCopyFields: ['editable_inline', 'actions', 'icon_color', 'boolean', 'boolean_warning', 'timestamp', 'timestamp_date', 'seconds', 'email_name', 'process_management_status', 'app_center_user', 'process_management_status'],
      notTruncateFields: ['process_management_status'],
      fieldEditing: null,
      fieldEditingValue: null,
      saving: false
    }
  },
  props: {item: Object, visualOptions: Object, fields: Array},
  computed: {
    rowActions() {
      let actions = []
      let commonActions = this.fields.find(f => f.value == 'actions').actions()
      if (commonActions != null) {
        actions.push(...commonActions)
      }
      if (this.item.actions != null) {
        actions.push(...this.item.actions)
      }
      return actions
    },
    pkcStatus() {
      if (!this.item[model_pkcProcess.STATUS]) {
        return null
      }
      return pkc_statuses.find(s => s.id == this.item[model_pkcProcess.STATUS])
    }
  },
  components: {MenuModelA},
  methods: {
    getSecondsDate(val) {
      return this.$moment().add(val, 's').valueOf()
    },
    getFieldStyle(index, field) {
      let style = ''
      if (field.value == 'actions') {
        style += ';padding: 0 !important;text-align:center'
      } else {
        style += helpers_resolution.getColWidth(field.width)
      }
      if (index == 0 && this.item['style']) {
        style += this.item['style']
      }
      if (field.type == 'process_management_status' && this.pkcStatus) {
        style += `;background-color: ${this.pkcStatus.color}16`
      }
      if (field.type == 'editable_inline' && this.fieldEditing == field.value) {
        style += ';padding: 0 !important;text-align:center'
      }
      return style
    },
    editField(field) {
      this.fieldEditingValue = this.item[field.value]
      this.fieldEditing = field.value
      //search elementi with id editTextField and focus it
      this.$nextTick(() => {
        document.getElementById('editTextField').focus()
      })
    },
    saveField() {
      this.$emit('saveField', {item: this.item, field: this.fieldEditing, value: this.fieldEditingValue})

      this.fieldEditingValue = null
      this.fieldEditing = null
    },
    cancelEdit() {
      this.fieldEditingValue = null
      this.fieldEditing = null
    },
    actionLaunched(action) {
      this.$emit('actionLaunched', {
        item: this.item,
        action
      })
    }
  },
  filters: {}
}
</script>
