<template>
  <v-btn
    :class="size"
    :color="color"
    :disabled="disabled"
    :loading="loading"
    :title="title"
    class="apc-button-a text-truncate"
    depressed
    @click="$emit('click')"
  >
    <v-icon
      small
    >
      {{ icon }}
    </v-icon>
    <div class="button-text text-truncate">
    <span>
      {{ text }}
    </span>

    </div>
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonWithIconModelA',
  props: {
    size: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'Botón A'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'person'
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

.apc-button-a {
  height: 28px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}

.apc-button-a i {
  position: absolute;
  left: 0;
}

.apc-button-a .button-text {
  width: 100%;
  padding-left: 16px;
  position: absolute;
  right: 0;
}

.apc-button-a span {
  max-width: 84px;
  overflow: hidden !important;

}

@media (min-width: 960px) {
  .apc-button-a {
    min-width: 180px !important;
    max-width: 180px !important;
  }

  .apc-button-a.percent {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .apc-button-a.small {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .apc-button-a.medium {
    min-width: 200px !important;
    max-width: 200px !important;
  }

  .apc-button-a.large {
    min-width: 240px !important;
    max-width: 240px !important;
  }

  .apc-button-a.middle {
    min-width: 50% !important;
    max-width: 50% !important;
  }

  .apc-button-a.full {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 1264px) {
}

@media (min-width: 1367px) {

  .apc-button-a.percent {
    min-width: 48% !important;
    max-width: 48% !important;
  }

}

@media (min-width: 1904px) {
}
</style>
