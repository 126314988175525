import Axios from 'axios'
import service_explorers from '@/services/global/service_explorers'
import { objectToFormData } from 'object-to-formdata'
import helpers from '@/plugins/helpers'

export default {

  openMasterAccount(code, name, section) {
    helpers.open('accounts', {
      id: code,
      name: name,
      section: section
    })
  },
  updateMasterAccount(masterAccountCode, properties) {
    let params = {}
    Object.keys(properties).forEach((key_prop) => {
      if (typeof properties[key_prop] == 'undefined') {
        properties[key_prop] = null
      }
      params[key_prop] = properties[key_prop]
    })
    let form_data_object = objectToFormData(params, { indices: true })
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + 'app-center/masteraccounts/' + masterAccountCode,
      form_data_object
    )
  },
  getB2BAccounts() {
    return Axios.get(process.env.VUE_APP_ENDPOINT + 'b2b/accounts')
  },
  findMasterAccounts(fields, filters, options) {
    return service_explorers.find('app-center/masteraccounts', fields, filters, options)
  },
  getMasterAccountInfo(id) {
    return Axios.get(
      process.env.VUE_APP_ENDPOINT + 'app-center/masteraccounts/' + id
    ).then((response) => {
      return response.data.extra
    })
  },
  createMasterAccount(info) {
    const formData = objectToFormData(info)
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + 'app-center/masteraccounts', formData
    ).then((response) => {
      return response.data
    })
  }
}
