import {objectToFormData} from 'object-to-formdata'
import store from '@/store'
import vuex_global from '@/store/modules/sections/vuex_global'
import Axios from 'axios'
import vuex_authentication from '@/store/modules/vuex_authentication'
import user_default_config from '@/assets/resources/config_files/user_default_config'
import helpers from '@/plugins/helpers'
import jwt_decode from 'vue-jwt-decode'
import Vue from 'vue'
import service_explorers from '@/services/global/service_explorers'
import model_group from '@/constants/models/app_center/model_group'

export default {

  checkPassword(password) {
    const loginObject = objectToFormData({password: password})
    let user_id = store.state[vuex_authentication.constants.NAMESPACE][vuex_authentication.constants.S_ID]
    return Axios
      .post(process.env.VUE_APP_ENDPOINT + 'app-center/users/' + user_id + '/check-password', loginObject)
      .then((response) => {
        switch (response.data.error) {
          case 0: {
            return true
          }
          default: {
            helpers.setAlertMessage(1, response.data.message)
            return false
          }
        }
      })
  },
  openUser(id, name) {
    helpers.open('admin_users', {
      id: id,
      name: name
    })
  },
  login(loginData) {
    const loginObject = objectToFormData(loginData)
    return Axios
      .post(process.env.VUE_APP_ENDPOINT + 'app-center/login', loginObject)
      .then((response) => {
        return response.data
      })
  },
  logout() {
    let logoutData = objectToFormData({
      username: store.state[vuex_authentication.constants.NAMESPACE][vuex_authentication.constants.S_USERNAME]
    })
    return Axios
      .post(process.env.VUE_APP_ENDPOINT + 'app-center/logout', logoutData)
      .then((response) => {
        Vue.$cookies.remove('act')
        return response.data
      })
  },
  setUserConfig(config) {
    let id = store.state[vuex_authentication.constants.NAMESPACE][vuex_authentication.constants.S_ID]
    store.dispatch(
      vuex_global.constants.NAMESPACE +
      '/' +
      vuex_global.constants.A_SET_USER_CONFIG,
      config
    )
    return this.saveUserProperties(id, {config: JSON.stringify(config)}).then(() => {
      return true
    })
  },

  findUsers(fields, filters, options) {
    return service_explorers.find('app-center/users', fields, filters, options)
  },
  getUserDetails(userId) {
    return Axios.get(
      process.env.VUE_APP_ENDPOINT + 'app-center/users/' + userId
    ).then((response) => {
      return response.data.extra
    })
  },
  saveUserProperties(user_id, properties) {
    let params = {}
    Object.keys(properties).forEach((key_prop) => {
      if (typeof properties[key_prop] == 'undefined') {
        properties[key_prop] = null
      }
      params[key_prop] = properties[key_prop]
    })
    let form_data_object = objectToFormData(params, {indices: true})
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + 'app-center/users/' + user_id,
      form_data_object
    )
  },
  createUser(userData) {
    let form_data_object = objectToFormData(userData, {indices: true})
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + 'app-center/users',
      form_data_object
    )
  },
  createGroup(groupData) {
    let form_data_object = objectToFormData(groupData, {indices: true})
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + 'app-center/groups',
      form_data_object
    )
  },

  findGroups(fields, filters, options) {
    filters = !helpers.nullOrEmpty(filters) && Object.keys(fields) != 0 ? filters : null
    fields = !helpers.nullOrEmpty(fields) ? fields : fields = 'name'
    options = !helpers.nullOrEmpty(options) ? options : {
      page: 1,
      rpp: 1000,
      order: {
        field: 'name',
        direction: 'ASC'
      }
    }
    return Axios.get(
      process.env.VUE_APP_ENDPOINT + 'app-center/groups',
      {
        params: {
          fields: fields,
          filters: filters,
          options: options
        }
      }
    ).then((response) => {
      return response.data.extra
    })
  },
  getGroupDetails(groupId) {
    return Axios.get(
      process.env.VUE_APP_ENDPOINT + 'app-center/groups/' + groupId
    ).then((response) => {
      return response.data.extra
    })
  },
  saveGroupProperties(group_id, properties) {
    let params = {}
    Object.keys(properties).forEach((key_prop) => {
      if (typeof properties[key_prop] == 'undefined') {
        properties[key_prop] = null
      }
      params[key_prop] = properties[key_prop]
    })
    let form_data_object = objectToFormData(params, {indices: true})
    return Axios.post(
      process.env.VUE_APP_ENDPOINT + 'app-center/groups/' + group_id,
      form_data_object
    )
  },

  getAllPermissions() {
    return service_explorers.find('app-center/permissions', [model_group.PERM_ID, model_group.PERM_KEY, model_group.PERM_DESCRIPTION, model_group.PERM_DEPENDS], null, {
      page: 1,
      rpp: 1000,
      order: {
        field: model_group.PERM_ID,
        direction: 'ASC'
      }
    })
  },
  checkAndUpdateDefaultProps(config_saved) {
    if (typeof config_saved == 'string' && config_saved != '') {
      config_saved = JSON.parse(config_saved)
    }

    let final_config = {}
    Object.keys(user_default_config).forEach(od_key => {
      if (config_saved[od_key] == null) {
        Vue.set(final_config, od_key, user_default_config[od_key])
        return
      }
      if (typeof user_default_config[od_key] == 'object') {
        Vue.set(final_config, od_key, user_default_config[od_key])

        Object.keys(config_saved[od_key]).forEach(lp => {
          if (user_default_config[od_key][lp] != null) {
            Vue.set(final_config[od_key], lp, config_saved[od_key][lp])
          }
        })
      } else if (user_default_config[od_key] != null) {
        Vue.set(final_config, od_key, user_default_config[od_key])
      }
    })
    return this.setUserConfig(final_config)

  },
  checkGroupPermission(permissionId) {
    const act = Vue.$cookies.get('act')
    let groupPerms = act != null ? jwt_decode.decode(act).group.permissions : false
    let result = false
    if (groupPerms) {
      result = groupPerms.includes(permissionId) || permissionId === false
    }
    return result
  },
  loginCas() {
    return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/login-cas')
  },
  validateTicket(ticket) {
    return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/login-cas', {
      params: {
        ticket: ticket
      }
    })
  }
}
