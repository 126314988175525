const ID = 'id'
const NAME= 'business_name'
const CODE = 'business_code'

export default Object.freeze({
  ENTITY_NAME: 'certificate_issuer',
  ID,
  NAME,
  CODE
})
