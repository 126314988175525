const constants = Object.freeze({
  NAMESPACE: 'vuex_administration',

  S_GROUPS_SEARCH_FILTERS: 'state_groups_search_filters',
  S_GROUPS_OPENED: 'state_groups_opened',
  S_USERS_SEARCH_FILTERS: 'state_users_search_filters',
  S_USERS_OPENED: 'state_users_opened',

  M_SET_GROUPS_SEARCH_FILTERS: 'mutation_set_groups_search_filters',
  M_SET_GROUPS_OPENED: 'mutation_set_groups_opened',
  M_SET_USERS_SEARCH_FILTERS: 'mutation_set_users_search_filters',
  M_SET_USERS_OPENED: 'mutation_set_users_opened',

  A_SET_GROUPS_SEARCH_FILTERS: 'action_set_groups_search_filters',
  A_SET_GROUPS_OPENED: 'action_set_groups_opened',
  A_SET_USERS_SEARCH_FILTERS: 'action_set_users_search_filters',
  A_SET_USERS_OPENED: 'action_set_users_opened',

})

const state = {
  [constants.S_GROUPS_SEARCH_FILTERS]: {},
  [constants.S_GROUPS_OPENED]: [{
    id: 'explorer',
    name: 'Listado de grupos'
  }],
  [constants.S_USERS_OPENED]: [{
    id: 'explorer',
    name: 'Listado de usuarios'
  }],
  [constants.S_USERS_SEARCH_FILTERS]: {},
}

const mutations = {
  [constants.M_SET_GROUPS_SEARCH_FILTERS](state, payload) {
    state[constants.S_GROUPS_SEARCH_FILTERS] =
      payload
  },
  [constants.M_SET_GROUPS_OPENED](state, payload) {
    state[constants.S_GROUPS_OPENED] =
      payload
  },
  [constants.M_SET_USERS_SEARCH_FILTERS](state, payload) {
    state[constants.S_USERS_SEARCH_FILTERS] =
      payload
  },
  [constants.M_SET_USERS_OPENED](state, payload) {
    state[constants.S_USERS_OPENED] =
      payload
  },
}

const actions = {
  [constants.A_SET_GROUPS_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_GROUPS_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_GROUPS_OPENED](state, filters) {
    state.commit(constants.M_SET_GROUPS_OPENED, filters)
  },
  [constants.A_SET_USERS_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_USERS_SEARCH_FILTERS, filters)
  },
  [constants.A_SET_USERS_OPENED](state, filters) {
    state.commit(constants.M_SET_USERS_OPENED, filters)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  constants,
}
