<template>
  <v-menu
    offset-y
    tile
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        class="app-menu-model-b"
        depressed
        primary
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="icon != null">{{ $icons.I_ACTIONS }}</v-icon>
        <span>
          {{ text }}
          </span>
      </v-btn>
    </template>
    <v-list
      class="app-menu-list-model-b"
    >
      <v-list-item
        v-for="(item, index) in actions"
        :key="index"
        class="py-1 px-4"
        link
        @click="$emit('actionLaunched',item.id)"
      >
        <v-icon
          v-if="item.icon"
          :color="item.color"
          class="mr-4"
        >{{ item.icon }}
        </v-icon>
        <v-list-item-title class="cb-font-size-3">{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import icons from '@/constants/icons'

export default {
  name: 'MenuModelB',
  props: {
    icon: {
      type: String,
      default: icons.I_ACTIONS
    },
    color: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: null
    },
    actions: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";
@import "../../../assets/styles/resources/variables";


.app-menu-model-b {
  height: 28px !important;
  width: 120px !important;
  min-width: 120px !important;

  & i {
    margin-right: 4px !important;
  }

  transition: width 1s ease !important;

}

.app-menu-list-model-b {

  background: $color_app_clear !important;

  & .v-list-item {
    background-color: $color_app_clear !important;

  }

}
</style>
