<template>
    <v-container class="pa-0">
        <v-row>
            <ExplorerHeader
              :section="section"
              :sections="sections"
              @[$events.EXPLORER_CHANGE_SECTION]="changeSection"
            />
        </v-row>
        <v-row class="cb-view-container">
            <v-tabs-items
              v-model="section"
              class="cb-view-tabs cts-w-100"
            >
                <v-tab-item
                  v-if="section == 'users'"
                  id="users"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                    <UsersDeveloperPortal/>
                </v-tab-item>
                <v-tab-item
                  v-if="section == 'groups'"
                  id="groups"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                    <GroupsDeveloperPortal/>
                </v-tab-item>
            </v-tabs-items>
        </v-row>
    </v-container>
</template>

<script>
import ExplorerHeader from '@/components/repository/explorers/ExplorerHeader'
import {
    P_ADMINISTRATION
} from '@/constants/group_permissions'

import UsersDeveloperPortal from "@/components/administration/developerPortal/usersDeveloperPortal.vue";
import GroupsDeveloperPortal from "@/components/administration/developerPortal/groupsDeveloperPortal.vue";

export default {
    name: 'DeveloperPortal',
    components: {
        ExplorerHeader,
        GroupsDeveloperPortal,
        UsersDeveloperPortal,
    },
    data() {
        return {

            section: null,
        }
    },
    computed: {

        sections() {
            let result = []

            result.push(
              {
                  id: 'users',
                  name: 'Usuarios',
                  icon: this.$icons.I_USER,
                  perm: P_ADMINISTRATION

              })

            result.push(
              {
                  id: 'groups',
                  name: 'Grupos',
                  icon: this.$icons.I_GROUP,
                  perm: P_ADMINISTRATION
              })
            return result
        }
    },
    methods: {
        changeSection(new_section) {
            this.section = new_section
        }
    }
}
</script>


