<template>
    <v-container>
        <ExplorerFiltersRow
          :actions="actions"
          :model="modelName"
          :response-filters="responseFilters"
          :search="true"
          @[$events.EXPLORER_REMOVE_FILTER]="removeFilter"
          @[$events.EXPLORER_SEARCH]="searchByText"
          @[$events.EXPLORER_ACTION_CLICKED]="actionClicked"
        />
        <v-row>
            <DataTable
              :custom-row="true"
              :data="dataTableData"
              :data-table-options="dataTableOptions"
              :headers="dataTableHeaders"
              :loading="loading"
              :model="modelName"
              @[$events.DATA_TABLE_REFRESH]="find"
              @[$events.DATA_TABLE_OPEN]="openItem"
            >
                <template v-slot="{ item }">
                    <DevUserRow
                      :fields="dataTableHeaders"
                      :item="item"
                      :visualOptions="visualOptions"
                      @openItem="openItem"
                    />
                </template>
            </DataTable>
        </v-row>
        <ExportDataTable
          v-if="showExportDialog"
          :fields="dataTableHeaders"
          :model="modelName"
          :response_filters="responseFilters"
          :service="service_devPortal.getUsers"
          @close="showExportDialog=false"
        />
        <DevPortalCreateOrUpdateUser
          v-if="itemSelected != null"
          :fields="dataTableHeaders"
          :response_filters="responseFilters"
          :user="itemSelected"
          @close="closeItem()"
          @refresh="find()"
        />
    </v-container>
</template>

<script>
import helpers_resolution from '@/plugins/helpers_resolution'
import service_user from '@/services/service_user'
import ExplorerFiltersRow from '@/components/repository/explorers/ExplorerFiltersRow'
import DataTable from '@/components/repository/tables/DataTable'
import {
    P_ADMINISTRATION,
    P_EXPORT
} from '@/constants/group_permissions'
import ExportDataTable from '@/components/repository/tables/dataTable/ExportDataTable'
import {datatableMixin} from '@/mixins/dataTable'
import icons from '@/constants/icons'
import service_devPortal from "@/services/service_devPortal";
import DevPortalCreateOrUpdateUser
    from "@/components/administration/developerPortal/components/DevPortalCreateOrUpdateUser.vue";
import DevUserRow from "@/components/administration/developerPortal/components/DevUserRow.vue";

const modelName = 'Usuarios integradores'
const dataTableConfig = {
    options: {
        rpp: helpers_resolution.getTableRowsPerPage(),
        sortDesc: [false],
        page: 1,
        sortBy: ['username']
    },
    finder: service_devPortal.getUsers
}

export default {
    name: 'usersDeveloperPortal',
    components: {
        DevUserRow,
        DevPortalCreateOrUpdateUser,
        ExportDataTable,
        DataTable,
        ExplorerFiltersRow,
    },
    mixins: [datatableMixin(dataTableConfig)],
    data() {
        return {
            service_devPortal,
            modelName,
            dataTableHeaders: [
                {
                    text: 'ID',
                    value: 'id',
                    hide: true,
                    align: ' d-none'
                },
                {
                    text: 'Nombre',
                    value: 'username',
                    sortable: true,
                    width: '30%',
                    type: 'link'
                },
                {
                    text: 'Description',
                    value: 'description',
                    sortable: true,
                    width: '40%',
                    type: 'text'
                },
                {
                    text: 'Expiración',
                    value: 'expiration',
                    sortable: true,
                    width: '15%',
                    type: 'text'
                },
                {
                    text: 'Activo',
                    value: 'active',
                    sortable: true,
                    width: '5%',
                    type: 'boolean'
                },
                {
                    text: 'Grupo',
                    value: 'group_id',
                    sortable: true,
                    width: '10%',
                    type: 'text'
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                    custom: true,
                    width: '40px',
                    actions: () => {

                        let entities = []

                        entities.push({
                            id: 'data_table_open',
                            icon: icons.I_VIEW,
                            title: 'Ver detalles',
                            enabled: true,
                        })
                        return entities

                    }
                },
            ],
        }
    },
    computed: {
        actions() {
            let entities = []
            if (service_user.checkGroupPermission(P_EXPORT)) {
                entities.push({
                    id: 'export',
                    icon: this.$icons.I_EXPORT,
                    text: 'Exportar datos',
                    enabled: true,
                })
            }
            if (service_user.checkGroupPermission(P_ADMINISTRATION)) {
                entities.push({
                    id: 'new',
                    icon: this.$icons.I_USER,
                    text: 'Nuevo usuario',
                    enabled: true,
                })
            }
            return entities
        }
    },
    methods: {
        actionClicked(action_id) {
            if (action_id === 'export') {
                this.showExportDialog = true
            }
            if (action_id === 'new') {
                this.openItem('new')
            }
        }
    },
    filters: {}
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";
@import "../../../assets/styles/resources/variables";

</style>
