import {PROTOCOL} from '@/constants/categories'
import ResourceManager from '@/views/protocol/ResourceManager.vue'
import {
  P_PROTOCOL_BILLING,
  P_PROTOCOL_EMAIL_CHECKER,
  P_PROTOCOL_ENTITIES,
  P_PROTOCOL_FILE_EXPLORER,
  P_PROTOCOL_SCHEDULE_TASKS,
  P_PROTOCOL_TICKER
} from '@/constants/group_permissions'
import icons from '@/constants/icons'

const Entities = () => import(/* webpackChunkName: "Protocol"*/ '@/views/protocol/Entities.vue')
const Schedules = () => import(/* webpackChunkName: "Protocol"*/ '@/views/protocol/Schedules.vue')
const Ticker = () => import(/* webpackChunkName: "Protocol"*/ '@/views/protocol/Ticker.vue')
const Billing = () => import(/* webpackChunkName: "Protocol"*/ '@/views/protocol/Billing.vue')
const EmailChecker = () => import(/* webpackChunkName: "Protocol"*/ '@/views/protocol/EmailChecker.vue')


const ROOT = '/protocol/'
const CATEGORY = PROTOCOL

export default [
    {
        path: ROOT + 'entities',
        name: 'entities',
        component: Entities,
        meta: {
            perm: P_PROTOCOL_ENTITIES,
            icon: icons.I_CONFIGURATION,
            title: 'Entidades del protocolo',
            title_short: 'Entidades',
            navigable: true,
            category: CATEGORY,
            requiresAuth: true
        }
    },
    {
        path: ROOT + 'billing',
        name: 'billing',
        component: Billing,
        meta: {
            perm: P_PROTOCOL_BILLING,
            title: 'Gestión de la facturación',
            title_short: 'Facturación',
            navigable: true,
            icon: icons.I_BILLING,
            category: CATEGORY,
            requiresAuth: true
        },
    },
    {
        path: ROOT + 'email-checker',
        name: 'email_checker',
        component: EmailChecker,
        meta: {
            perm: P_PROTOCOL_EMAIL_CHECKER,
            title: 'Comprobador de emails',
            title_short: 'Email checker',
            navigable: true,
            icon: icons.I_EMAIL,
            category: CATEGORY,
            requiresAuth: true
        },
    },
    {
        path: `${ROOT}resourceManager`,
        name: 'resource_manager',
        component: ResourceManager,
        meta: {
            perm: P_PROTOCOL_FILE_EXPLORER,
            icon: icons.I_RESOURCES,
            title: 'Gestor de recursos',
            title_short: 'Gestor de recursos',
            navigable: true,
            category: CATEGORY,
            requiresAuth: true
        }
    },
    {
        path: ROOT + 'schedules',
        name: 'schedules',
        component: Schedules,
        meta: {
            perm: P_PROTOCOL_SCHEDULE_TASKS,
            icon: icons.I_SCHEDULES,
            title: 'Tareas programadas',
            title_short: 'Tareas programadas',
            navigable: true,
            category: CATEGORY,
            requiresAuth: true
        }
    },
    {
        path: ROOT + 'ticker',
        name: 'ticker',
        component: Ticker,
        meta: {
            perm: P_PROTOCOL_TICKER,
            icon: icons.I_TICKER,
            title: 'Ticker',
            title_short: 'Ticker',
            navigable: true,
            category: CATEGORY,
            requiresAuth: true
        }
    }

]
