const constants = Object.freeze({
  NAMESPACE: 'vuex_notarial_deposit',

  S_SEARCH_FILTERS_EXPEDIENTS: 'state_search_filters_expedients',
  M_SET_SEARCH_FILTERS_EXPEDIENTS: 'mutation_set_search_filters_expedients',
  A_SET_SEARCH_FILTERS_EXPEDIENTS: 'action_set_search_filters_expedients',
})

const state = {

  [constants.S_SEARCH_FILTERS_EXPEDIENTS]: {},

}

const mutations = {
  [constants.M_SET_SEARCH_FILTERS_EXPEDIENTS](state, payload) {
    state[constants.S_SEARCH_FILTERS_EXPEDIENTS] =
      payload
  },

}

const actions = {
  [constants.A_SET_SEARCH_FILTERS_EXPEDIENTS](state, users) {
    state.commit(constants.M_SET_SEARCH_FILTERS_EXPEDIENTS, users)
  },

}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  constants,
}
