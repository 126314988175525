import Axios from "axios";
import {objectToFormData} from "object-to-formdata";
import service_explorers from "@/services/global/service_explorers";

export default {
    createOrUpdateUser(id, changesPending) {
        if (id) {
            return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/commservices/users/' + id, objectToFormData(changesPending), {indices: true})
        } else {

            return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/commservices/users', objectToFormData(changesPending), {indices: true})
        }
    },
    deleteUser(id) {
        return Axios.delete(process.env.VUE_APP_ENDPOINT + 'app-center/commservices/users/' + id)
    },
    getUsers(fields, filters, options) {
        return service_explorers.find('app-center/commservices/users', fields, filters, options)
    },
    exclusionListSearch(email) {
        return Axios.get(process.env.VUE_APP_ENDPOINT + 'app-center/commservices/exclusion-list/exclusion/' + email)
    },
    exclusionListAdd(email, reason = null) {

        const data = {
            email: email,
            reason: reason
        }
        return Axios.post(process.env.VUE_APP_ENDPOINT + 'app-center/commservices/exclusion-list/exclusion', objectToFormData(data), {indices: true})
    },
    exclusionListDelete(email) {
        return Axios.delete(process.env.VUE_APP_ENDPOINT + 'app-center/commservices/exclusion-list/exclusion/' + email)
    }
}
