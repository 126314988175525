<template>
  <v-col>
    <v-card class="data-table-standard-card">
      <v-list-item three-line>
        <v-list-item-avatar :style="`border-color:${statusFound?.color}`">

          <v-img
            :src="'https://r.mailcomms.io/app_center/img/'+$env.name+'/' + item[model_pkcProcess.MASTER_ACCOUNT_CODE] +'.png'"
            class="mr-2"
            max-height="40px"
            max-width="40px"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content class="py-1">
          <v-row dense justify="space-between">
            <v-col cols="4">
              <v-list-item-title @click="actionLaunched('data_table_open')">
                {{item[model_pkcProcess.NAME]}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{item[model_pkcProcess.STATE_COMMENTS]}}
              </v-list-item-subtitle>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row class="py-1 px-2" dense justify="space-between">
            <span>Última actualización: {{item[model_pkcProcess.LAST_UPDATE] | moment('DD/MM/YY')}}</span>
          </v-row>

        </v-list-item-content>
        <v-list-item-action>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(action, index) in actions"
                :key="index"
                class="py-2"
                link
                @click="actionLaunched(action.action)"
              >
                <v-list-item-title>{{action.title}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </v-col>
</template>
<script lang="js">
import model_pkcProcess, {statuses} from "@/constants/models/pkc/model_pkcProcess";

export default {
  name: "DataTableCardPKCProcesses",
  props: {
    item: Object
  },
  data() {
    return {
      statuses,
      actions: [
        {title: 'Abrir', action: 'open'},
        {title: 'Editar', action: 'edit'}
      ]

    };
  },
  computed: {
    model_pkcProcess() {
      return model_pkcProcess
    },

    statusFound() {
      return this.statuses.find(s => s.id === this.item[model_pkcProcess.STATUS])
    }
  },
  methods: {
    actionLaunched(action) {
      console.log(action)
      this.$emit('actionLaunched', {
        item: this.item,
        action
      })
    }
  },

}
</script>
<style lang="scss">
@import "../../../../assets/styles/resources/colors";
@import "../../../../assets/styles/resources/variables";

.data-table-standard-card {

  .v-list-item__title {
    color: $color_app_primary;
    cursor: pointer;
  }

  .v-avatar {
    border: solid 2px transparent;
  }
}
</style>