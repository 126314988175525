import Vue from 'vue'
import user_default_config from '@/assets/resources/config_files/user_default_config'

const constants = Object.freeze({
    NAMESPACE: 'vuex_global',

    S_LOADING_DATA: 'state_loading_data',
    S_INSTANCE_PRELOAD_DATA: 'state_instance_preload_data',
    S_ALERT_DIALOG_DATA: 'state_alert_dialog_data',
    S_ACTIVE_PROFILE: 'state_active_profile',
    S_ACTIVE_ROUTE: 'state_active_route',
    S_SESSION_EXPIRATION: 'state_session_expiration',
    S_USER_CONFIG: 'state_user_config',

    M_SET_LOADING_DATA: 'mutation_set_loading_data',
    M_SET_INSTANCE_PRELOAD_DATA: 'mutation_set_instance_preload_data',
    M_SET_ALERT_DIALOG_DATA: 'mutation_set_alert_dialog_data',
    M_SET_ACTIVE_PROFILE: 'mutation_set_active_profile',
    M_SET_ACTIVE_ROUTE: 'mutation_set_active_route',
    M_SET_SESSION_EXPIRATION: 'mutation_set_session_expiration',
    M_SET_USER_CONFIG: 'mutation_set_user_config',

    A_SET_LOADING_DATA: 'action_set_loading_data',
    A_SET_INSTANCE_PRELOAD_DATA: 'action_set_instance_preload_data',
    A_ADD_INSTANCE_TO_PRELOAD_DATA: 'action_add_instance_to_preload_data',
    A_SET_ALERT_DIALOG_DATA: 'alert_set_alert_dialog_data',
    A_SET_ACTIVE_PROFILE: 'action_set_active_profile',
    A_SET_ACTIVE_ROUTE: 'action_set_active_route',
    A_SET_USER_CONFIG: 'action_set_user_config',


})

const state = {
    [constants.S_LOADING_DATA]: false,
    [constants.S_INSTANCE_PRELOAD_DATA]: [],
    [constants.S_ALERT_DIALOG_DATA]: null,
    [constants.S_ACTIVE_PROFILE]: null,
    [constants.S_ACTIVE_ROUTE]: null,
    [constants.S_SESSION_EXPIRATION]: null,
    [constants.S_USER_CONFIG]: user_default_config
}

const mutations = {
    [constants.M_SET_LOADING_DATA](state, val) {
        Vue.set(this.state[constants.NAMESPACE], constants.S_LOADING_DATA, val)
    },
    [constants.M_SET_ACTIVE_PROFILE](state, val) {
        Vue.set(this.state[constants.NAMESPACE], constants.S_ACTIVE_PROFILE, val)
    },
    [constants.M_SET_ACTIVE_ROUTE](state, val) {
        Vue.set(this.state[constants.NAMESPACE], constants.S_ACTIVE_ROUTE, val)
    },
    [constants.M_SET_ALERT_DIALOG_DATA](state, val) {
        Vue.set(
            this.state[constants.NAMESPACE],
            constants.S_ALERT_DIALOG_DATA,
            val
        )
    },
    [constants.M_SET_INSTANCE_PRELOAD_DATA](state, val) {
        Vue.set(
            this.state[constants.NAMESPACE],
            constants.S_INSTANCE_PRELOAD_DATA,
            val
        )
    },
    [constants.M_SET_SESSION_EXPIRATION](state, val) {
        Vue.set(
            this.state[constants.NAMESPACE],
            constants.S_SESSION_EXPIRATION,
            val
        )
    },
    [constants.M_SET_USER_CONFIG](state, val) {
        Vue.set(
            this.state[constants.NAMESPACE],
            constants.S_USER_CONFIG,
            val
        )
    },
}

const actions = {
    [constants.A_SET_LOADING_DATA](state, val) {
        if (!val) {
            setTimeout(() => {
                state.commit(constants.M_SET_LOADING_DATA, val)
            }, 500)
        } else {
            state.commit(constants.M_SET_LOADING_DATA, val)
        }
    },
    [constants.A_SET_INSTANCE_PRELOAD_DATA](state, val) {
        state.commit(constants.M_SET_INSTANCE_PRELOAD_DATA, val)
    },
    [constants.A_SET_ACTIVE_PROFILE](state, val) {
        state.commit(constants.M_SET_ACTIVE_PROFILE, val)
    },
    [constants.A_SET_ACTIVE_ROUTE](state, val) {
        state.commit(constants.M_SET_ACTIVE_ROUTE, val)
    },
    [constants.A_SET_ALERT_DIALOG_DATA](state, val) {
        state.commit(constants.M_SET_ALERT_DIALOG_DATA, val)
    },
    [constants.A_SET_USER_CONFIG](state, val) {
        state.commit(constants.M_SET_USER_CONFIG, val)
    },
    [constants.A_ADD_INSTANCE_TO_PRELOAD_DATA](state, instance_to_add) {
        instance_to_add.open = true
        instance_to_add.minimized = false

        let actual_data = []
        state.state[constants.S_INSTANCE_PRELOAD_DATA].forEach((i) => {
            actual_data.push(i)
        })
        if (
            actual_data.find(
                (ipd) => ipd.instance_code.indexOf(instance_to_add.instance_code) != -1
            ) == null
        ) {
            actual_data.push(instance_to_add)
        }
        state.commit(constants.M_SET_INSTANCE_PRELOAD_DATA, actual_data)
    },
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    constants,
}
