import moment from 'moment'

require('moment/locale/es')

export default {
    URL: 'https://svpmondev1389.aws.mailteck.com:3000',

    DEFAULT_TIME_ITEM: 'now-1h',
    TIME_ITEMS: [
        {name: 'Últ. 5 minutos', code: 'now-5m'},
        {name: 'Últ. 15 minutos', code: 'now-15m'},
        {name: 'Últ. 30 minutos', code: 'now-30m'},
        {name: 'Últ. hora', code: 'now-1h'},
        {name: 'Últ. 3 horas', code: 'now-3h'},
        {name: 'Últ. 6 horas', code: 'now-6h'},
        {name: 'Últ. 12 horas', code: 'now-12h'},
        {name: 'Últ. 24h', code: 'now-24h'},
        {name: 'Hoy', code: 'now/d'},
        {name: 'Personalizado', code: 'custom'},
    ],
    INFLUX_TIME_ITEMS: [
        {name: 'Últ. 7 días', code: 'last_7_days'},
        {name: 'Hoy ' + moment().format('dddd'), code: 'today'},
        {name: 'Ayer ' + moment().add(-1, 'days').format('dddd'), code: 'yesterday'},
        {name: 'Semana actual', code: 'current_week'},
        {name: 'Semana anterior', code: 'last_week'},
        {name: 'Mes de ' + moment().format('MMMM'), code: 'current_month'},
        {name: 'Mes de ' + moment().add(-1, 'months').format('MMMM'), code: 'last_month'},
        {name: 'Año ' + moment().format('YYYY'), code: 'current_year'},
        {name: 'Año ' + moment().add(-1, 'years').format('YYYY'), code: 'last_year'},
        {name: 'Personalizado', code: 'custom'},
    ],
    DEFAULT_REFRESH_ITEM: '1m',
    REFRESH_ITEMS: [
        {name: '5 segundos', code: '5s'},
        {name: '15 segundos', code: '15s'},
        {name: '30 segundos', code: '30s'},
        {name: '1 minuto', code: '1m'},
        {name: '5 minutos', code: '5m'},
    ],
    GROUP_BY_ITEMS: [
        {'code': 'time(1d)', 'name': 'Por día'},
        {'code': 'time(1w)', 'name': 'Por semana'},
        {'code': 'time(4w)', 'name': 'Por mes'},
        {'code': 'time(1y)', 'name': 'Por año'},
    ],
    DEFAULT_DATE_ITEM: 'custom',
    DEFAULT_STATS_SEARCH: {
        all: {
            total: 0,
            results_count: 0,
            results: []
        },
        year: {
            total: 0,
            results_count: 0,
            results: []
        },
        month: {
            total: 0,
            results_count: 0,
            results: []
        },
        week: {
            total: 0,
            results_count: 0,
            results: []
        },
        current_week: {
            total: 0,
        },
        today: {
            total: 0,
        },
        yesterday: {
            total: 0,
        }
    },

}
