<template>
    <v-col v-if="selectableFields">
        <v-row class="mb-4">
            <h1 class="cb-row-page-title">Exportación de los datos de {{model}}</h1>
        </v-row>
        <v-row>
            <p>Selecciona los campos a exportar o modifica el orden de los campos </p>
        </v-row>
        <v-row>
            <draggable
              v-model="selectableFields"

              class="cb-draggable-list"
              style="cursor: grab"
            >
                <transition-group class="row">
                    <v-col
                      v-for="(element,index) in selectableFields"
                      :key="element.value"
                      cols="3"
                      @click="changeElementStatus(index)"
                    >
                        <v-list class="pa-0">
                            <v-list-item
                              :disabled="element.disabled"
                              :title="element.text"
                              class="pa-0"
                              dense
                            >
                                <v-list-item-avatar
                                  class="ma-0"
                                  height="24"
                                >{{index + 1}}
                                </v-list-item-avatar>
                                <v-list-item-title>
                                    {{element.text}}
                                </v-list-item-title>
                                <v-icon
                                  :color="element.disabled ? 'contrast_medium':'primary'"
                                  class="px-1"
                                  small
                                >visibility
                                </v-icon>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </transition-group>
            </draggable>
        </v-row>
        <v-row class="mt-4">
            <v-col>
                <v-row
                  v-if="validExport"
                  class="mb-4"
                >
                    <v-col class="cb-export-details">
                        <v-row class="mb-4">
                            <span>Detalles de la exportación:</span>
                        </v-row>
                        <v-card>
                            <v-card-text>
                                <v-row class="mb-2 pl-1">
                  <span class="ma-0">
                    Filtros seleccionados:
                  </span>
                                </v-row>
                                <ExplorerFiltersRow
                                  :response-filters="response_filters"
                                  mode="readonly_rows"
                                />
                                <v-divider class="my-2"></v-divider>
                                <v-row class="mb-2 pl-1">
                  <span class="ma-0">
                    Número de registros coincidentes: <strong class="mx-1">{{exportCount}}</strong>
                  </span>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-row class="mt-4">
                            <span>¿Generar CSV?</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mb-2"/>
                <v-row justify="space-between">
                    <v-col cols="3">
                        <button-with-icon-model-a
                          :icon="validExport ? $icons.I_GENERATE : $icons.I_SEARCH"
                          :loading="loading"
                          :text="validExport ? 'Generar':'Comprobar'"
                          @click="validExport ? exportCSV():checkSearch()"
                        />
                    </v-col>
                    <v-col cols="3">
                        <button-icon-text
                          :icon="$icons.I_CANCEL"
                          :loading="loading"
                          text="Cancelar"
                          @click="cancel()"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import draggable from 'vuedraggable'
import helpers, {downloadFile} from '@/plugins/helpers'
import ExplorerFiltersRow from '@/components/repository/explorers/ExplorerFiltersRow'
import ButtonWithIconModelA from '@/components/repository/buttons/ButtonWithIconModelA'
import ButtonIconText from '@/components/repository/buttons/ButtonIconText'

export default {
    name: 'ExportTableContent',
    components: {ButtonIconText, ButtonWithIconModelA, ExplorerFiltersRow, draggable},
    props: {
        fields: Array,
        response_filters: [Object, Array],
        model: String,
        service: Function
    },
    data() {
        return {
            selectableFields: [],
            validExport: false,
            exportCount: null,
            loading: false,
        }
    },
    computed: {
        fieldsToExport() {
            return this.selectableFields.filter(sf => !sf.disabled).map(a => a.value)
        }
    },
    created() {
        this.fields.forEach(f => {
            if (!helpers.nullOrEmpty(f.text)) {
                let searchField = Object.assign({}, f)
                searchField.disabled = false
                this.selectableFields.push(searchField)
            }
        })
    },
    methods: {
        changeElementStatus(index) {
            let element = this.selectableFields[index]
            if (helpers.nullOrEmpty(this.response_filters[element.value])) {
                element.disabled = !element.disabled
                if (element.disabled) {
                    this.selectableFields.push(this.selectableFields.splice(index, 1)[0])
                }
            } else {
                helpers.setAlertMessage(1, 'El campo es necesario ya que es un filtro')
            }
        },
        cancel() {
            this.validExport = false
            this.exportCount = null
            this.$emit('close')
        },
        checkSearch() {
            this.loading = true
            let options

            if (this.response_filters) {
                let field = Object.keys(this.response_filters)[0]
                if (field == 'OR') {
                    field = this.fieldsToExport[0]
                }
                options = {page: 1, rpp: 1, order: {field: field, direction: 'ASC'}}
            } else {
                options = {page: 1, rpp: 1, order: {field: this.fields[0].name, direction: 'ASC'}}
            }

            this.service(this.fieldsToExport, this.response_filters, options).then(extra => {

                if (extra.count <= 2000) {
                    this.validExport = true
                    this.exportCount = extra.count
                }
                this.loading = false
            })
        },
        exportCSV() {
            this.service(this.fieldsToExport, this.response_filters, {type: 'generateCsv'}).then(extra => {
                this.cancel()
                downloadFile(extra.results)
                helpers.setAlertMessage(0, 'Fichero descargado correctamente')
            })
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/resources/colors.scss";
@import "@/assets/styles/resources/variables.scss";

.cb-draggable-list {
    width: 100%;
}

.cb-draggable-list .container {
    display: block;
    width: 100%;
}

.cb-draggable-list .v-list-item {
    border: solid 1px $color_app_contrast_medium;
    margin: 5px 0px
}

.cb-export-details span {
    font-size: $font-size-3 !important;
}
</style>
