const constants = Object.freeze({
    NAMESPACE: 'vuex_management',

    S_PROCESSES_OPENED: 'state_processes_opened',
    S_PROCESSES_SEARCH_FILTERS: 'state_processes_search_filters',

    M_SET_PROCESSES_OPENED: 'mutation_set_processes_opened',
    M_SET_PROCESSES_SEARCH_FILTERS: 'mutation_set_processes_search_filters',

    A_SET_PROCESSES_OPENED: 'action_set_processes_opened',
    A_SET_SEARCH_FILTERS: 'action_set_processes_search_filters',

    S_CURRENT_PROCESS_DATA: "state_current_process_data",
    M_MUTATE_CURRENT_PROCESS_DATA: "mutation_current_process_data",
    A_SET_CURRENT_PROCESS_DATA: "action_set_current_process_data",
})

const state = {
    [constants.S_PROCESSES_OPENED]: [{
        id: 'explorer',
        name: 'Listado de procesos'
    }],
    [constants.S_PROCESSES_SEARCH_FILTERS]: {},

    [constants.S_CURRENT_PROCESS_DATA]: null,
}

const mutations = {
    [constants.M_SET_PROCESSES_OPENED](state, payload) {
        state[constants.S_PROCESSES_OPENED] =
            payload
    },
    [constants.M_SET_PROCESSES_SEARCH_FILTERS](state, payload) {
        state[constants.S_PROCESSES_SEARCH_FILTERS] =
            payload
    },

    [constants.M_MUTATE_CURRENT_PROCESS_DATA](state, payload) {
        state[constants.S_CURRENT_PROCESS_DATA] = payload
    }
}

const actions = {
    [constants.A_SET_PROCESSES_OPENED](state, processes) {
        state.commit(constants.M_SET_PROCESSES_OPENED, processes)
    },
    [constants.A_SET_SEARCH_FILTERS](state, filters) {
        state.commit(constants.M_SET_PROCESSES_SEARCH_FILTERS, filters)
    },

    [constants.A_SET_CURRENT_PROCESS_DATA]({commit}, value) {
        commit(constants.M_MUTATE_CURRENT_PROCESS_DATA, value)
    }
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    constants,
}
