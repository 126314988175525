<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        :icon="icon != null"
        :width="text != null ? 140:34"
        height="34"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          v-if="icon != null"
          small
        >{{ $icons.I_ACTIONS }}
        </v-icon>
        <span
          v-if="text != null"
          v-html="text"
        ></span>
      </v-btn>
    </template>
    <v-list class="cb-dropdown-list">
      <v-list-item
        v-for="(item, index) in actions.filter(a => a.enabled)"
        :key="index"
        class="py-1 px-2"
        link
        style="min-width: 200px"
        @click="$emit('actionLaunched',item.id)"
      >
        <v-list-item-avatar
          class="ma-0"
          height="24"
        >
          <v-icon
            :color="item.icon_color"
            small
          >{{ item.icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title class="cb-font-size-4">{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import icons from '@/constants/icons'

export default {
  name: 'MenuModelA',
  props: {
    icon: {
      type: String,
      default: icons.I_ACTIONS
    },
    color: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: null
    },
    actions: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>


