const fields = {
  ID: 'id',
  PROCESS_CODE: 'process_code',
  NAME: 'process_name',
  STATE_COMMENTS: 'description',
  STATUS: 'status',
  USER_OPERATION_ID: 'user_operation_id',
  USER_OPERATION: 'user_operation',
  USER_OPERATION_USERNAME: 'user_operation_username',
  USER_QA_ID: 'user_qa_id',
  USER_QA: 'user_qa',
  USER_QA_USERNAME: 'user_qa_username',
  USER_PMO_ID: 'user_pmo_id',
  USER_PMO: 'user_pmo',
  USER_PMO_USERNAME: 'user_pmo_username',
  RELEASE_PLANNED_DATE: 'release_planned_date',
  START_PLANNED_DATE: 'start_planned_date',
  LAST_UPDATE: 'last_update',
  LINKED_PLATFORMS: 'linked_platforms',
  BILLING_PLATFORM: 'billing_platform',
  BILLING_COMMENTS: 'billing_comments',
  BILLING_STATUS: 'billing_status',
  TEST_ID: 'test_id',
  TEST_TYPE: 'test_type',
  MASTER_ACCOUNT_NAME: 'master_account_name',
  MASTER_ACCOUNT_CODE: 'master_account_code',
  SUBACCOUNT_NAME: 'subaccount_name',
  SUBACCOUNT_CODE: 'subaccount_code',
  PROJECT_NAME: 'project_name',
  PROJECT_CODE: 'project_code',
  MIGRATION_PENDING: 'migration_pending'
}
export default (fields)
export const billing_platforms = [
  {id: 'CertySign', name: 'CertySign'},
  {id: 'Communicalia', name: 'Communicalia'},
  {id: 'Operations IT', name: 'Operaciones TIC'},
  {id: 'Postamail', name: 'Postamail'}
]
export const billing_status = [
  {id: 'active', name: 'Activa'},
  {id: 'pending', name: 'Pendiente'},
  {id: 'N/A', name: 'N/A'}
]
export const linked_platforms = [
  {id: 'communicalia', name: 'Communicalia'},
  {id: 'postamail', name: 'Postamail'},
  {id: 'salesforce', name: 'Salesforce'},
  {id: 'certycomms', name: 'CertyComms'}
]
//'CertySign','Communicalia','Postamail','Operations IT','other'
export const test_types = [
  {id: 'manual', name: 'Manual'},
  {id: 'automatic', name: 'Automático'}
]
export const statuses = [
  {id: 'pending', name: 'Planificación pendiente', color: '#f3ba07', icon: 'schedule'},
  {id: 'working', name: 'En desarrollo', color: '#00319a', icon: 'engineering'},
  {id: 'testing_qa', name: 'Testing QA', color: '#ec7fdd', icon: 'content_paste_search'},
  {id: 'testing_client', name: 'Testing Cliente', color: '#f37e2d', icon: 'group'},
  {id: 'on_deployment', name: 'En despliegue', color: '#bd7311', icon: 'cloud_upload'},
  {id: 'testing_uat', name: 'Testing UAT', color: '#20a9a5', icon: 'safety_check'},
  {id: 'on_production', name: 'En producción', color: '#66a455', icon: 'check_circle'},
  {id: 'stopped', name: 'Detenido', color: '#d33737', icon: 'block'}
]

export const table_view_fields = {
  default: {
    [fields.STATUS]: {width: "40px"},
    [fields.MASTER_ACCOUNT_NAME]: {width: "8%"},
    [fields.NAME]: {width: "16%"},
    [fields.STATE_COMMENTS]: {width: "38%"},
    [fields.USER_OPERATION_USERNAME]: {width: "8%"},
    [fields.USER_QA_USERNAME]: {width: "8%"},
    [fields.USER_PMO_USERNAME]: {width: "8%"},
    [fields.LAST_UPDATE]: {width: "15%"},
    actions: {width: "34px"}
  },
  operations: {
    [fields.STATUS]: {width: "40px"},
    [fields.MASTER_ACCOUNT_NAME]: {width: "8%"},
    [fields.NAME]: {width: "16%"},
    [fields.STATE_COMMENTS]: {width: "25%"},
    [fields.PROCESS_CODE]: {width: "6%"},
    [fields.USER_OPERATION_USERNAME]: {width: "10%"},
    [fields.START_PLANNED_DATE]: {width: "15%"},
    [fields.RELEASE_PLANNED_DATE]: {width: "15%"},
    [fields.LAST_UPDATE]: {width: "15%"},
    actions: {width: "34px"}
  },
  qa: {
    [fields.STATUS]: {width: "40px"},
    [fields.MASTER_ACCOUNT_NAME]: {width: "8%"},
    [fields.NAME]: {width: "16%"},
    [fields.PROCESS_CODE]: {width: "6%"},
    [fields.USER_OPERATION_USERNAME]: {width: "8%"},
    [fields.USER_QA_USERNAME]: {width: "8%"},
    [fields.START_PLANNED_DATE]: {width: "15%"},
    [fields.RELEASE_PLANNED_DATE]: {width: "15%"},
    [fields.LAST_UPDATE]: {width: "15%"},
    actions: {width: "34px"}
  },
  billing: {
    [fields.STATUS]: {width: "40px"},
    [fields.NAME]: {width: "16%"},
    [fields.PROCESS_CODE]: {width: "6%"},
    [fields.RELEASE_PLANNED_DATE]: {width: "15%"},
    [fields.BILLING_PLATFORM]: {width: "8%"},
    [fields.BILLING_STATUS]: {width: "8%"},
    [fields.BILLING_COMMENTS]: {width: "20%"},
    [fields.LAST_UPDATE]: {width: "15%"},
    actions: {width: "34px"}
  },
}

export function getAllFields() {

  return [
    fields.ID,
    fields.STATUS,
    fields.PROCESS_CODE,
    fields.STATE_COMMENTS,
    fields.USER_PMO,
    fields.USER_OPERATION,
    fields.USER_QA,
    fields.START_PLANNED_DATE,
    fields.RELEASE_PLANNED_DATE,
    fields.BILLING_PLATFORM,
    fields.BILLING_COMMENTS,
    fields.BILLING_STATUS,
    fields.TEST_ID,
    fields.TEST_TYPE,
    fields.LINKED_PLATFORMS,
    fields.MIGRATION_PENDING
  ]
}