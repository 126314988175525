const constants = Object.freeze({
  NAMESPACE: 'vuex_templates',

  S_TEMPLATES_OPENED: 'state_templates_opened',
  S_SEARCH_FILTERS: 'state_search_filters',

  M_SET_TEMPLATES_OPENED: 'mutation_set_templates_opened',
  M_SET_SEARCH_FILTERS: 'mutation_set_search_filters',

  A_SET_TEMPLATES_OPENED: 'action_set_templates_opened',
  A_SET_SEARCH_FILTERS: 'action_set_search_filters',

  S_CURRENT_TEMPLATE_DATA: 'state_current_process_data',
  M_MUTATE_CURRENT_TEMPLATE_DATA: 'mutation_current_process_data',
  A_SET_CURRENT_TEMPLATE_DATA: 'action_set_current_process_data',
})

const state = {
  [constants.S_TEMPLATES_OPENED]: [{
    id: 'explorer',
    name: 'Listado de plantillas'
  }],
  [constants.S_SEARCH_FILTERS]: {},

  [constants.S_CURRENT_TEMPLATE_DATA]: null,
}

const mutations = {
  [constants.M_SET_TEMPLATES_OPENED](state, payload) {
    state[constants.S_TEMPLATES_OPENED] =
      payload
  },
  [constants.M_SET_SEARCH_FILTERS](state, payload) {
    state[constants.S_SEARCH_FILTERS] =
      payload
  },

  [constants.M_MUTATE_CURRENT_TEMPLATE_DATA](state, payload) {
    state[constants.S_CURRENT_TEMPLATE_DATA] = payload
  }
}

const actions = {
  [constants.A_SET_TEMPLATES_OPENED](state, templates) {
    state.commit(constants.M_SET_TEMPLATES_OPENED, templates)
  },
  [constants.A_SET_SEARCH_FILTERS](state, filters) {
    state.commit(constants.M_SET_SEARCH_FILTERS, filters)
  },

  [constants.A_SET_CURRENT_TEMPLATE_DATA]({ commit }, value) {
    commit(constants.M_MUTATE_CURRENT_TEMPLATE_DATA, value)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  constants,
}
