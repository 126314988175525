<template>
  <v-card-title class="pa-0">
    <v-row
      :class="'ma-0 cb-title-card justify-space-between cb-title-card-' + mode"
      dense
    >
      <v-row class="my-auto ml-2">
        <v-icon
          v-if="mode != 'compact'"
          :color="iconColor"
          class="mr-2"
        >{{icon}}
        </v-icon>
        <span
          :class="titleColor != null ? titleColor +'--text': ''"
          class="cb-font-size-4  text-uppercase my-auto"
          style="vertical-align: middle;margin-top: 2px !important;"
          v-html="title"
        />
        <v-tooltip
          v-if="reload"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="cb-reload-button my-auto"
              icon
              @click="refresh"
              v-on="on"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
          </template>
          <span>Refrescar información</span>
        </v-tooltip>
      </v-row>
      <span>
        <v-row>
          <span
            v-if="selector"
            class="pa-0"
          >
            <v-row class="pr-2">
              <span
                class="my-auto mr-2"
                style="font-size: 14px"
              >{{selector_options[0]}}</span>
              <v-switch
                class="cb-title-switch"
                color="primary"
                dense
                hide-details
                x-small
                @change="changeSelector"
              ></v-switch>
              <span
                class="my-auto"
                style="font-size: 14px"
              >{{selector_options[1]}}</span>
            </v-row>
          </span>
          <span>
            <v-menu
              v-if="actions"
              bottom
              class="my-auto"
              left
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="cb-action-button my-auto"
                  text
                  v-on="on"
                >
                  <v-icon class="my-auto">settings</v-icon>
                </v-btn>
              </template>

              <v-list
                class="cb-dropdown-list"
                dense
              >
                <v-list-item
                  v-for="action in actions_list"
                  :key="action.id"
                  link
                  @click="actionLaunched(action.id)"
                >
                  <v-list-item-icon v-if="action.icon">
                    <v-icon
                      small
                      v-text="action.icon"
                    ></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{action.name}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="minimized"
              class="cb-min-button"
              text
              title="Minimizar"
              @click="minimizedEvent"
            ><span>_</span></v-btn
            >
            <v-btn
              v-if="close"
              class="cb-close-button"
              text
              title="Cerrar [Esc]"
              @click="closeEvent"
            ><v-icon>clear</v-icon></v-btn
            >
          </span>
        </v-row>
      </span>
      <v-icon
        v-if="mode == 'compact'"
        :color="iconColor"
        class="mr-2"
      >{{icon}}
      </v-icon>
    </v-row>
  </v-card-title>
</template>

<script>
export default {
  name: 'CardTitle',
  props: {
    title: String,
    icon: String,
    iconColor: String,
    titleColor: String,
    reload: {
      default: false,
      type: Boolean,
    },
    selector: {
      default: false,
      type: Boolean,
    },
    selector_options: {
      default: null,
      type: Array,
    },
    close: {
      default: false,
      type: Boolean,
    },
    minimized: {
      default: false,
      type: Boolean,
    },
    actions: {
      default: false,
      type: Boolean,
    },
    actions_list: {
      default: null,
      type: Array,
    },
    mode: {
      default: 'dense',
      type: String,
    },
    showSearch: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      searchText: null,
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    closeEvent() {
      this.$emit('close')
    },
    minimizedEvent() {
      this.$emit('minimized')
    },
    actionLaunched(val) {
      this.$emit('action_launched', val)
    },
    changeSelector(val) {
      this.$emit('selector_changed', val === true ? 1 : 0)
    },
    changeSearch(clear) {
      if (clear === true) {
        this.searchText = ''
      }
      this.$emit('search_changed', this.searchText)
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/variables";
@import "../../../assets/styles/resources/colors";

.cb-title-card span {

  color: $color_app_clear !important;
}

.cb-title-card {
  padding: 8px;
  background: $color_app_primary !important;
}


.cb-title-card .title {
  font-size: $font-size-4 !important;
}

.cb-title-card .cb-search {
  width: 100%;
}

.cb-title-card .cb-search input {
  padding-right: 5px !important;
  padding-left: 5px !important;
  border-bottom: none !important;
}

.cb-title-card .cb-search .v-input__control {
  min-height: 30px !important;
}

.cb-title-card .cb-search .v-input__slot {
  background-color: $color_app_contrast_low !important;
}

.cb-title-card .cb-search.v-input--is-focused {
  border-bottom: none !important;
}

.cb-title-card i {
  font-size: $icon-size-4 !important;
  line-height: $icon-size-4 !important;
  color: $color_app_clear !important;
}

.cb-title-card-dense {
  padding: 8px;

}

.cb-title-card-compact {
  padding: 5px;
  background: $color_app_contrast_low !important;
  border-bottom: 1px solid $color_app_contrast_medium;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.cb-title-card-compact .text-truncate {
  vertical-align: text-bottom !important;
}

.cb-title-card-compact span {
  color: $color_app_contrast_high !important;
  vertical-align: middle;
}

.cb-title-card-compact i {
  color: $color_app_contrast_high !important;
}

.cb-card .v-card__title {
  padding: 0;
}

.cb-reload-button i {
  font-size: 18px !important;
  color: #007685 !important;
}

.cb-reload-button {
  color: #007685 !important;
  font-weight: bold;
  height: 14px !important;
  width: 14px !important;
  margin-left: 8px;
  min-width: 10px !important;
}


.cb-close-button {
  font-weight: bold;
  height: 20px !important;
  margin-left: 5px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 40px !important;
}

.cb-min-button {
  font-weight: bold;
  height: 28px !important;
  margin-left: 5px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 40px !important;
}

.cb-action-button {
  font-weight: bold;
  height: 26px !important;
  margin-left: 5px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 40px !important;
}

.cb-action-button i {
  font-size: 18px !important;
}

.cb-min-button span {
  margin-bottom: 3px;
}

.cb-close-button .v-btn__content {
  font-size: 20px !important;
}

.cb-title-switch {
  margin: 0 0 0 5px;
  padding-top: 0;
}

.cb-dropdown-list .v-list-item {
  padding-left: 8px;
  padding-right: 8px;
}

.cb-dropdown-list .v-list-item__icon {
  margin: 0 !important;
  margin-top: 3px !important;
}

.cb-dropdown-list .v-list-item__icon i {
  font-size: 16px !important;
}
</style>
