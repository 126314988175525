import operations from '@/router/routes/operations'
import administration from '@/router/routes/administration'
import monitoring from '@/router/routes/monitoring'
import protocol from '@/router/routes/protocol'
import {P_ACCOUNT, P_ADMINISTRATION} from '@/constants/group_permissions'
import Test from '@/views/Test'
import moment from 'moment'
import commservices from "@/router/routes/commservices";
import projectManagement from "@/router/routes/projectManagement";

const Home = () => import(/* webpackChunkName: "Home"*/ '@/views/Home')
const Login = () => import(/* webpackChunkName: "Login"*/ '@/views/Login')
const UserAccount = () => import(/* webpackChunkName: "UserAccount"*/ '@/views/UserAccount')
const Error = () => import(/* webpackChunkName: "Error"*/ '@/views/Error')
const Forbidden = () => import(/* webpackChunkName: "Forbidden"*/ '@/views/Forbidden')
let hour = parseInt(moment().format('HH'))
let routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: hour < 12 ? 'Buenos días, bienvenid@ a APP Center' : 'Buenas tardes, bienvenid@ a APP Center',
            title_short: 'Inicio',
            navigable: false,
            icon: hour < 12 ? 'wb_sunny' : 'wb_twilight',
            requiresAuth: true
        },
    },
    {
        path: '/account',
        name: 'account',
        component: UserAccount,
        meta: {
            perm: P_ACCOUNT,
            title: 'Mi cuenta',
            title_short: 'Mi cuenta',
            navigable: false,
            icon: 'home',
            requiresAuth: true
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login', navigable: false
        },
    },
    {
        path: '/app-center/login-cas',
        name: 'login',
        redirect: '/login',
        meta: {
            title: 'Login',
            navigable: false
        },
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: Forbidden,
        meta: {
            title: '',
            navigable: false,
            requiresAuth: false,
        },
    },
    {
        path: '/error',
        name: 'error',
        component: Error,
        meta: {
            title: '',
            navigable: false,
            requiresAuth: false,
        },
    },
    {
        path: '/test',
        name: 'test',
        component: Test,
        meta: {
            perm: P_ADMINISTRATION,
            title: 'Test',
            title_short: 'Test',
            navigable: false,
            icon: 'home',
            requiresAuth: false
        },
    }
]

routes = routes.concat(operations)
routes = routes.concat(monitoring)
routes = routes.concat(protocol)
routes = routes.concat(commservices)
routes = routes.concat(administration)
routes = routes.concat(projectManagement)

export default routes
