<template>
  <v-list-item
    v-if="twoLine"
    class="pa-0"
  >
    <v-list-item-content class="pa-0 pb-1">
      <v-row class="justify-space-between cb-row-info py-1">
        <div class="my-auto">
          <v-list-item-title class="font-weight-medium">{{
              title
            }}
          </v-list-item-title>
        </div>
        <div class="text-right">
          <v-list-item-title>{{ value }}</v-list-item-title>
          <v-list-item-subtitle>{{ moreInfo }}</v-list-item-subtitle>
        </div>
      </v-row>
    </v-list-item-content>
  </v-list-item>
  <v-row
    v-else
    class="justify-space-between py-1 cb-row-info"
  >
    <h1>{{ title }}</h1>
    <span :class="{ 'cb-row-data-alert': alert }">
      <span class="font-weight-medium">
        <span v-if="checkData && typeof value == 'number'">
          <span v-if="value == 0"><v-icon color="red">clear</v-icon></span>
          <span v-if="value == 1"><v-icon color="green">check</v-icon></span>
        </span>
        <span v-if="checkData && typeof value == 'boolean'">
          <span v-if="value == false"><v-icon color="red">clear</v-icon></span>
          <span v-else><v-icon color="green">check</v-icon></span>
        </span>
        <span
          v-else-if="value"
          v-html="value"
        > </span>
      </span>
      <span
        v-if="moreInfo != null"
        class="ml-1"
      >{{ moreInfo }}</span>
      <span
        v-if="button"
        class="ml-1"
      ><v-btn
        @click="launchActionButton"
      ><v-icon>{{ buttonValue.icon }}</v-icon></v-btn
      ></span
      >
    </span>
  </v-row>
</template>

<script>
export default {
  name: 'CardRowInfo',
  props: {
    title: String,
    value: [String, Boolean, Number],
    checkData: Boolean,
    moreInfo: String,
    alert: Boolean,
    button: Boolean,
    buttonValue: Object,
    twoLine: Boolean,
  },
  methods: {
    launchActionButton() {
      this.$emit('buttonAction', this.buttonValue.value)
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";

.cb-row-info {
  border-bottom: 1px solid $color_low-dark;
}

.cb-row-info:hover {
  background-color: $color_tertiary;
}

.cb-row-data-alert {
  color: $color_error;
}
</style>
