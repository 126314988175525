<template>
  <v-card-actions class="py-3 cb-dialog-card-footer">
    <v-row :justify="!saveButtonVisible && !editButtonVisible ? 'end' : 'space-between'">
      <div
        v-if="saveButtonVisible"
      >
        <v-row>
          <ButtonWithIconModelA
            :color="saveButtonColor"
            :disabled="saveButtonDisabled"
            :icon="saveButtonCustomIcon"
            :text="saveButtonCustomText"
            @click="save()"
          />
          <a
            v-if="!hideDiscardButton"
            class="ml-2 "
            @click="$emit('discard')"
          >
            <v-icon
              class="mr-2"
              small
            >clear
            </v-icon>
            <span>Descartar cambios</span></a>
        </v-row>
      </div>
      <ButtonWithIconModelA
        v-else-if="editButtonVisible"
        :icon="editButtonCustomIcon"
        :text="editButtonCustomText"
        @click="edit()"
      />
      <ButtonWithIconModelA
        v-if="closeButtonVisible"
        :icon="closeButtonCustomIcon"
        :text="closeButtonCustomText"
        color="contrast_medium"
        @click="close()"
      />
    </v-row>
  </v-card-actions>
</template>

<script>
import icons from '@/constants/icons'
import ButtonWithIconModelA from '@/components/repository/buttons/ButtonWithIconModelA'

export default {
  name: 'DialogCardFooter',
  components: { ButtonWithIconModelA },
  props: {
    editButtonVisible: {
      type: Boolean,
      default: false
    },
    editButtonCustomText: {
      type: String,
      default: 'Editar'
    },
    editButtonCustomIcon: {
      type: String,
      default: icons.I_EDIT
    },
    hideDiscardButton: {
      type: Boolean,
      default: true
    },
    closeButtonVisible: {
      type: Boolean,
      default: false
    },
    closeButtonCustomText: {
      type: String,
      default: 'Cerrar'
    },
    closeButtonCustomIcon: {
      type: String,
      default: icons.I_CANCEL
    },

    saveButtonVisible: {
      type: Boolean,
      default: false
    },
    saveButtonColor: {
      type: String,
      default: 'primary'
    },
    saveButtonDisabled: {
      type: Boolean,
      default: false
    },
    saveButtonCustomText: {
      type: String,
      default: 'Guardar'
    },
    saveButtonCustomIcon: {
      type: String,
      default: icons.I_SAVE
    },
  },
  methods: {
    save() {
      this.$emit('save')
    },
    edit() {
      this.$emit('edit')
    },
    close() {
      this.$emit('close')
    },
    deleteAction() {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";

.cb-dialog-card-footer {
  border-top: 1px solid $color_app_contrast_medium;
  background-color: $color_app_contrast_low;
  position: sticky;
  bottom: 0;
}
</style>
