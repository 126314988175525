export default Object.freeze({
    BOOLEAN: 'boolean',
    STRING: 'string',
    NUMBER: 'number',
    COLOR: 'color',
    FINDER: 'finder',
    BITMASK: 'bitmask',
    TIMESTAMP: 'timestamp',


    CUSTOM_SELECTOR: 'custom_selector',
    CUSTOM_AUTOCOMPLETE: 'custom_autocomplete',
    CUSTOM_CHIP_GROUP: 'custom_chip_group',

    BOOLEAN_VALUES: [
        {
            code: '0',
            icon: 'cancel',
            text: 'Desactivado'
        },
        {
            code: '1',
            icon: 'check_circle',
            text: 'Activado'
        }
    ],
    COMMSTRACKER_DOMAIN_VALUES: [
        {
            code: '0',
            icon: 'lock_open',
            color: 'success',
            text: 'Desbloqueado'
        },
        {
            code: '1',
            icon: 'lock',
            color: 'error',
            text: 'Bloqueado'
        }
    ]
})
