<template>
		<v-row
			class="ma-0 justify-space-between apc-tabs-horizontal"
			dense
		>
				<v-tabs
					v-model="tab"
					center-active
					hide-slider
					show-arrows
				>
						<v-tab

							v-for="tab in tabs"
							:id="tab.id"
							:key="tab.id"
							:disabled="tab.disabled"
							:href="'#'+tab.id"
						>
								<v-icon
									v-if="tab.icon"
									:class="{'warning--text':tab.changesPending, 'error--text':tab.error}"
									class="mr-2 mb-0"
									small
								>{{tab.icon}}
								</v-icon>
								<span :class="{'warning--text':tab.changesPending, 'error--text':tab.error}" v-html="tab.name"></span>
						</v-tab>
				</v-tabs>
		</v-row>
</template>

<script>
export default {
		name: 'TabsHorizontalModelA',
		props: {
				tabSelected: {
						type: String,
						default: null
				},
				tabs: {
						type: Array,
						default: () => {
								return []
						}
				}
		},
		data() {
				return {
						tab: this.tabSelected
				}
		},
		watch: {
				tabSelected(val) {
						this.tab = val
				},
				tab(val) {
						this.$emit('changeTab', val)
				}
		}
}
</script>

<style
	lang="scss"

>
@import "../../assets/styles/resources/colors.scss";
@import "../../assets/styles/resources/variables.scss";

.apc-tabs-horizontal {
		background-color: $color_app_clear;

}

.apc-tabs-horizontal > .v-tabs > .v-item-group {
		border: 1px solid $color_app_contrast_medium !important;
		height: auto !important;
		border-left: none;
		border-right: none;
}

.apc-tabs-horizontal .v-tab {
		color: $color_app_primary !important;
		min-width: 100px;
		max-width: 180px;
		font-size: $font-size-5;
		text-transform: inherit;
		border: 1px solid $color_app_contrast_medium;
		border-bottom: none;
		border-top: none;
		border-left: none;
		height: 28px;
}

.apc-tabs-horizontal .v-tab span {
		vertical-align: middle;
}

.apc-tabs-horizontal .v-tab--active {
		background-color: $color_app_bg_primary !important;
}


.apc-tabs-horizontal .v-tab:hover {
		background-color: $color_app_bg_primary !important;
		opacity: 0.6;
}

.apc-tabs-horizontal .v-tab--disabled {
		color: $color_app_contrast_medium !important;
}
</style>
