<template>
    <v-btn
      :color="color"
      :disabled="disabled"
      :title="text"
      class="cb-button-text"
      outlined
      primary
      @click="$emit('click')"
    >
        <v-icon small>{{icon}}</v-icon>
        {{text}}
    </v-btn>
</template>

<script>
export default {
    name: 'ButtonIconText',
    props: {
        text: String,
        icon: String,
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            default: 'primary',
            type: String
        }
    }
}
</script>

<style lang="scss">
.cb-button-text {
    height: 28px !important;
    width: 180px !important;
    min-width: 180px !important;
    background: white;

    & i {
        margin-right: 4px !important;
    }

    transition: width 1s ease !important;

}

</style>
