<template>
  <v-container
    v-if="!loading"
  >
    <right-sidebar-standard-row
      v-for="(row, index) in standardRowsTop"
      :key="index"
      :name="row.name"
      :value="row.value"
    />

    <v-alert
      v-if="instanceAlert"
      :type="instanceAlert.type"
      class="my-2"
      dense
      icon="warning"
    >
      {{instanceAlert.message}}
    </v-alert>
    <v-divider class="mt-2"/>
    <v-container class="pa-0">
      <v-tabs
        v-model="tab"
        class="ac-sidebar-tabs"
        grow
      >
        <v-tab
          key="timeline"
          href="#timeline"
        >Timeline
        </v-tab>
        <v-tab
          key="callbacks"
          href="#callbacks"
        >Callbacks
        </v-tab>
      </v-tabs>
    </v-container>
    <v-tabs-items
      v-model="tab"
      continuous
    >
      <v-tab-item
        v-if="tab=='timeline'"
        value="timeline"
      >
        <v-container>
          <v-row
            class="mb-2"
            justify="space-between"
          >
            <v-col
              class="pa-0"
              cols="5"
            >
              <v-text-field
                v-model="eventSearch"
                class="ma-0"
                hide-details
                outlined
                placeholder="Buscar evento"
              />
            </v-col>
            <v-col
              class="pa-0"
              cols="5"
            >
              <v-row justify="end">
                <button-with-icon-model-a
                  :icon="jsonView ? 'arrow_back':'code'"
                  :text="jsonView ? 'Eventos':'D. Único'"
                  class="mr-2"
                  @click="jsonView = !jsonView"
                />
                <button-icon-tooltip
                  icon="refresh"
                  text="Refrescar"
                  @click="getInstanceInfo"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="jsonView">
            <v-jsoneditor
              v-model="instance"
              :options="{mode:'code'}"
              height="60vh"
            />
          </v-row>
          <v-row v-else>
            <events-timeline
              :event-selected="eventSelected"
              :events="events"
              :instance_events="relevantEvents"
              :small="true"
              @eventClick="changeEvent"
            />
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item
        v-if="tab=='callbacks'"
        value="callbacks"
      >
        <v-container>
          <callback-list
            :instance_code="code"
            :preview="true"
          />
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="dialogOpen" width="60vw" @click:outside="eventSelected = null">
      <v-card>
        <card-title :close="true" title="Previsualización de evento" @close="eventSelected=null"></card-title>
        <v-card-text style="height: 70vh;overflow: auto">
          <event-details-dialog
            :event-data="eventSelected"
            :events="events"
            :instance_code="instance.document.instance_code"
            :instance_events="relevantEvents"
            @close="eventSelected = null"
          ></event-details-dialog>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
  <loading-container
    v-else
    :small-screen="true"
  />
</template>

<script>
import LoadingContainer from '@/components/repository/LoadingContainer.vue'
import RightSidebarStandardRow from '@/components/global_app/rightSidebarPreview/rightSidebarStandardRow.vue'
import service_instances from '@/services/service_instances'
import EventsTimeline from '@/components/explorers/instances/instance_details/components/EventsTimeline.vue'
import service_processes from '@/services/service_processes'
import model_instance from '@/constants/models/model_instance'
import helpers from '@/plugins/helpers'
import EventDetailsDialog from '@/components/explorers/instances/instance_details/components/eventDetailsDialog.vue'
import ButtonWithIconModelA from '@/components/repository/buttons/ButtonWithIconModelA.vue'
import ButtonIconTooltip from '@/components/repository/buttons/ButtonIconTooltip.vue'
import CallbackList from '@/components/explorers/callbacks/callback_list/CallbackList.vue'
import CardTitle from "@/components/repository/cards/CardTitle.vue";

export default {
  name: 'rightSidebarSectionInstance',
  components: {
    CardTitle,
    CallbackList,
    ButtonIconTooltip,
    ButtonWithIconModelA,
    EventDetailsDialog,
    EventsTimeline,
    RightSidebarStandardRow,
    LoadingContainer
  },
  props: {
    code: String
  },
  data() {
    return {
      model_instance,
      tab: 'timeline',
      eventSearch: '',
      loading: true,
      jsonView: false,
      instance: null,
      panel: [0, 1],
      eventSelected: null,
      events: null,
      relevantEvents: null
    }
  },
  computed: {
    dialogOpen() {
      return this.eventSelected != null
    },
    standardRowsTop() {
      let rows = [
        {
          name: 'Fecha',
          value: this.$moment.unix(this.instance.document[model_instance.CREATION]).format('DD/MM/YYYY HH:mm:ss') +
            '- <span class="font-italic">' + this.$moment.unix(this.instance.document[model_instance.CREATION]).from() + '</span>'
        },
        {
          name: 'Origen',
          value: this.instance.document[model_instance.SOURCE]
        },
        {
          name: 'Macroestado',
          value: this.instance.document[model_instance.MACROSTATUS]
        },
      ]
      if (this.instance.document[model_instance.SOURCE] == 'b2b') {
        rows.push(...[{
          value: this.instance.document[model_instance.SOURCE_EXTRA].username,
          name: 'Usuario emisor',
        }, {
          value: this.getFormatContactData(),
          name: 'Datos contacto',
        }])
      }
      /*, {
              value: '[' + this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_id + '] ' + this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_name + ' ' +
                this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_surname,
              name: 'Destinatario'

          },*/
      if (this.instance.document[model_instance.SOURCE_EXTRA].recipient_data) {
        rows.push({
          value: '[' + this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_id + '] ' + this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_name + ' ' +
            this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_surname,
          name: 'Destinatario'
        })
      }
      return rows
    },
    instanceAlert() {
      if (this.productionVersions != null && this.productionVersions.length == 0) {
        return {
          type: 'error',
          message: 'La plantilla no tiene versión de producción'
        }
      }
      return null
    },
  },
  watch: {
    eventSearch() {
      this.fillEvents()
    }
  },
  created() {
    this.getInstanceInfo()
  },
  methods: {
    getFormatContactData() {
      if (this.instance.document[model_instance.SOURCE_EXTRA].recipient_data == null) {
        return "No Disponible"
      }
      let info = ''
      if (!helpers.nullOrEmpty(this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_email)) {
        info += this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_email
      }
      if (!helpers.nullOrEmpty(this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_phone)) {
        info += '(+' + this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_country_code + ') ' + this.instance.document[model_instance.SOURCE_EXTRA].recipient_data.contact_phone
      }
      return info
    },
    async fillEvents() {
      let re = await service_processes.getRelevantStatuses(this.instance.document[model_instance.PROCESS_CODE])
      this.relevantEvents = re.events
      this.events = model_instance.checkEvents(this.instance.document[model_instance.EVENTS], this.eventSearch)
    },
    async getInstanceInfo() {
      this.loading = true
      this.instance = await service_instances.getInstanceDetails(this.code)
      await this.fillEvents()
      this.loading = false
    },
    changeEvent(event) {
      this.eventSelected = event
    }
  }
}
</script>

<style lang="scss">
@import "../../../../assets/styles/resources/colors";

</style>