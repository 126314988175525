<template>
  <v-menu
    v-if="$route.name != 'home' && $route.name != 'alerts' && errors && health && service_user.checkGroupPermission(P_MONITORING_ALERTS)"
    v-model="errorsOpened"
    bottom
    left
    offset-y
    open-on-click
    tile
  >
    <template v-slot:activator="{ on }">
      <v-chip
        :color="health.color"
        class="my-auto cb-alert-chip font-weight-medium pr-0"
        label
        small
        v-on="on"
      >
        <span class="mr-1">{{ helpers.numberWithCommas(health.errors) }}</span>
        <span class="">{{ health.errors == 1 ? 'Alerta' : 'Alertas' }}</span>
        <v-btn
          class="ml-2"
          icon
          small
          title="Refrescar alertas"
          @click.stop="getConfigInfo()"
        >
          <v-icon
            color="white"
            style="font-size: 18px !important;"
          >refresh
          </v-icon>
        </v-btn>
      </v-chip>
    </template>
    <v-list>
      <v-list-item
        link
        @click="goToErrorView('errors_handler')"
      >
        <v-list-item-avatar>
          <v-icon
            :color="health.color"
            style="font-size: 40px"
          >{{ health.icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="cb-font-size-3">
            <strong>{{ helpers.numberWithCommas(health.errors) }}</strong> Alertas detectadas
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider/>
    <v-list
      dense
      style="width: 300px"
    >
      <v-list-item
        v-for="(section,index) in Object.keys(errors)"
        :key="index"
        link
        @click="goToErrorView(section)"
      >
        <v-list-item-content class="py-1">
          <v-list-item-title>
            <v-row
              class="px-2 justify-space-between"
            >
              <div>
                <v-row>
                  <v-icon
                    :color="errors[section] == 0 ? 'success' : 'error'"
                    class="mr-2 my-auto"
                    small
                  >{{ errors[section] == 0 ? 'check_circle' : 'warning' }}
                  </v-icon>
                  <span class="text-uppercase my-auto ">{{ section }}</span>
                </v-row>
              </div>
              <span
                :class="{'success--text':errors[section] == 0}"
                class="error--text  ml-8 my-auto"
              >{{
                  errors[section]
                }}</span>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import helpers from '@/plugins/helpers'
import service_user from '@/services/service_user'
import { P_MONITORING_ALERTS } from '@/constants/group_permissions'
import service_protocol from '@/services/service_protocol'


export default {
  name: 'AlertMenu',
  data() {
    return {
      helpers,
      service_user,
      P_MONITORING_ALERTS,
      errorsOpened: false,
      errors: {},
      health: {
        color: 'grey',
        errors: 0,
      },
    }
  },
  watch: {

    errorsOpened(val) {
      if (val) {
        this.getConfigInfo()
      }
    }
  },
  created() {
    this.getConfigInfo()

  },
  methods: {

    checkConfig() {
      if (this.$route.path.indexOf('error') != -1) {
        if (this.errorsOpened || this.$route.path.indexOf('monitoring') != -1) {
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.getConfigInfo()
          }, 15000)
        }
      }
    },
    goToErrorView(error) {
      helpers.redirect('alerts', null, {
        section: error
      })
    },
    getConfigInfo() {
      if (service_user.checkGroupPermission(P_MONITORING_ALERTS)) {
        this.health = {
          color: 'warning',
          errors: 0,
        }
        service_protocol.getConfigOverview().then(response => {
          let info = response.data.extra
          this.errors = info
          if (Object.values(info).find(val => val !== 0) != null) {
            let error_count = 0
            Object.values(info).forEach(val => {
              error_count += val
            })
            this.health = {
              code: 'error',
              name: error_count + ' alertas activas',
              errors: error_count,
              color: 'error',
              icon: 'error'
            }
          } else {
            this.health = {
              code: 'ok',
              name: 'sin errores',
              errors: 0,
              color: 'success',
              icon: 'check_circle'
            }
          }
          this.checkConfig()
        })
      }
    },
  }
}
</script>