import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import vuex_support from './modules/sections/vuex_support'
import vuex_instances from './modules/sections/vuex_instances'
import vuex_statistics from './modules/sections/vuex_statistics'
import vuex_processes from './modules/sections/vuex_processes'
import vuex_global from './modules/sections/vuex_global'
import location from './modules/location'
import vuex_authentication from './modules/vuex_authentication'
import vuex_templates from './modules/sections/vuex_templates'
import vuex_administration from './modules/sections/vuex_administration'
import vuex_b2b from './modules/sections/vuex_b2b'
import vuex_protocol from '@/store/modules/sections/vuex_protocol'
import vuex_notarial_deposit from '@/store/modules/sections/vuex_notarial_deposit'
import vuex_management from "@/store/modules/sections/vuex_management";
import vuex_commservices from "@/store/modules/sections/vuex_commservices";

Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        vuex_authentication: vuex_authentication,
        vuex_support: vuex_support,
        vuex_administration: vuex_administration,
        vuex_statistics: vuex_statistics,
        vuex_instances: vuex_instances,
        vuex_processes: vuex_processes,
        vuex_management: vuex_management,
        vuex_global: vuex_global,
        vuex_templates: vuex_templates,
        vuex_protocol: vuex_protocol,
        vuex_notarial_deposit: vuex_notarial_deposit,
        vuex_commservices: vuex_commservices,
        vuex_b2b: vuex_b2b,
        location: location,
    },
    strict: false,

    plugins: [
        createPersistedState({
            storage: window.localStorage,
        })
    ],
})
