import helpers from '@/plugins/helpers'
import Axios from 'axios'

export default {

  find(url, fields, filters, options) {
    filters = !helpers.nullOrEmpty(filters) ? filters : null
    options = !helpers.nullOrEmpty(options) ? options : null
    if (options != null && options.sortBy != null && options.sortDesc != null) {
      options.order = {
        field: options.sortBy[0],
        direction: options.sortDesc[0] ? 'DESC' : 'ASC'
      }

    }
    return Axios.get(
      process.env.VUE_APP_ENDPOINT + url,
      {
        params: {
          fields: fields,
          filters: filters,
          options: options
        }
      }
    ).then((response) => {
      if (!response.data.extra) {
        helpers.setAlertMessage(1, 'No hay resultados disponibles')
        return {}
      }
      if (response.data.extra.results === false) {
        helpers.setAlertMessage(1, 'Al menos un campo no disponible en la vista')
      }
      return response.data.extra
    })

  }
}
