const constants = Object.freeze({
    NAMESPACE: 'vuex_commservices',

    S_COMMSTRACKER_DOMAINS_SEARCH_FILTERS: 'state_commstracker_domains_search_filters',
    S_COMMSERVICES_USERS_SEARCH_FILTERS: 'state_commservices_users_search_filters',

    M_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS: 'mutation_set_commstracker_domains_search_filters',
    M_SET_COMMSERVICES_USERS_SEARCH_FILTERS: 'mutation_set_commservices_users_search_filters',

    A_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS: 'action_set_commstracker_domains_search_filters',
    A_SET_COMMSERVICES_USERS_SEARCH_FILTERS: 'action_set_commservices_users_search_filters',

})

const state = {

    [constants.S_COMMSTRACKER_DOMAINS_SEARCH_FILTERS]: {},
    [constants.S_COMMSERVICES_USERS_SEARCH_FILTERS]: {},
}

const mutations = {

    [constants.M_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS](state, payload) {
        state[constants.S_COMMSTRACKER_DOMAINS_SEARCH_FILTERS] = payload
    },
    [constants.M_SET_COMMSERVICES_USERS_SEARCH_FILTERS](state, payload) {
        state[constants.S_COMMSERVICES_USERS_SEARCH_FILTERS] = payload
    }
}

const actions = {
    [constants.A_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS](state, filters) {
        state.commit(constants.M_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS, filters)
    },
    [constants.A_SET_COMMSERVICES_USERS_SEARCH_FILTERS](state, filters) {
        state.commit(constants.M_SET_COMMSERVICES_USERS_SEARCH_FILTERS, filters)
    }
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    constants,
}
