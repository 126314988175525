<template>
    <v-row
      v-if="property.id != 'delete'"
      class="cb-row-property"
      justify="space-between"
    >
        <v-col
          :lg="smallDescription ? 9:7"
          class="my-auto pa-0"
          cols="12"
        >
            <v-row
              class="mb-1"
              justify="space-between"
            >
        <span>
          <v-icon
            v-if="error"
            class="mr-1 my-auto"
            color="error"
            small
          >{{$icons.I_ALERT}}
          </v-icon>
          <span
            :class="{'error--text':error,'warning--text' :internalValue != value && !error}"
            class="my-auto text-uppercase font-weight-medium"
          >
            {{property.name}}
          </span>
        </span>
                <v-tooltip
                  v-if="property.description"
                  bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="d-block d-md-none"
                          color="primary"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                            {{$icons.I_HELP}}
                        </v-icon>
                    </template>
                    <span
                      v-html="property.description"
                    />
                </v-tooltip>
            </v-row>
            <v-row>
                <span
                  v-if="!isEditing || property.disabled == true"
                  class="font-weight-medium mt-2 text-truncate"
                  v-html="formatValue"
                />
                <v-col
                  v-else
                  class="pa-0"
                  cols="8"
                >
                    <v-text-field
                      v-if="property.type == types.STRING"
                      v-model="internalValue"
                      :class="{['cb-input-' + property.extra.subtype]: property.extra != null}"
                      :counter="property.extra.subtype != 'color'"
                      :error="error != null"
                      :error-messages="error != null ? [error.text]:[]"
                      :maxlength="property.extra != null ? property.extra.maxLength : 255"
                      :placeholder="'Selecciona ' + property.name"
                      :type="property.extra != null ? property.extra.subtype : 'text'"
                      class="cts-h-100"
                      clearable
                      outlined
                    />
                    <v-slider
                      v-else-if="property.type == types.NUMBER"
                      v-model="internalValue"
                      :error="error != null"
                      :error-messages="error != null ? [error.text]:[]"
                      :max="property.extra.max"
                      :min="property.extra.min"
                      class="my-auto"
                      style="max-width: 50%;"
                      thumb-label
                      ticks
                    >
                        <template v-slot:append>
                            <v-text-field
                              v-model="internalValue"
                              class="ma-0 mr-2 centered-input"
                              hide-details
                              outlined
                              style="width: 48px"

                            ></v-text-field>
                            <span
                              v-if="property.extra != null && property.extra.unit != null"
                              class="my-auto"
                            >
                {{property.extra.unit}}
              </span>
                        </template>
                    </v-slider>

                    <field-chip-group
                      v-else-if="property.type == types.BOOLEAN"
                      v-model="internalValue"
                      :items="types.BOOLEAN_VALUES"
                      item_name="text"
                      item_value="code"
                    />
                    <BitmaskField
                      v-else-if="property.type === types.BITMASK"
                      v-model="internalValue"
                      :items="property.extra.bitmask"
                    />
                    <field-chip-group
                      v-else-if="property.type == types.COMMSTRACKER_DOMAIN_VALUES"
                      v-model="internalValue"
                      :items="types.COMMSTRACKER_DOMAIN_VALUES"
                      item_name="text"
                      item_value="code"
                    />
                    <field-chip-group
                      v-else-if="property.type == types.CUSTOM_CHIP_GROUP"
                      v-model="internalValue"
                      :item_name="property.extra.item_text"
                      :item_value="property.extra.item_value"
                      :items="property.extra.items"
                    />
                    <v-autocomplete
                      v-else-if="property.type == types.CUSTOM_AUTOCOMPLETE"
                      v-model="internalValue"
                      :item_name="property.extra.item_text"
                      :item_value="property.extra.item_value"
                      :items="property.extra.items"
                      :placeholder="'Elige ' + property.name"
                      dense
                      hide-details
                      outlined
                    />
                    <FinderSelector
                      v-else-if="property.type == types.FINDER"
                      v-model="internalValue"
                      :additional_values="property.extra.additional_values"
                      :filters="property.extra.filters"
                      :not_null="property.extra.not_null"
                      :placeholder="property.name"
                      :text_name="property.extra.item_text"
                      :text_value="property.extra.item_value"
                      :url="property.extra.finder_url"
                    />
                    <v-text-field
                      v-else-if="property.type == types.TIMESTAMP"
                      v-model="internalValue"
                      class="cb-filter-text"
                      hide-details
                      outlined
                      type="datetime-local"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col
          v-if="property.description"
          :lg="smallDescription ? 3:5"
          class="pa-2 my-auto d-none d-md-block"
          md="3"
        >
            <p
              class="ma-0 cb-text-descriptor"
              v-html="property.description"
            />
        </v-col>
    </v-row>
    <v-row
      v-else
      class="cb-row-property mt-4 pr-2"
      justify="end"
    >
        <ButtonWithIconModelA
          v-if="isEditing"
          :icon="$icons.I_DELETE"
          color="error"
          text="Borrar"
          @click="$emit('delete')"
        />
    </v-row>
</template>

<script>
import FinderSelector from '@/components/repository/fields/FinderSelector'
import helpers from '@/plugins/helpers'
import FieldChipGroup
    from '@/components/explorers/processes/process_details/sections/ProcessConfig/fieldTypes/fieldChipGroup'
import types from '@/constants/types'
import ButtonWithIconModelA from '@/components/repository/buttons/ButtonWithIconModelA'
import BitmaskField from "@/components/repository/fields/BitmaskField.vue";

export default {
    name: 'RowPropertyConfig',
    components: {BitmaskField, ButtonWithIconModelA, FieldChipGroup, FinderSelector},
    props: {
        property: Object,
        value: [String, Number, Array, Object],
        alias: String,
        smallDescription: Boolean,
        error: Object,
        isEditing: Boolean
    },
    data() {
        return {
            types,
            internalValue: this.value
        }
    },
    computed: {
        formatValue() {
            let result = this.value

            if (this.alias != null) {
                return this.alias
            }
            if (!helpers.nullOrEmpty(result)) {
                if (this.property.type == types.BOOLEAN) {
                    let style = this.value == 0 ? 'error' : 'success'
                    let text = this.value == 0 ? 'Desactivado' : 'Activado'
                    let icon = this.value == 0 ? 'clear' : 'check'

                    return `<span class="${style}--text">
                    <i aria-hidden="true" class="v-icon notranslate pb-1 material-icons theme--light ${style}--text" style="font-size: 16px;">${icon}</i>
                    ${text}
                  </span>`
                }
                if (this.property.type == types.CUSTOM_CHIP_GROUP) {
                    let item = this.property.extra.items.find(i => i.code == result)
                    return `<span class="${item.color}--text">
                    <i aria-hidden="true" class="v-icon notranslate pb-1 material-icons theme--light ${item.color}--text" style="font-size: 16px;">${item.icon}</i>
                    ${item.text}
                  </span>`
                }

                if (this.property.extra != null && this.property.extra.subtype == types.COLOR) {
                    return '<div class="row"><div class="mr-1" style="border:solid 1px black;width: 16px;height: 16px;background-color: ' + this.internalValue + '"></div><div>' + result + '</div></div>'
                }
            } else {
                return '<span class="contrast_medium--text">Vacío</span>'
            }
            if (this.property.extra != null && this.property.extra.unit != null) {
                result += ' ' + this.property.extra.unit
            }
            return result
        }
    },
    watch: {
        value(value) {
            this.internalValue = value

        },
        isEditing() {
            if (!helpers.nullOrEmpty(this.value)) {
                this.internalValue = this.value
            }
        },
        internalValue(val) {
            this.$emit('change', {[this.property.id]: val})
        }
    }
}
</script>

<style lang="scss">
@import "src/assets/styles/resources/colors";
@import "src/assets/styles/resources/variables";

.cb-row-property {
    border-bottom: 1px solid $color_app_contrast_medium_low;
    min-height: 76px;
    padding: 4px 8px;
}

.cb-row-property:last-child {
    border-bottom: none;
}

.cb-input-color input {
    padding: 0px 4px 0px 0px !important;
    cursor: pointer;
}
</style>
