<template>
  <v-col
    ref="variables"
    class="pa-0"
  >
    <v-data-table
      :headers="headers"
      :items="datatableItems"
      class="ac-instance-events-table ac-simple-table"
      dense
      disable-pagination
      hide-default-footer
      sort-by="timestamp">
      <template v-slot:item="{ item }">
        <tr class="rowCopy">
          <td style="word-break: break-all">
                        <span class="small font-weight-medium">
                            {{item.name}}
                        </span>
          </td>
          <td>
            <v-col
              v-if="checkVarType(item)=='json'"
              class="pa-0 pb-2 mt-2"
            >
              <v-jsoneditor
                v-model="item.value"
                :options="{mode:'code',mainMenuBar : false,statusBar : false}"
                height="auto"
              />
            </v-col>
            <v-col
              v-else-if="checkVarType(item).indexOf('file') != -1"
              class="pa-0"
            >
              <v-row>
                <v-col cols="9" style="overflow: hidden;word-break: break-all">
                                    <span>
                                        {{
                                        item.value
                                      }}

                                        </span>
                </v-col>
                <v-col class="my-auto" cols="3">
                  <v-btn
                    block
                    color="primary"
                    depressed
                    small
                    title="Descargar fichero"
                    @click="downloadResource(item)">
                    <v-icon color="white">download</v-icon>
                    <span class="white--text">Descargar</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-else
              class="pa-0 "
              style="position: relative"
            >
              <span class="cb-var-value">{{item.value}}</span>
              <v-icon
                class="iconCopy"
                color="accent"
                @click="copyVal(item.value)"
              >copy_all
              </v-icon>
            </v-col>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import helpers from '@/plugins/helpers'
import service_tools from '@/services/service_tools'
import service_instances from '@/services/service_instances'

export default {
  name: 'VarsViewer',
  components: {},
  props: {
    instance_code: String,
    variables: Object
  },
  data() {
    return {
      headers: [
        {
          text: 'Variable',
          value: 'name',
          sortable: true,
          width: '25%',
        }, {
          text: 'Valor',
          value: 'value',
          sortable: true,
          width: '75%',
        },
      ]
    }
  },
  computed: {
    datatableItems() {
      let result = []

      Object.keys(this.variables).forEach(v => {
        result.push({
          name: v,
          value: this.variables[v]
        })
      })
      return result
    }
  },
  methods: {
    checkVarType(variable) {
      let value = variable.value
      let type = 'default'
      if (!helpers.nullOrEmpty(value)) {
        if (typeof value == 'boolean') {
          type = 'bool'
        } else if (typeof value == 'number') {
          type = 'number'
        } else if (typeof value == 'object' || helpers.isJson(value)) {
          type = 'json'
        } else if (variable.name.indexOf('document_s3_key') != -1) {
          type = 'file_s3'
        } else if (value.indexOf('/var/spool/') != -1) {
          type = 'file_filebus'
        }
      }
      return type
    },
    downloadResource(variable) {
      let value = variable.value
      let type = this.checkVarType(variable)
      if (type.indexOf('filebus') != -1) {
        service_instances.getResourceFile(this.instance_code, value)
      } else {
        service_tools.downloadFromS3(value)
      }
    },
    copyVal(variable) {

      let container = this.$refs.variables
      let text = variable
      if (typeof text == 'object') {
        text = JSON.stringify(text)
      }
      if (typeof text != 'string') {
        text = text.toString()
      }
      try {

        this.$copyText(text, container).then(function () {
          helpers.setAlertMessage(0, 'Variable copiada')
        }, function () {
          helpers.setAlertMessage(1, 'Variable no copiada')
        })
      } catch (e) {
        helpers.setAlertMessage(1, 'Variable no copiada')
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../../../assets/styles/resources/colors";

.cb-var-value {
  word-break: break-all;
}

.cb-simple-list .v-avatar {
  margin: 0 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.cb-simple-list .v-list-item {
  padding-left: 0;
}

.cb-simple-list .v-avatar i {
  font-size: 20px !important;
}

.rowCopy .iconCopy {
  display: none;
  position: absolute;
  right: 0;
}

.rowCopy:hover .iconCopy {
  display: inline;
}
</style>
