<template>
  <v-container
    v-if="!loading"
  >
    <right-sidebar-standard-row
      v-for="(row, index) in standardRowsTop"
      :key="index"
      :color="row.color"
      :link="row.link"
      :name="row.name"
      :value="row.value"
      @linkLaunched="linkLaunched"
    />

    <v-alert
      v-if="processAlert"
      :type="processAlert.type"
      class="my-2"
      dense
      icon="warning"
    >
      {{ processAlert.message }}
    </v-alert>
    <v-divider class="mt-2"/>
    <v-container class="pa-0">
      <v-tabs
        v-model="tab"
        class="ac-sidebar-tabs"
        grow
      >
        <v-tab
          key="overview"
          href="#overview"
        >Información
        </v-tab>
        <v-tab
          key="callbacks"
          href="#callbacks"
        >Callbacks
        </v-tab>
      </v-tabs>
    </v-container>
    <v-tabs-items
      v-model="tab"
      continuous
    >
      <v-tab-item
        v-if="tab=='overview'"
        value="overview"
      >
        <v-container>
          <card-info-section-with-icon
            :icon="$icons.I_OVERVIEW"
            :rows="overviewProperties"
            title="Información general"
          />
          <v-divider class="my-2"></v-divider>
          <card-info-section-with-icon
            :icon="$icons.I_CERTIFICATE"
            :rows="certificationProperties"
            title="Declaración de finalización"
          />
          <v-divider class="my-2"></v-divider>
          <card-info-section-with-icon
            :icon="$icons.I_PROVIDERS"
            :rows="providersProperties"
            title="Proveedores"
          />
          <v-divider class="my-2"></v-divider>
          <card-info-section-with-icon
            :icon="$icons.I_CALLBACKS"
            :rows="apiProperties"
            title="Api, eventos y callbacks"
          />
        </v-container>
      </v-tab-item>

      <v-tab-item
        v-if="tab=='callbacks'"
        value="callbacks"
      >
        <v-container>
          <callback-list
            :preview="true"
            :process_code="code"
          />
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
  <loading-container
    v-else
    :small-screen="true"
  />
</template>

<script>
import LoadingContainer from '@/components/repository/LoadingContainer.vue'
import RightSidebarStandardRow from '@/components/global_app/rightSidebarPreview/rightSidebarStandardRow.vue'
import service_processes from '@/services/service_processes'
import model_process from '@/constants/models/model_process'
import CallbackList from '@/components/explorers/callbacks/callback_list/CallbackList.vue'
import service_templates from '@/services/service_templates'
import service_instances from '@/services/service_instances'
import model_instance from '@/constants/models/model_instance'
import CardInfoSectionWithIcon from '@/components/repository/cards/CardInfoSectionWithIcon.vue'
import service_protocol from '@/services/service_protocol'
import model_certificate_issuer from '@/constants/models/protocol/model_certificate_issuer'
import service_masteraccounts from '@/services/service_masteraccounts'


export default {
  name: 'rightSidebarSectionProcess',
  components: {
    CardInfoSectionWithIcon,
    CallbackList,
    RightSidebarStandardRow,
    LoadingContainer
  },
  props: {
    code: String
  },
  data() {
    return {
      model_process,
      tab: 'overview',
      loading: true,
      jsonView: false,
      process: null,
      transactionCount: null,
      transactionFindTime: null,
      lastTransaction: null,
      processCallbacksCount: null,
      certificateIssuer: null,
      processEvents: null,
      panel: [0, 1]
    }
  },
  computed: {
    standardRowsTop() {
      let instanceInfo = 'Cargando'
      if (this.transactionCount != null) {
        instanceInfo = '<span class="font-weight-medium">' + this.transactionCount + ' inst. </span> <span class="font-italic">(' + this.transactionFindTime.toFixed(4) + 's)</span>'
      }
      if (this.lastTransaction != null) {
        instanceInfo += '<span class="font-italic ml-2">Última ' + this.$moment.unix(this.lastTransaction[model_instance.CREATION]).from() + '</span>'
      }

      return [
        {
          name: 'Cuenta raíz',
          value: this.process[model_process.MASTER_ACCOUNT_NAME] + ' (' + this.process[model_process.MASTER_ACCOUNT_CODE] + ')',
          link: 'masteraccount',
        },
        {
          name: 'Plantilla',
          value: this.process[model_process.TEMPLATE_CODE],
          link: 'template',
        },
        {
          name: 'Instancias últ mes',
          value: instanceInfo
        },
      ]
    },
    overviewProperties() {
      return [
        {
          name: 'Apariencia',
          value: this.process[model_process.APPEARANCE_CODE]
        },
        {
          name: 'Idioma por defecto',
          value: this.process[model_process.DEFAULT_LANGUAGE]
        },
        {
          name: '¿Activo en portal B2B?',
          value: this.process[model_process.IS_B2B] ? 'Sí' : 'No',
          color: this.process[model_process.IS_B2B] ? 'success' : 'error',
        },
        {
          name: 'Autenticación',
          value: this.process[model_process.AUTHENTICATION_METHOD],
          color: this.process[model_process.AUTHENTICATION_METHOD] != 'NONE' ? 'success' : 'error',
          alert: this.process[model_process.AUTHENTICATION_METHOD] == 'NONE',
        },
      ]
    },
    certificationProperties() {
      let issuer = this.certificateIssuer ?? 'Cargando...'
      return [
        {
          name: 'Entidad proveedora',
          value: this.process[model_process.CERTIFICATE_PROVIDER],
          error: this.process[model_process.CERTIFICATE_PROVIDER] == null
        },
        {
          name: 'TSA',
          value: this.process[model_process.TSA_PROVIDER]
        },
        {
          name: 'Sellos de tiempo',
          value: this.process[model_process.SEAL_PROVIDER]
        },
        {
          name: 'Empresa emisora',
          value: issuer,
          alert: issuer == 'Sin empresa emisora' && issuer != 'Cargando...'
        },
        {
          name: '¿Textos cualificados?',
          value: this.process[model_process.QUALIFIED_TEXTS] == 1 ? 'Sí' : 'No',
          color: this.process[model_process.QUALIFIED_TEXTS] == 1 ? 'success' : 'error',
        },
        {
          name: 'Formatos PDF (cert./eve.)',
          value: this.process[model_process.FORMAT_PDF_CERTIFICATES] + '/' + this.process[model_process.FORMAT_PDF_EVENTS]
        },
        {
          name: 'Idioma de la DF',
          value: this.process[model_process.CERTIFICATE_LANGUAGE]
        }
      ]
    },
    apiProperties() {

      return [
        {
          name: 'Eventos activos',
          value: this.processEvents ? this.processEvents.eventsActive.length + '/' + this.processEvents.events.length : 'Cargando..'
        },
        {
          name: 'Nº de Callbacks',
          value: this.processCallbacksCount ?? 'Cargando..'
        },
        {
          name: 'Api de intromisión',
          value: this.process[model_process.API_INTROMISSION] != 0 ? 'Activado' : 'Desactivado',
          color: this.process[model_process.API_INTROMISSION] != 0 ? 'success' : 'error',
        },
        {
          name: 'Api de consulta',
          value: this.process[model_process.API_ENQUIRY] != 0 ? 'Activado' : 'Desactivado',
          color: this.process[model_process.API_ENQUIRY] != 0 ? 'success' : 'error',
        },
        {
          name: 'Communicalia Sync.',
          value: this.process[model_process.COMMUNICALIA_SYNC] != 0 ? 'Activado' : 'Desactivado',
          color: this.process[model_process.COMMUNICALIA_SYNC] != 0 ? 'success' : 'error',
        },
        {
          name: 'Callbacks en cambio de macroestados',
          value: this.process[model_process.MACROSTATUS_CALLBACKS] != 0 ? 'Activado' : 'Desactivado',
          color: this.process[model_process.MACROSTATUS_CALLBACKS] != 0 ? 'success' : 'error',
        },
      ]
    },
    providersProperties() {
      let emailValue = this.process[model_process.EMAIL_ENTITY] ?? 'Sin proveedor'
      let smsValue = this.process[model_process.SMS_PROVIDER] ?? 'Sin proveedor'

      let emailCT = this.process[model_process.COMMSTRACKER_EMAIL] ?? 'Sin dominio'
      let smsCT = this.process[model_process.COMMSTRACKER_SMS] ?? 'Sin dominio'


      return [
        {
          name: 'Email',
          value: emailValue,
          color: this.process[model_process.EMAIL_ENTITY] ? '' : 'error',
        },
        {
          name: 'SMS',
          value: smsValue,
          color: this.process[model_process.SMS_PROVIDER] ? '' : 'error',
        },
        {
          name: 'Commstracker (email/sms)',
          value: emailCT + '/' + smsCT
        },
        {
          name: 'Sobre postal',
          value: this.process[model_process.POSTAL_ENVELOPE] ?? 'Sin sobre',
          alert: (this.process[model_process.SMS_PROVIDER] != null && this.process[model_process.COMMSTRACKER_SMS] == null) || this.process[model_process.EMAIL_ENTITY] != null && this.process[model_process.COMMSTRACKER_EMAIL] == null,
          color: this.process[model_process.POSTAL_ENVELOPE] ? 'success' : 'error',
        }
      ]
    },
    processAlert() {
      return null
    },
  },
  watch: {},
  async created() {
    await this.getProcessInfo()
    this.getTransactionCount()
    this.getCallbacksCount()
  },
  methods: {
    async getProcessInfo() {
      this.loading = true
      let process = await service_processes.getProcessInfo(this.code)
      this.process = process.data.extra
      this.loading = false
      service_processes.getRelevantStatuses(this.process[model_process.CODE]).then(data => {
        this.processEvents = data
      })
      service_protocol.getCertificateIssuers([model_certificate_issuer.ID, model_certificate_issuer.NAME], { [model_certificate_issuer.ID]: this.process[model_process.CERTIFICATE_ISSUER] }).then(data => {
        this.certificateIssuer = data.results.length != 0 ? data.results[0][model_certificate_issuer.NAME] : 'Sin empresa emisora'
      })
    },
    getCallbacksCount() {
      service_processes.getProcessCallbacks(this.process[model_process.CODE]).then(data => {
        this.processCallbacksCount = data.callbacks.length
      })
    },
    getTransactionCount() {
      service_instances.findInstances([model_instance.MASTER_ACCOUNT_CODE, model_instance.PROCESS_CODE, model_instance.CREATION], {
        [model_instance.CREATION]: { 'type': 'last_month' },
        [model_instance.MASTER_ACCOUNT_CODE]: this.process[model_process.MASTER_ACCOUNT_CODE],
        [model_instance.PROCESS_CODE]: this.process[model_process.CODE]
      }, {
        'rpp': 1,
        'sortDesc': [false],
        'page': 1,
        'sortBy': [],
        'order': {}
      }).then(data => {
        this.transactionCount = data.count
        this.lastTransaction = data.results[0]
        this.transactionFindTime = data.time
      })
    },
    linkLaunched(item) {
      if (item.link == 'template') {
        service_templates.openTemplate(item.value)
      } else if (item.link == 'masteraccount') {
        service_masteraccounts.openMasterAccount(this.process[model_process.MASTER_ACCOUNT_CODE], this.process[model_process.MASTER_ACCOUNT_NAME])
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../../assets/styles/resources/colors";

.ac-section-title {
  border-bottom: 1px solid $color_app_primary;
  padding: 4px;
}

.ac-section-title span {
}
</style>