export const P_LOGS = 'logs'
export const P_EXPORT = 'export'
export const P_DOWNLOAD_FILES = 'download_files'
export const P_ACCOUNT = 'account'
export const P_INSTANCES = 'instances'
export const P_INSTANCE_EVENT_DETAILS = 'ins_event_details'
export const P_INSTANCE_DOCUMENT = 'ins_document'
export const P_INSTANCE_RESOURCES = 'ins_resources'
export const P_INSTANCE_REDIS = 'ins_redis'
export const P_INSTANCE_CALLBACKS = 'ins_callbacks'
export const P_INSTANCE_API = 'ins_api'
export const P_INSTANCE_DOWNLOAD_CERTIFICATE = 'ins_download_certificate'
export const P_INSTANCE_DOWNLOAD_BACKUP = 'ins_download_backup'
export const P_TEMPLATES = 'templates'
export const P_TEMPLATE_HISTORY = 'template_history'
export const P_TEMPLATE_EDIT = 'template_edit'
export const P_PROCESSES = 'processes'
export const P_PROCESS_CALLBACKS = 'proc_callbacks'
export const P_PROCESS_RESOURCES = 'proc_resources'
export const P_PROCESS_RESOURCE_VARIABLE_EDIT = 'proc_resource_variable_edit'
export const P_PROCESS_RESOURCE_FILE_EDIT = 'proc_resource_file_edit'
export const P_PROCESS_LANGUAGES_EDIT = 'proc_languages_edit'
export const P_PROCESS_LAUNCH_TEST = 'proc_launch_test'
export const P_PROCESS_LAUNCH_CHANGE_VERSION = 'proc_launch_change_version'
export const P_PROCESS_CONFIG = 'proc_config'
export const P_PROCESS_CONFIG_PROVIDERS = 'proc_config_providers'
export const P_PROCESS_CONFIG_B2B = 'proc_config_b2b'
export const P_PROCESS_CONFIG_API = 'proc_config_api'
export const P_PROCESS_CONFIG_SECURITY = 'proc_config_security'
export const P_PROCESS_CONFIG_EVENTS = 'proc_config_events'
export const P_PROCESS_CONFIG_CALLBACKS = 'proc_config_callbacks'
export const P_PROCESS_DOWNLOAD_DOCUMENTATION = 'proc_download_documentation'
export const P_PROCESS_DOWNLOAD_SWAGGER = 'proc_download_swagger'
export const P_ADMINISTRATION = 'administration'
export const P_MONITORING_ALERTS = 'mon_alerts'
export const P_MONITORING_ALERT_RETRY = 'mon_alert_retry'
export const P_MONITORING_ALERT_SOFT_REJECT_DELETE = 'mon_alert_soft_reject_delete'
export const P_MONITORING_ALERT_ERROR_HANDLER_DELETE = 'mon_alert_error_handler_delete'
export const P_MONITORING_ALERT_HARD_REJECT_DELETE = 'mon_alert_hard_reject_delete'
export const P_MONITORING_ALERT_REDIS_RETRY = 'mon_alert_redis_retry'
export const P_MONITORING_ALERT_REDIS_DELETE = 'mon_alert_redis_delete'
export const P_MONITORING_STATS = 'mon_stats'
export const P_MONITORING_PROVIDERS = 'mon_providers'
export const P_MONITORING_HOSTS = 'mon_hosts'
export const P_MONITORING_HOSTS_STOP_WORKERS = 'mon_hosts_stop_workers'
export const P_B2B_USERS_ACTIONS = 'b2b_users_actions'
export const P_PROTOCOL_ACCOUNTS = 'msmdp_accounts'
export const P_PROTOCOL_ACCOUNTS_EDIT = 'msmdp_accounts_edit'
export const P_PROTOCOL_PROVIDERS = 'msmdp_providers'
export const P_PROTOCOL_PROVIDERS_EMAIL = 'msmdp_providers_email'
export const P_PROTOCOL_PROVIDERS_POSTAMAIL = 'msmdp_providers_postamail'
export const P_PROTOCOL_PROVIDERS_SMS = 'msmdp_providers_sms'

export const P_PROTOCOL_BILLING_UNITS_EDIT = 'msmdp_accounts_billing_units_edit'
export const P_PROTOCOL_EMAIL_CHECKER = 'msmdp_email_checker'
export const P_PROTOCOL_EMAIL_CHECKER_EDIT = 'msmdp_email_checker_edit'


export const P_PROTOCOL_ACCOUNTS_B2B_USERS = 'msmdp_accounts_b2b_users'
export const P_PROTOCOL_ACCOUNTS_B2B_GROUPS = 'msmdp_accounts_b2b_groups'
export const P_PROTOCOL_ACCOUNTS_BILLING_UNITS = 'msmdp_accounts_billing_units'
export const P_PROTOCOL_ACCOUNTS_B2B_USERS_EDIT = 'msmdp_accounts_b2b_users_edit'
export const P_PROTOCOL_ACCOUNTS_B2B_GROUPS_EDIT = 'msmdp_accounts_b2b_groups_edit'
export const P_PROTOCOL_ACCOUNTS_BILLING_UNITS_EDIT = 'msmdp_accounts_billing_units_edit'
export const P_PROTOCOL_ACCOUNTS_EDIT_POSTAMAIL = 'msmdp_accounts_edit_postamail'
export const P_PROTOCOL_ACCOUNTS_EDIT_B2B = 'msmdp_accounts_edit_b2b'
export const P_PROTOCOL_SUBACCOUNTS = 'msmdp_subaccounts'
export const P_PROTOCOL_SUBACCOUNTS_EDIT = 'msmdp_subaccounts_edit'
export const P_PROTOCOL_SUBACCOUNTS_EDIT_PROJECTS = 'msmdp_subaccounts_edit_projects'
export const P_PROTOCOL_ACCOUNTS_ALASTRIA = 'msmdp_accounts_alastria'

export const P_PROTOCOL_FILE_EXPLORER = 'msmdp_file_explorer'
export const P_PROTOCOL_FILE_EXPLORER_EDIT = 'msmdp_file_explorer_edit'
export const P_PROTOCOL_BILLING = 'msmdp_billing'
export const P_PROTOCOL_BILLING_PROVIDERS = 'msmdp_billing_providers'
export const P_PROTOCOL_BILLING_CUSTOM_LAUNCH = 'msmdp_billing_custom_launch'
export const P_PROTOCOL_ENTITIES = 'msmdp_entities'
export const P_PROTOCOL_ENTITIES_COMMSTRACKER = 'msmdp_entities_commstracker'
export const P_PROTOCOL_ENTITIES_POSTAL_ENVELOPES = 'msmdp_entities_postal_envelopes'
export const P_PROTOCOL_ENTITIES_CERTIFICATE_ISSUERS = 'msmdp_entities_certificate_issuers'
export const P_PROTOCOL_SCHEDULE_TASKS = 'msmdp_schedule_tasks'
export const P_PROTOCOL_SCHEDULE_TASKS_EDIT = 'msmdp_schedule_tasks_edit'
export const P_PROTOCOL_TICKER = 'msmdp_ticker'
export const P_PROTOCOL_GLOBAL_VARIABLES = 'msmdp_global_variables'
export const P_PROTOCOL_GLOBAL_VARIABLES_EDIT = 'msmdp_global_variables_edit'
export const P_PROTOCOL_ENTITIES_POSTAL_ENVELOPES_EDIT = 'msmdp_entities_postal_envelopes_edit'
export const P_PROTOCOL_ENTITIES_CERTIFICATE_ISSUERS_EDIT = 'msmdp_entities_certificate_issuers_edit'
export const P_PROTOCOL_ENTITIES_APPEARANCES = 'msmdp_entities_appearances'
export const P_PROTOCOL_ENTITIES_APPEARANCES_EDIT = 'msmdp_entities_appearances_edit'
export const P_MONITORING_NOTIFICATIONS = 'mon_notifications'
export const P_PROTOCOL_PROVIDERS_EDIT = 'msmdp_providers_edit'
export const P_PROTOCOL_NOTARIAL_DEPOSIT = 'msmdp_notarial_deposit'//'msmdp_notarial_deposit'
export const P_PROTOCOL_NOTARIAL_DEPOSIT_UPDATE = 'msmdp_notarial_deposit_update_record'
export const P_MONITORING_BATCH_UPLOADS = 'batch_uploader_view'

export const P_COMMSERVICES_USERS_LIST = 'comms_users'
export const P_COMMSERVICES_USERS_EDIT = 'comms_users_edit'
export const P_COMMSERVICES_COMMSTRACKER_LIST = 'comms_commstracker'
export const P_COMMSERVICES_COMMSTRACKER_EDIT = 'comms_commstracker_edit'
export const P_COMMSERVICES_EXCLUSION_LIST = 'comms_exclusion_list'
export const P_COMMSERVICES_EXCLUSION_LIST_EDIT = 'comms_exclusion_list_edit'

export const P_MANAGEMENT_DOCUMENT_PROCESS = 'm_pkc'
export const P_MANAGEMENT_DOCUMENT_PROCESS_EDIT = 'm_pkc_edit'
export const P_MANAGEMENT_DOCUMENT_PROCESS_CHECKLIST = 'm_pkc_checklist'
export const P_MANAGEMENT_DOCUMENT_PROCESS_CHECKLIST_EDIT = 'm_pkc_checklist_edit'
export const P_MANAGEMENT_DOCUMENT_PROCESS_ASSETS = 'm_pkc_assets'
export const P_MANAGEMENT_DOCUMENT_PROCESS_ASSETS_EDIT = 'm_pkc_assets_edit'
export const P_MANAGEMENT_DOCUMENT_PROCESS_BRIEFING = 'm_pkc_briefing'
export const P_MANAGEMENT_DOCUMENT_PROCESS_BRIEFING_EDIT = 'm_pkc_briefing_edit'

export const P_SHOW_SIDEBAR = 'show_sidebar'

export function getGroupPermissionsFromCategory(category) {
  switch (category) {
    case 'global': {
      return [
        P_LOGS,
        P_EXPORT,
        P_DOWNLOAD_FILES,
        P_ACCOUNT,
        P_ADMINISTRATION,
        P_SHOW_SIDEBAR
      ]
    }
    case 'monitoring' : {
      return [
        P_MONITORING_ALERTS,
        P_MONITORING_STATS,
        P_MONITORING_PROVIDERS,
        P_MONITORING_HOSTS,
        P_MONITORING_NOTIFICATIONS,
        P_MONITORING_BATCH_UPLOADS
      ]
    }
    case 'operations' : {
      return [
        P_INSTANCES,
        P_TEMPLATES,
        P_PROCESSES,
        P_PROTOCOL_ACCOUNTS,
      ]
    }
    case 'commservices' : {
      return [
        P_COMMSERVICES_USERS_LIST,
        P_COMMSERVICES_USERS_EDIT,
        P_COMMSERVICES_COMMSTRACKER_LIST,
        P_COMMSERVICES_COMMSTRACKER_EDIT,
        P_COMMSERVICES_EXCLUSION_LIST,
        P_COMMSERVICES_EXCLUSION_LIST_EDIT
      ]
    }
    case 'management' : {
      return [
        P_MANAGEMENT_DOCUMENT_PROCESS,
        P_MANAGEMENT_DOCUMENT_PROCESS_EDIT,
        P_MANAGEMENT_DOCUMENT_PROCESS_CHECKLIST,
        P_MANAGEMENT_DOCUMENT_PROCESS_CHECKLIST_EDIT,
        P_MANAGEMENT_DOCUMENT_PROCESS_ASSETS,
        P_MANAGEMENT_DOCUMENT_PROCESS_ASSETS_EDIT,
        P_MANAGEMENT_DOCUMENT_PROCESS_BRIEFING,
        P_MANAGEMENT_DOCUMENT_PROCESS_BRIEFING_EDIT
      ]
    }
    case 'protocol' : {
      return [
        P_PROTOCOL_PROVIDERS,
        P_PROTOCOL_ACCOUNTS_BILLING_UNITS,
        P_PROTOCOL_SUBACCOUNTS,
        P_PROTOCOL_ENTITIES,
        P_PROTOCOL_BILLING,
        P_PROTOCOL_FILE_EXPLORER,
        P_PROTOCOL_GLOBAL_VARIABLES,
        P_PROTOCOL_SCHEDULE_TASKS,
        P_PROTOCOL_TICKER,
        P_PROTOCOL_NOTARIAL_DEPOSIT,
        P_PROTOCOL_PROVIDERS_EDIT
      ]
    }
  }
}


export default {
  P_LOGS,
  P_EXPORT,
  P_DOWNLOAD_FILES,
  P_ACCOUNT,
  P_INSTANCES,
  P_INSTANCE_EVENT_DETAILS,
  P_INSTANCE_DOCUMENT,
  P_INSTANCE_RESOURCES,
  P_INSTANCE_REDIS,
  P_INSTANCE_CALLBACKS,
  P_INSTANCE_API,
  P_INSTANCE_DOWNLOAD_CERTIFICATE,
  P_INSTANCE_DOWNLOAD_BACKUP,
  P_TEMPLATES,
  P_TEMPLATE_HISTORY,
  P_TEMPLATE_EDIT,
  P_PROCESSES,
  P_PROCESS_CALLBACKS,
  P_PROCESS_RESOURCES,
  P_PROCESS_RESOURCE_VARIABLE_EDIT,
  P_PROCESS_RESOURCE_FILE_EDIT,
  P_PROCESS_LANGUAGES_EDIT,
  P_PROCESS_LAUNCH_TEST,
  P_PROCESS_LAUNCH_CHANGE_VERSION,
  P_PROCESS_CONFIG,
  P_PROCESS_CONFIG_PROVIDERS,
  P_PROCESS_CONFIG_B2B,
  P_PROCESS_CONFIG_API,
  P_PROCESS_CONFIG_SECURITY,
  P_PROCESS_CONFIG_EVENTS,
  P_PROCESS_CONFIG_CALLBACKS,
  P_PROCESS_DOWNLOAD_DOCUMENTATION,
  P_PROCESS_DOWNLOAD_SWAGGER,
  P_ADMINISTRATION,
  P_MONITORING_ALERTS,
  P_MONITORING_ALERT_RETRY,
  P_MONITORING_STATS,
  P_MONITORING_PROVIDERS,
  P_MONITORING_HOSTS,
  P_MONITORING_HOSTS_STOP_WORKERS,
  P_B2B_USERS_ACTIONS,
  P_PROTOCOL_ACCOUNTS,
  P_PROTOCOL_ACCOUNTS_EDIT,
  P_PROTOCOL_PROVIDERS,
  P_PROTOCOL_PROVIDERS_EMAIL,
  P_PROTOCOL_PROVIDERS_POSTAMAIL,
  P_PROTOCOL_PROVIDERS_SMS,
  P_PROTOCOL_ACCOUNTS_BILLING_UNITS,
  P_PROTOCOL_BILLING_UNITS_EDIT,
  P_PROTOCOL_BILLING_CUSTOM_LAUNCH,
  P_PROTOCOL_BILLING_PROVIDERS,
  P_MONITORING_BATCH_UPLOADS,
  P_PROTOCOL_PROVIDERS_EDIT,
  P_MONITORING_ALERT_SOFT_REJECT_DELETE,
  P_MONITORING_ALERT_ERROR_HANDLER_DELETE,
  P_MONITORING_ALERT_HARD_REJECT_DELETE,
  P_MONITORING_ALERT_REDIS_RETRY,
  P_MONITORING_ALERT_REDIS_DELETE,
  getGroupPermissionsFromCategory
}
