export const EXPLORER_CHANGE_SECTION = 'explorer_change_section'
export const EXPLORER_OPEN_FILTERS = 'explorer_open_filters'
export const EXPLORER_OPEN_VISUAL_OPTIONS = 'explorer_open_visual_options'
export const EXPLORER_OPEN_ACTIONS = 'explorer_open_actions'
export const EXPLORER_REMOVE_FILTER = 'explorer_remove_filter'
export const EXPLORER_SEARCH = 'explorer_search'
export const EXPLORER_FILTER_FAV = 'explorer_filter_fav'
export const EXPLORER_ACTION_CLICKED = 'explorer_action_clicked'
export const EXPLORER_VIEW_CLICKED = 'explorer_view_clicked'

export const DATA_TABLE_REFRESH = 'data_table_refresh'
export const DATA_TABLE_CHANGE_PAGE = 'data_table_change_page'
export const DATA_TABLE_ACTION_CLICKED = 'data_table_action_clicked'
export const DATA_TABLE_OPEN = 'data_table_open'
export const DATA_TABLE_EDIT = 'data_table_edit'

export const B2B_CONFIG_CHANGE = 'b2b_config_change'

export default {
    EXPLORER_CHANGE_SECTION,
    EXPLORER_OPEN_FILTERS,
    EXPLORER_OPEN_VISUAL_OPTIONS,
    EXPLORER_OPEN_ACTIONS,
    EXPLORER_REMOVE_FILTER,
    EXPLORER_SEARCH,
    EXPLORER_FILTER_FAV,
    EXPLORER_ACTION_CLICKED,
    EXPLORER_VIEW_CLICKED,

    DATA_TABLE_REFRESH,
    DATA_TABLE_CHANGE_PAGE,
    DATA_TABLE_OPEN,
    DATA_TABLE_EDIT,
    DATA_TABLE_ACTION_CLICKED,

    B2B_CONFIG_CHANGE,
}
