<template>
  <v-dialog
    v-model="open"

    transition="dialog-bottom-transition"
    width="1000px"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card class="cb-card-dialog cb-card-dialog-bordered">
      <v-card-text class="white pa-4">
        <v-col
          class="pa-0"
        >
          <v-row
            class="ma-0 my-2"
            dense
          >
            <h1 class="cb-text-descriptor text-h5">
              Ayuda de la vista {{ $route.meta.title.toLowerCase() }}
            </h1>
          </v-row>
          <v-divider class="my-2"/>
          <v-row class="">
            <v-col>
              <InstancesHelper v-if="$route.name=='instances'"/>
              <BillingUnitsInfo v-else-if="$route.name=='billing_units'"/>
              <p v-else>No disponible</p>
            </v-col>
          </v-row>
        </v-col>
        <v-row
          class="ma-0 my-2"
          dense
        >
          <h1 class="cb-text-descriptor text-h5">Lista de comandos</h1>
        </v-row>
        <v-divider class="my-2"/>
        <v-row>
          <v-col class="px-4">
            <CarRowInfo
              v-for="(shortKey, key) in Object.entries($shortKeys)"
              :key="key"
              :title="shortKey[1].name"
              :value="shortKey[1].command.join(' + ').toUpperCase()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CarRowInfo from '@/components/repository/cards/CardRowInfo'
import InstancesHelper from '@/components/repository/view_helpers/InstancesHelper'
import BillingUnitsInfo from '@/components/protocol/billingUnits/billing_units_list/components/BillingUnitsInfo'

export default {
  name: 'ShortKeysDialog',
  components: { BillingUnitsInfo, InstancesHelper, CarRowInfo },
  data() {
    return {
      open: true

    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style scoped></style>
