<template>
    <v-container class="pa-0">
        <ExplorerFiltersRow
          v-if="!preview"
          :read-only="instance_code != null ? [model_callback.constants.INSTANCE_CODE] : [model_callback.constants.PROCESS_CODE]"
          :response-filters="responseFilters"
          :search="instance_code == null"
          model="por instancia"
          @[$events.EXPLORER_REMOVE_FILTER]="removeFilter"
          @[$events.EXPLORER_SEARCH]="searchByText"
        />
        <v-row
          class="ma-0"
          dense
        >
            <DataTable
              :custom-row="true"
              :data="dataTableData"
              :data-table-options="dataTableOptions"
              :headers="dataTableHeaders"
              :loading="loading"
              model="callbacks"
              @[$events.DATA_TABLE_REFRESH]="find"
            >
                <template v-slot="{item}">
                    <InstanceCallbacksTableRow
                      :fields="dataTableHeaders"
                      :item="item"
                      :preview="preview"
                      @openCallback="openCallback"
                    />
                </template>
            </DataTable>
        </v-row>
        <ShowJsonDialog
          v-if="callbackParsed != null"
          :json="callbackParsed"
          mode="code"
          @close="closeCallback"
        />
    </v-container>
</template>

<script>
import helpers from '@/plugins/helpers'
import helpers_resolution from '@/plugins/helpers_resolution'

import DataTable from '@/components/repository/tables/DataTable'
import InstanceCallbacksTableRow from '@/components/explorers/callbacks/callback_list/components/callbacksTableRow'
import model_callback from '@/constants/models/protocol/model_callback'
import service_protocol from '@/services/service_protocol'
import ExplorerFiltersRow from '@/components/repository/explorers/ExplorerFiltersRow'
import vuex_protocol from '@/store/modules/sections/vuex_protocol'
import ShowJsonDialog from '@/components/explorers/templates/components/ShowJsonTemplateDialog'

export default {
    name: 'CallbackList',
    components: {
        ShowJsonDialog,
        ExplorerFiltersRow,

        InstanceCallbacksTableRow,
        DataTable
    },
    props: {
        instance_code: String,
        process_code: String,
        preview: Boolean,
    },
    data() {
        return {
            helpers,
            model_callback,
            /* Table */
            dataTableData: null,

            responseFilters: null,
            searchText: null,
            loading: false,
            dataTableHeaders: [
                {
                    text: '',
                    value: model_callback.constants.STATUS_CODE,
                    width: '50px',
                    align: 'center'
                },
                {
                    text: 'Fecha/hora',
                    value: model_callback.constants.TIMESTAMP,
                    sortable: true,
                    width: '20%',
                },
                {
                    text: 'Endpoint',
                    value: model_callback.constants.URL,
                    sortable: true,
                    width: '50%',
                },
                {
                    text: 'Código de instancia',
                    value: model_callback.constants.INSTANCE_CODE,
                    hide: this.instance_code != null,
                    align: this.instance_code != null ? ' d-none' : '',
                    width: this.instance_code != null ? '' : '28%',
                },
                {
                    text: 'Error',
                    value: model_callback.constants.ERROR,
                    hide: true,
                    align: ' d-none',
                },
                {
                    text: 'Evento',
                    value: model_callback.constants.PARAMETERS,
                    sortable: true,
                    width: this.preview ? this.instance_code != null ? ' 70%' : '35%' : this.instance_code != null ? '50%' : '20%',
                },
                {
                    text: '',
                    value: 'actions',
                    width: '40px',
                    align: this.preview ? ' d-none' : 'center'
                }
            ],
            pagination: null,
            resultsTime: null,
            datatableCount: null,
            dataTableOptions: {
                rpp: helpers_resolution.getTableLessRowsPerPage(),
                sortDesc: [true],
                page: 1,
                sortBy: [model_callback.constants.TIMESTAMP]
            },

            itemSelected: null,
        }
    },
    computed: {
        searchFilters: {
            get() {
                return this.$store.state[vuex_protocol.constants.NAMESPACE][vuex_protocol.constants.S_CALLBACKS_FILTERS]
            },
            set(val) {
                this.$store.dispatch(`${vuex_protocol.constants.NAMESPACE}/${vuex_protocol.constants.A_SET_CALLBACKS_FILTERS}`, val)
            }
        },

        callbackParsed() {
            if (this.itemSelected == null) return null
            let parsed = this.itemSelected
            Object.keys(parsed).forEach(key => {
                if (typeof parsed[key] == 'string' && parsed[key].substring(0, 1) == '{') {
                    parsed[key] = JSON.parse(parsed[key])
                }

            })
            return parsed
        },
    },
    created() {
        this.find()
    },
    methods: {
        removeFilter(filter) {
            this.$delete(this.searchFilters, filter)
            this.find()
        },
        searchByText(searchText) {
            this.find(searchText)
        },
        find(instance_code) {
            this.searchFilters = {}
            let instanceCode = this.instance_code != null ? this.instance_code : instance_code
            if (this.process_code != null) {
                this.$set(this.searchFilters, model_callback.constants.PROCESS_CODE, this.process_code)
            }
            if (instanceCode != null) {
                this.$set(this.searchFilters, model_callback.constants.INSTANCE_CODE, instanceCode)
            }
            service_protocol.findCallbacks(this.dataTableHeaders.map(a => a.value), this.searchFilters, this.dataTableOptions).then((extra) => {
                this.dataTableData = extra
                this.responseFilters = extra.filters
                this.loading = false
            })
        },
        /* Table */
        openCallback(callback) {
            this.itemSelected = callback
        },

        closeCallback() {
            this.itemSelected = null
        }
    },
    filters: {}
}
</script>


