<!-- field-chip-group.vue -->
<template>
    <v-row>
        <v-chip
          v-for="(label, bitmask) in items"
          :key="bitmask"
          :input-value="isBitSet(bitmask)"
          class="bitmask-chip"
          color="primary"
          label
          outlined
          small
          @click="toggleBit(bitmask)">
            <v-icon class="mr-1"
                    x-small>
                {{isBitSet(bitmask) ? 'check_circle' : 'clear'}}
            </v-icon>
            <span class="cb-font-size-5">{{label}}
                            </span>
        </v-chip>
    </v-row>
</template>

<script>
import helpers from "@/plugins/helpers";

export default {
    name: 'BitmaskField',
    props: {
        value: Number,
        items: Object,
    },
    data() {
        return {
            helpers
        }
    },
    methods: {
        isBitSet(bitmask) {
            // Comprueba si el bit en la posición 'bitmask' está activado
            return (this.value & bitmask) !== 0;
        },
        toggleBit(bitmask) {
            // Activa o desactiva el bit en la posición 'bitmask'
            const newBitmask = this.value ^ bitmask;
            this.$emit('input', newBitmask);
        },
    },
};
</script>
<style lang="scss">
.bitmask-chip {
    width: 40%;
    margin-bottom: 4px;
    margin-right: 4px;
}
</style>