<template>
  <v-dialog
    v-model="show"
    inset
    scrollable
    width="900px"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card
      class="cb-filter-card"
    >
      <card-title
        :close="true"
        icon="menu"
        title="Exportar datos de tabla"
        @close="$emit('close')"
      />
      <v-card-text class="pa-4">
        <ExportTableContent
          :fields="fields"
          :model="model"
          :response_filters="response_filters"
          :service="service"
          @close="$emit('close')"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import service_user from '@/services/service_user'
import CardTitle from '@/components/repository/cards/CardTitle'
import ExportTableContent from '@/components/repository/tables/dataTable/ExportTableContent'
import { P_EXPORT } from '@/constants/group_permissions'

export default {
  name: 'ExportDataTable',
  components: {
    ExportTableContent,
    CardTitle
  },
  props: {
    loading: Boolean,
    fields: Array,
    response_filters: [Object, Array],
    model: String,
    service: Function
  },
  data() {
    return {
      P_EXPORT,
      service_user,
      show: true,
    }
  }
}
</script>
