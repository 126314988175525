<template>
    <v-col class="pa-0 pt-2">

        <v-timeline
          :class="{'cb-events-timeline-compact': small}"
          class="cb-events-timeline pt-0"
          dense
        >
            <v-row
              v-for="eventDay in Object.keys(events)"
              :key="eventDay"
              class="ma-0"
              dense
            >
                <v-col class="pa-0 pb-2">
                    <v-timeline-item
                      class="mb-0 cb-timeline-date-container"
                      color="transparent"
                      hide-dot
                      right
                    >
                        <div
                          slot="opposite"
                          class="cb-timeline-date"
                        >
                            <span class="font-weight-medium">{{eventDay.split(' ')[1]}}</span>
                            <span
                              v-if="!small"
                              class="mx-1"
                            >|</span>
                            <span
                              v-if="!small"
                              class=""
                            >{{eventDay.split(' ')[0]}}</span>
                        </div>
                    </v-timeline-item>
                    <v-timeline-item
                      v-for="(eventSecond,index) in Object.keys(events[eventDay])"
                      :key="index"
                      class="mb-0"
                      color="transparent"
                      fill-dot
                      icon="arrow_right"
                      icon-color="contrast_medium"
                      small
                    >
                        <v-row
                          slot="opposite"
                          class="ma-0 justify-end"
                          dense
                        >
                            {{eventSecond}}
                        </v-row>
                        <v-row
                          v-for="(event,index) in events[eventDay][eventSecond]"
                          :key="index"
                          :class="{'mb-4': !small, 'cb-event-row-compact': small, 'cb-event-selected': small &&eventSelected && eventSelected.evidence_id == event.evidence_id}"
                          class="ma-0 cb-timeline-event-row"
                          dense
                        >
                            <v-col
                              class="pa-0"
                              style="position:relative;"
                            >
                                <v-tooltip
                                  v-if="getEventTreatment(event.step_event) != null"
                                  left
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                          :color="getEventTreatment(event.step_event).color"
                                          class="cb-event-icon-timeline"
                                          v-on="on"
                                        >
                                            {{getEventTreatment(event.step_event).icon}}
                                        </v-icon>
                                    </template>
                                    <span class="text-capitalize">{{getEventTreatment(event.step_event).name}}</span>
                                </v-tooltip>

                                <v-row>
                                    <v-col class="pa-0 my-auto">
                                        <v-row
                                          v-if="!small"
                                          class=" ma-0"
                                          dense
                                        >
                                            <a
                                              v-if="service_user.checkGroupPermission(P_INSTANCE_EVENT_DETAILS)"
                                              @click="openEventInfo(event)"
                                            >
                            <span class="font-weight-medium text-uppercase "
                                  title="Abrir información del evento"> {{event.step_event.split('.')[0]}}
                          </span>
                                            </a>
                                            <span
                                              v-else
                                              class="font-weight-medium text-uppercase bg_dark--text"
                                            >{{
                                                    event.step_event.split('.')[0]
                                                }}</span>
                                            <span class="mx-1 my-auto">|</span>
                                            <span
                                              :style="'color:'+getEventColor(event.event_code)"
                                              class="my-auto font-weight-medium"
                                            > {{event.step_event.split('.')[1]}}</span>
                                        </v-row>
                                        <v-row
                                          v-else
                                          class="ma-0 pa-1 cb-item-event"
                                          dense
                                          @click="openEventInfo(event)"
                                        >
                              <span class="font-weight-medium accent--text text-uppercase text-no-wrap x-small my-auto"
                              >
                                {{event.step_event.split('.')[0]}}
                              </span>
                                            <span class="text-no-wrap small my-auto">| {{
                                                    event.step_event.split('.')[1]
                                                }}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row
                                  v-if="!small"
                                  class="ma-0"
                                  dense
                                >
                                    <span class="grey--text">{{getEventDescription(event.event_code)}}</span>
                                </v-row>
                                <event-overview
                                  v-if="!small"
                                  :event="event"
                                ></event-overview>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-col>

            </v-row>
        </v-timeline>
    </v-col>
</template>

<script>
import service_user from '@/services/service_user'
import {P_INSTANCE_EVENT_DETAILS} from '@/constants/group_permissions'
import EventOverview from '@/components/explorers/instances/instance_details/components/EventOverview'
import helpers from '@/plugins/helpers'

export default {
    name: 'EventsTimeline',
    components: {EventOverview},
    props: {
        small: Boolean,
        events: Object,
        instance_events: Array,
        eventSelected: Object
    },
    data() {
        return {
            P_INSTANCE_EVENT_DETAILS,
            service_user,
            step_events: require('@/assets/resources/config_files/step_events').default,
        }
    },
    methods: {
        getEventDescription(val) {
            let parent = this.step_events[val.split('.')[0]]
            let event = null
            if (!helpers.nullOrEmpty(parent)) {
                event = parent.events[val.split('.')[1]]
                if (!helpers.nullOrEmpty(event)) {
                    return event.description
                }
            }
            return 'Sin descripción'

        },
        getEventTreatment(step_event) {
            if (this.instance_events) {
                let template_event = this.instance_events.find(te => te.name.toUpperCase() == step_event.replace(/@[\d]+/, '').toUpperCase())
                if (template_event != null) {
                    if (template_event.treatment == 3) {
                        return {
                            name: 'terminal',
                            color: 'error',
                            icon: 'lock'
                        }
                    }
                    if (template_event.treatment == 2) {
                        return {
                            name: 'macroestate',
                            color: 'info',
                            icon: 'done_all'
                        }
                    }
                    if (template_event.treatment == 1) {
                        return {
                            name: 'milestone',
                            color: 'primary',
                            icon: 'check'
                        }
                    }

                }

            }
        },
        getEventColor(val) {
            let parent = this.step_events[val.split('.')[0]]
            if (parent != null) {
                let event = parent.events[val.split('.')[1]]
                if (event == null) {
                    return 'gray'
                } else {
                    return event.color
                }
            }
            return 'gray'
        },
        openEventInfo(event) {
            this.$emit('eventClick', event)
        },
    }
}
</script>

<style lang="scss">

@import "../../../../../assets/styles/resources/variables";
@import "../../../../../assets/styles/resources/colors";

.cb-events-timeline .v-timeline-item {
    position: relative;
    padding-bottom: 0;
}

.cb-events-timeline .cb-timeline-date-container {
    position: sticky;
    top: 0px;
    background: linear-gradient(180deg, $color_app_clear, rgba(255, 255, 255, 0) 75%);
    z-index: 1;
}

.cb-events-timeline-compact .cb-timeline-date-container {
    position: relative;
    background: transparent !important;
}

.cb-events-timeline {
    padding-top: 0;
    max-height: 70vh;
    overflow: auto;
}

.cb-events-timeline-compact {
    padding-top: 5px !important;
}

.cb-events-timeline-compact .v-timeline-item {
    padding-top: 0px;
    padding-bottom: 0px;
}

.cb-events-timeline-compact .v-timeline-item__dot--small {
    margin-top: 5px;
}

.cb-events-timeline-compact .v-timeline-item__opposite {
    top: -2px !important;
}


.cb-events-timeline .v-timeline-item__dot--small {
    height: 14px !important;
    width: 14px !important;
    z-index: 0;
    margin-right: -41px;
}

.cb-events-timeline-compact .v-timeline-item__dot--small {
    height: 14px !important;
    width: 14px !important;
    z-index: 0;
    margin-right: -46px;
}

.cb-events-timeline .v-timeline-item__inner-dot, .v-timeline-item__dot {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.cb-events-timeline .v-timeline-item__inner-dot i {
    font-size: $icon-size-title !important;
}

.cb-events-timeline .v-timeline-item__divider {
    padding-top: 5px;
    min-width: 35px !important;
    align-items: baseline;
    margin-left: 131px;
    border-right: 1px dashed $color_app_contrast_medium;
}


.cb-events-timeline .v-timeline-item__opposite {
    flex: none;
    position: absolute;
    left: 90px;
    top: -2px;
    display: block !important;
    margin-top: 3px;
}

.cb-events-timeline-compact .v-timeline-item__opposite {
    display: inline-block;
    min-width: 61px !important;
    align-items: baseline;
    left: 40px;
    top: 6px;
    font-size: $font-size-4 !important;
}

.cb-events-timeline-compact .cb-timeline-date-container .v-timeline-item__opposite {
    top: 0;
}

.cb-events-timeline-compact .v-timeline-item__divider {
    margin-top: 0px;
    min-width: 40px !important;
    align-items: baseline;
    margin-left: 80px;
}

/* line */
.v-application--is-ltr .cb-events-timeline .v-timeline--dense:not(.v-timeline--reverse):before {
    display: none;
}

.v-application--is-ltr .cb-events-timeline-compact:not(.v-timeline--reverse):before {
    display: none;
}

.v-application--is-ltr .cb-events-timeline-compact:not(.v-timeline--reverse):before {
    margin-top: 46px;
    left: 115px !important;
}

.opposite-width-force {
    display: inline-block;
    width: 95px;
    max-width: 95px;
    text-align: right;
}

.cb-event-icon {
    margin-top: -2px;
    font-size: $icon-size-5 !important;
}

.cb-timeline-event-row {
    border-left: 4px solid transparent;
    padding-left: 36px;
}

.cb-events-timeline-compact .v-timeline-item .cb-item-event:hover span {
    color: $color_app_bg_accent_hover !important;
}

.cb-events-timeline-compact .v-timeline-item {
    cursor: pointer;
}


.cb-event-icon-timeline {
    width: 10px;
    height: 4px;
    position: absolute !important;
    top: 10px;
    left: -24px;
    font-size: 20px !important;
}

.cb-event-row-compact {
    padding-top: 5px;
    padding-bottom: 5px;
}

.cb-event-selected span {
    color: $color_app_bg_accent !important;
    font-weight: bold !important;
}

.cb-event-selected span {
    color: $color_app_bg_accent !important;
}

.cb-timeline-date-container {
    height: 50px;
}

.cb-events-timeline-compact .cb-timeline-date-container {
    height: 40px;

}

.cb-timeline-date {
    margin-left: -80px !important;
}

.cb-events-timeline-compact .cb-timeline-date {
    margin-left: -16px !important;
}

.cb-timeline-date span {
    text-transform: capitalize;
    font-size: $font-size-2 !important;
}

.cb-timeline-date span:last-child {
    font-size: $font-size-3 !important;
}

</style>
