<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="color != null ? color : 'primary'"
              :style="title?'width:auto !important':''"
              :title="text"
              class="cb-button-expandable"
              outlined
              primary
              v-bind="attrs"
              @click="$emit('click')"
              v-on="on"
            >
                <v-icon>{{icon}}</v-icon>
                <span v-if="title" class="ml-2">{{title}}</span>
            </v-btn>
        </template>
        <span
          class="mr-2"
        >{{text}}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'ButtonIconTooltip',
    props: {
        title: String,
        text: String,
        icon: String,
        color: String,
    }
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";

.cb-button-expandable {
    height: 28px !important;
    width: 28px !important;
    min-width: 28px !important;
    background-color: $color_app_clear;

    & i {
        margin-right: 0 !important;
    }

    transition: width 1s ease !important;

}

</style>
