const constants = {
    GLOBAL: 'global',
    PROCESSES: 'processes',
    MANAGEMENT: 'management',
    INSTANCES: 'instances',
    TEMPLATES: 'templates',
    BILLING_UNITS: 'billing_units',
    HOME: 'home',

    COMPACT_MODE: 'compact_mode',
    DEFAULT_VIEW: 'home',

    ASSOCIATED_PROCESSES: 'associated_processes',
    PERSONAL_NOTES: 'personal_notes',

    TABLE_COLS: 'table_cols',
    TABLE_RPP: 'table_rpp',
    TABLE_DATES: 'table_dates',
    TABLE_ENTITIES: 'table_entities',

    DATE_ABSOLUTE: 'date_absolute',
    DATE_RELATIVE: 'date_relative',

    ENTITY_NAME: 'name',
    ENTITY_CODE: 'code',
    ENTITY_BOTH: 'both',

    FAV_PROCESSES: 'fav_processes',
    INSTANCES_SAVED_SEARCH: 'instance_saved_search',

    HOME_LAYOUT: 'home_layout',
    HOME_ACTIVE_COMPONENTS: 'home_active_components',

    HOME_VIEW_ACTIVE: 'home_view_active',

}

const values = {
    [constants.TABLE_DATES]: {
        [constants.DATE_ABSOLUTE]: 'absolutas',
        [constants.DATE_RELATIVE]: 'relativas'
    },
    [constants.TABLE_ENTITIES]: {
        [constants.ENTITY_NAME]: 'nombre',
        [constants.ENTITY_CODE]: 'código',
        [constants.ENTITY_BOTH]: 'ambas'
    }
}

export default {
    constants,
    values
}
