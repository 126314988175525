export default Object.freeze({
  ENTITY_NAME: 'billing_unit',
  ID: 'id',
  NAME: 'name',
  CODE: 'code',
  DESCRIPTION: 'description',

  MASTER_ACCOUNT_NAME: 'masteraccount_name',
  MASTER_ACCOUNT: 'masteraccount_code',

  USERS: 'users',
  PROCESSES_DEFAULT: 'processes_default'
})
