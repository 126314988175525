import Vue from 'vue'
import model_grafana from '@/constants/models/model_grafana'

export default {
    downloadFromS3(hash) {
        Vue.axios
            .get(process.env.VUE_APP_ENDPOINT + 'events/getfilefroms3/' + btoa(hash))
            .then((response) => {
                if (response.data.error === 0) {
                    window.open(response.data.message, '_blank')
                }
            })
    },
    getGrafanaPanel(id, folder, refresh, from = 'now-1h', to = 'now', filter_var = '', dashboard = false) {
        let env = 'Certysign_' + Vue.prototype.$env.name.toUpperCase()
        let dashboardEnabled = dashboard ? '&kiosk=tv' : ''
        let d = dashboard ? 'd' : 'd-solo'
        let url = model_grafana.URL + '/' + d + '/' + folder + '?orgId=1&from=' + from + '&to=' + to + '&refresh=' + refresh + dashboardEnabled + '&theme=light&var-ENV=' + env + filter_var + '&panelId=' + id
        return url
    }
}
