<template>
  <v-dialog
    v-model="open"
    :max-width="width"
    transition="dialog-bottom-transition"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card class="cb-edit-dialog">
      <card-title
        :close="true"
        :title="title != null ? title :'Detalles del json'"
        icon="code"
        mode="dense"
        @close="$emit('close')"
      />
      <v-card-text
        class="pa-0 pt-0"
        style="padding-top: 0px !important;"
      >
        <div
          v-if="errors != null && errors.length !=0 "
          :style="{
            'background-color': '#fbe3e4',
            'border-color': '#f8b3b5',
            'color': '#f44336',
          }"
          class="cb-error-div "
        >
          <v-col>
            <v-row class="justify-space-between">
              <span>
              <v-icon
                class="mr-2"
                color="error"
              >warning
              </v-icon>
              Se han detectado errores
              </span>
              <v-icon @click="$emit('closeErrors')">close</v-icon>
            </v-row>
            <v-divider class="my-2"/>
            <v-row
              v-for="error in errors"
              :key="error"
              class="mb-2"
            >
              <span class="cb-font-size-4"><v-icon
                class="mr-2"
                x-small
              >circle</v-icon>{{error}}</span>
            </v-row>
          </v-col>
        </div>
        <v-jsoneditor
          v-model="internalJson"
          :height="height"
          :options="options"
        />
      </v-card-text>
      <v-card-actions v-if="save">
        <v-row class="justify-space-between">
          <div>
            <v-row>
              <v-btn
                :disabled="changeLog && changeLogMessage.length < 25"
                class="cb-btn-save my-auto"
                depressed
                small
                @click="saveJson"
              >
                <v-icon
                  class="mr-2"
                  small
                >save
                </v-icon>
                Guardar
              </v-btn>
              <v-text-field
                v-if="changeLog"
                v-model="changeLogMessage"
                class="ml-4 cb-changeLog-message"
                hide-details
                outlined
                placeholder="Changelog"
              />
            </v-row>
          </div>
          <v-btn
            class="cb-btn-close my-auto"
            depressed
            small
            @click="$emit('close')"
          >Cancelar
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CardTitle from '@/components/repository/cards/CardTitle'

export default {
  name: 'ShowJsonDialog',
  components: {CardTitle},
  props: {
    json: Object,
    mode: {
      type: String,
      default: 'preview'
    },
    width: {
      type: String,
      default: '900px'
    },
    height: {
      type: String,
      default: '500px'
    },
    save: {
      type: Boolean,
      default: false
    },
    changeLog: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      open: true,
      options: {
        mode: this.mode,
      },
      internalJson: this.json,
      changeLogMessage: '',
    }
  },
  methods: {
    saveJson() {
      if (this.changeLog) {
        this.$emit('saveWithChangelog', {json: this.internalJson, changeLog: this.changeLogMessage})
      }
      this.$emit('save', this.internalJson)
    }
  }
}
</script>

<style scoped>
.cb-error-div {
  position: absolute;
  top: 74px;
  right: 10px;
  width: 300px;
  height: auto;
  z-index: 1;
  border-style: solid;
  border-width: 2px;
}
</style>
