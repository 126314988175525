<template>
  <v-row class="pa-0 ac-card-selectable">

    <v-col
      class="my-auto"
      cols="3"
    >
      <v-row class="justify-center">
        <v-icon
          class="mr-2"
          color="primary"
        >{{ icon }}
        </v-icon>
      </v-row>
      <v-row class="justify-center">
        <v-col class="text-center pa-0">
          <span class="font-weight-medium my-auto">{{ title }} </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="8">
      <v-row
        class="ac-row-border-left ml-2"
      >
        <v-col class="pl-4">
          <v-row
            v-for="(row, index) in rows"
            :key="index"
            class="my-2"
          >
            <span class=" mr-2">
             {{ row.name }}
            </span>
            <span
              :class="row.color+'--text'"
              class="font-weight-medium"
            >
             {{ row.value }}
            </span>
            <v-icon
              v-if="row.alert && !row.error"
              class="ml-2"
              color="warning"
              style="font-size: 13px !important;"
            >{{ $icons.I_ALERT }}
            </v-icon>
            <v-icon
              v-if="row.error"
              class="ml-2"
              color="error"
              style="font-size: 13px !important;"
            >{{ $icons.I_ERROR }}
            </v-icon>
          </v-row>

        </v-col>
      </v-row>
    </v-col>

  </v-row>
</template>

<script>
export default {
  name: 'CardInfoSectionWithIcon',
  props: {
    title: String,
    icon: String,
    rows: Array
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/resources/colors";

.ac-card-selectable:hover {
  background-color: $color_app_contrast_low;
}

.ac-row-border-left {
  border-left: 2px solid $color_app_primary;
}
</style>