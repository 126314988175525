<template>
  <v-row class="t-sidebar-standard-row">
    <v-col
      :cols="dense ? '6':'4'"
    >
      <span class="">{{ name }}</span>
    </v-col>
    <v-col
      :class="dense? 'text-right':''"
      :cols="dense ? '6':'8'"
    >
      <span
        :class="color+'--text'"
        class="font-weight-medium"
        v-html="value"
      />
      <v-btn
        v-if="link"
        :title="'Abrir ' + name "
        class="ma-0 ml-1"
        color="primary"
        icon
        x-small
        @click="openRowLink"
      >
        <v-icon style="font-size: 14px !important;">open_in_new</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'rightSidebarStandardRow',
  props: {
    name: String,
    value: [Number, String],
    link: String,
    dense: Boolean,
    color: String,
  },
  methods: {
    openRowLink() {
      this.$emit('linkLaunched', {
        link: this.link,
        value: this.value
      })
    }
  }
}
</script>
