<template>
  <v-card
    class="upload-area "
    flat
    style="border: none !important"
    @click.prevent="$emit('click')"
  >
    <v-card-text
      class="pa-0"
      style="height: 100%; width: 100%"
    >
      <v-row
        align="center"
        class="pa-0"
        justify="center"
        style="height: 100%; width: 100%"
      >
        <v-col>
          <slot/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FileUploadArea',

  data() {
    return {
      dragover: false
    }
  },

  mounted() {
    const uploadAreaElement = document.querySelector('.upload-area')
    uploadAreaElement.addEventListener('drop', this.onDrop)
    uploadAreaElement.addEventListener('dragover', this.onDragOver)
    uploadAreaElement.addEventListener('dragleave', this.onDragLeave)
  },


  methods: {
    onDragOver(event) {
      event.preventDefault()
      this.dragover = true
    },

    onDragLeave(event) {
      event.preventDefault()
      this.dragover = false
    },

    onDrop(event) {
      event.preventDefault()
      this.dragover = false

      const files = event?.dataTransfer?.files

      this.$emit('onDrop', files)

    }
  }

}
</script>

<style
  lang="scss"
>
@import "../../../src/assets/styles/resources/colors";

.upload-area {
  height: 100%;
  background-color: rgba($color_app_contrast_low, 0.2);
}
</style>
