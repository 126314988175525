<template>
  <v-dialog
    v-model="visible"
    max-width="600"
    transition="dialog-bottom-transition"
  >
    <v-card class="cb-edit-dialog">
      <card-title
        :close="true"
        :title="title"
        icon="create"
        @close="$emit('close')"
      />

      <v-card-text>
        <v-row v-if="mode == 'create_new_folder'">
          <v-col cols="12">
            <span>Crear nuevo directorio</span>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="inputValue"
              clearable
              hide-details
              outlined
              placeholder="Nombre de la nueva carpeta"
              type="text"
              validate-on-blur
            />
          </v-col>
        </v-row>

        <v-row v-if="mode == 'upload_files'">
          <v-col cols="12">
            <span>Subir nuevos ficheros</span>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="inputValue"
              clearable
              counter
              hide-details
              multiple
              outlined
              placeholder="Seleccionar fichero(s)"
              validate-on-blur
            />
          </v-col>
        </v-row>

        <v-row v-if="mode == 'confirm_delete'">
          <v-col
            class="py-4"
            cols="12"
          >
            <p class="cb-font-size-3">Si confirmas la eliminación del fichero, ya no se podrá recuperar</p>
            <v-row class="mb-2">
              <v-col cols="3">
                <span class="cb-font-size-3">Entorno: </span>
              </v-col>
              <v-col>
                <span
                  :style="'color:'+$env.color"
                  class="cb-font-size-3 font-weight-medium"
                > {{ $env.complete_name }}</span>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col cols="3">
                <span class="cb-font-size-3">Recurso/s:</span>
              </v-col>
              <v-col>
                <span
                  class="cb-font-size-3 font-weight-medium"
                  v-html="extra"
                > </span>
              </v-col>
            </v-row>
            <v-divider class="mb-4"/>
            <v-row justify="center">
              <span class="cb-font-size-3">¿Confirmar la <strong class="error--text">eliminación</strong> ?</span>
            </v-row>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-row class="justify-space-between">
          <v-btn
            class="cb-btn-save my-auto"
            depressed
            small
            @click="$emit(mode, inputValue)"
          >
            <span v-if="mode == 'confirm_delete'">Confirmar</span>
            <span v-else>Crear</span>
          </v-btn>

          <v-btn
            class="cb-btn-close"
            depressed
            small
            @click="$emit('close')"
          >
            Cancelar
          </v-btn>
        </v-row>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import CardTitle from '@/components/repository/cards/CardTitle'

export default {
  name: 'NewResourceDialog',
  props: {
    extra: String,
    mode: {
      type: String
    }
  },
  components: { CardTitle },

  data() {
    return {
      visible: true,
      inputValue: null
    }
  },

  computed: {
    title() {
      return this.mode == 'confirm_delete' ? 'Confirmar eliminación' : 'Nuevo recurso'
    },

  }
}
</script>

<style
  lang="scss"
  scoped
>

</style>
