<template>
  <div
    :style="'height:' + height +';width:'+width+';max-height:' + height +';max-width:'+width+';fill:'+dynamicColor"
    class="appc-icon"
  >
    <component
      :is="dynamicIcon"
    />
  </div>
</template>

<script>
import helpers from '@/plugins/helpers'

export default {
  name: 'app-center-icon',
  props: {
    name: String,
    width: {
      default: '24px',
      type: String
    },
    height: {
      default: '24px',
      type: String
    },
    color: {
      default: 'primary',
      type: String
    },
  },
  computed: {
    dynamicIcon() {
      if (this.name) {
        return () => import('../../assets/icons/' + this.name + '.svg')
      }
      return null
    },
    dynamicColor() {
      let theme = this.$vuetify.theme.isDark ? 'dark' : 'light'
      let icon_color = this.$vuetify.theme.themes[theme][this.color]
      if (helpers.nullOrEmpty(icon_color)) {
        return this.color
      } else {
        return icon_color
      }
    }
  },
}
</script>

<style scoped>
.appc-icon {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}

.appc-icon svg {
  max-height: 100% !important;
  max-width: 100% !important;
}
</style>
