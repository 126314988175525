<template>
  <v-container>
    <iframe
      id="cts-preview-html-container"
      :srcdoc="displayContent"
      class="iframe-preview"
      title="html-viewer"
    >
    </iframe>
  </v-container>
</template>

<script>

export default {
  name: 'htmlViewer',
  components: {},
  props: {
    displayContent: String
  }
}
</script>
<style>
.iframe-preview {
  width: 100%;
  height: 400px;
}
</style>

