/* Language */
import es from '../../../src/assets/locales/es'

const location = {
  availableLocations: [
    {
      code: 'es',
      name: 'Español',
    },
  ],
  currentLocation: 'es',
}
const locationsContent = { es }
const getters = {
  getLocation () {
    return location
  },
  getLocationsContent () {
    return locationsContent
  },
}
const mutations = {
  setNewLocation (location, newLocation) {
    location.currentLocation = newLocation
  },
}
export default {
  namespaced: true,
  mutations,
  getters,
}
