export default Object.freeze({
  ENTITY_NAME: 'appearance',
  CODE: 'code',
  NAME: 'name',
  PROCESSES: 'processes',
  TEXT_WIDTH_PERCENT: 'text_width_percent',
  TEXT_COLOR: 'text_color',
  HOVERED_TEXT_COLOR: 'hovered_text_color',
  HOVERED_BACKGROUND_TEXT_COLOR: 'hovered_background_text_color',
  BUTTON_A_BACKGROUND_COLOR: 'button_a_background_color',
  BUTTON_A_TEXT_COLOR: 'button_a_text_color',
  BUTTON_A_BACKGROUND_COLOR_HOVERED: 'button_a_background_color_hovered',
  BUTTON_A_TEXT_COLOR_HOVERED: 'button_a_text_color_hovered',
  BUTTON_B_BACKGROUND_COLOR: 'button_b_background_color',
  BUTTON_B_TEXT_COLOR: 'button_b_text_color',
  BUTTON_B_BACKGROUND_COLOR_HOVERED: 'button_b_background_color_hovered',
  BUTTON_B_TEXT_COLOR_HOVERED: 'button_b_text_color_hovered',
  LOGO: 'logo',
  FAVICON: 'favicon',
  HEADER_HEIGHT: 'header_height',
  HEADER_BORDER_BOTTOM_WIDTH: 'header_border_bottom_width',
  HEADER_BORDER_BOTTOM_COLOR: 'header_border_bottom_color',
  HEADER_BACKGROUND_COLOR: 'header_background_color',
  GOOGLE_FONT: 'google_font',
  BACKGROUND_COLOR: 'background_color',
  BACKGROUND_IMAGE: 'background_image',
  MAIN_TEXT_BOX_COLOR: 'main_text_box_color',
  MAIN_TEXT_BOX_BORDER_COLOR: 'main_text_box_border_color',
  MAIN_TEXT_BOX_BORDER_WIDTH: 'main_text_box_border_width',
  LANGUAGE_DROPDOWN_BUTTON_COLOR: 'language_dropdown_button_color',
})
