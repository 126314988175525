export default {
  show_shortkeys_helpers: {
    name: 'Ayuda de comandos',
    description: 'Muestra el diálogo de ayuda de los accesos directos',
    command: ['alt', 'f1'],
  },
  search_bar: {
    name: 'Buscar en APP Center',
    description:
      'Activa el foco en la búsqueda de APP Center de la barra superior',
    command: ['alt', 's'],
  },
  view: {
    name: 'Ir al buscador de páginas',
    description: 'Ve a al buscador de páginas',
    command: ['alt', 'v'],
  },
  web_navigator: {
    name: 'Volver o avanzar',
    description: 'Ve a una vista usando el teclado',
    command: ['alt', 'IZQ. o DER.'],
  },
}
