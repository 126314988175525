export default Object.freeze({
  ENTITY_NAME: 'group',
  ID: 'id',
  NAME: 'name',
  DESCRIPTION: 'description',
  PERMISSIONS: 'permissions',

  PERM_ID: 'id',
  PERM_DEPENDS: 'depends',
  PERM_ID_ESPECIAL: 'permission_id',
  PERM_KEY: 'key',
  PERM_NAME: 'name',
  PERM_DESCRIPTION: 'description'
})
