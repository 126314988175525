export default Object.freeze({
  ENTITY_NAME: 'user',
  ID: 'id',
  NAME: 'name',
  USERNAME: 'username',
  PASSWORD: 'password',

  CONFIG: 'config',

  GROUP_ID: 'group_id',
  GROUP_NAME: 'group_name',
})
