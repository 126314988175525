const constants = Object.freeze({
  NAMESPACE: 'vuex_b2b',

  S_SEARCH_FILTERS_USERS: 'state_search_filters_users',
  S_SEARCH_FILTERS_GROUPS: 'state_search_filters_groups',

  M_SET_SEARCH_FILTERS_USERS: 'mutation_set_search_filters_users',
  M_SET_SEARCH_FILTERS_GROUPS: 'mutation_set_search_filters_groups',

  A_SET_SEARCH_FILTERS_USERS: 'action_set_search_filters_users',
  A_SET_SEARCH_FILTERS_GROUPS: 'action_set_search_filters_groups'
})

const state = {
  [constants.S_SEARCH_FILTERS_USERS]: {},
  [constants.S_SEARCH_FILTERS_GROUPS]: {},

}

const mutations = {

  [constants.M_SET_SEARCH_FILTERS_USERS](state, payload) {
    state[constants.S_SEARCH_FILTERS_USERS] =
      payload
  },
  [constants.M_SET_SEARCH_FILTERS_GROUPS](state, payload) {
    state[constants.S_SEARCH_FILTERS_GROUPS] =
      payload
  },
}

const actions = {

  [constants.A_SET_SEARCH_FILTERS_USERS](state, users) {
    state.commit(constants.M_SET_SEARCH_FILTERS_USERS, users)
  },
  [constants.A_SET_SEARCH_FILTERS_GROUPS](state, groups) {
    state.commit(constants.M_SET_SEARCH_FILTERS_GROUPS, groups)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  constants,
}
