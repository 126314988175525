<template>
    <v-container>
        <ExplorerFiltersRow
          :actions="actions"
          :model="modelName"
          :response-filters="responseFilters"
          :search="true"
          @[$events.EXPLORER_REMOVE_FILTER]="removeFilter"
          @[$events.EXPLORER_SEARCH]="searchByText"
          @[$events.EXPLORER_ACTION_CLICKED]="actionClicked"
        />
        <v-row>
            <DataTable
              :data="dataTableData"
              :data-table-options="dataTableOptions"
              :headers="dataTableHeaders"
              :loading="loading"
              :model="modelName"
              @open_processes="openProcesses"
              @[$events.DATA_TABLE_REFRESH]="find"
              @[$events.DATA_TABLE_OPEN]="openItem"
            />
        </v-row>
        <ExportDataTable
          v-if="showExportDialog"
          :fields="dataTableHeaders"
          :model="modelName"
          :response_filters="responseFilters"
          :service="service_protocol.findCommstrackerDomains"
          @close="showExportDialog=false"
        />
        <CommstrackerDomainCreateOrUpdateDialog
          v-if="itemSelected != null"
          :commstracker-domain="itemSelected"
          :fields="dataTableHeaders"
          :response_filters="responseFilters"
          @close="closeItem()"
          @refresh="find()"
        />
    </v-container>
</template>

<script>
import helpers_resolution from '@/plugins/helpers_resolution'
import service_protocol from '@/services/service_protocol'
import service_user from '@/services/service_user'
import ExplorerFiltersRow from '@/components/repository/explorers/ExplorerFiltersRow'
import DataTable from '@/components/repository/tables/DataTable'
import {
    P_EXPORT,
    P_PROCESSES,
    P_COMMSERVICES_COMMSTRACKER_EDIT
} from '@/constants/group_permissions'
import ExportDataTable from '@/components/repository/tables/dataTable/ExportDataTable'
import vuex_protocol from '@/store/modules/sections/vuex_protocol'
import {datatableMixin} from '@/mixins/dataTable'
import store from '@/store'
import icons from '@/constants/icons'
import CommstrackerDomainCreateOrUpdateDialog
    from '@/components/commservices/commstracker/CommstrackerDomainCreateOrUpdateDialog.vue'
import service_processes from '@/services/service_processes'
import model_process from '@/constants/models/model_process'

const modelName = 'Dominio Commstracker'
const dataTableConfig = {
    options: {
        rpp: helpers_resolution.getTableRowsPerPage(),
        sortDesc: [false],
        page: 1,
        sortBy: ['domain']
    },
    finder: service_protocol.findCommstrackerDomains,
    vuex: {
        state: store.state[vuex_protocol.constants.NAMESPACE][vuex_protocol.constants.S_COMMSTRACKER_DOMAINS_SEARCH_FILTERS],
        setter: vuex_protocol.constants.NAMESPACE + '/' + vuex_protocol.constants.A_SET_COMMSTRACKER_DOMAINS_SEARCH_FILTERS
    }
}

export default {
    name: 'CommstrackerDomainsView',
    components: {
        CommstrackerDomainCreateOrUpdateDialog,
        ExportDataTable,
        DataTable,
        ExplorerFiltersRow
    },
    mixins: [datatableMixin(dataTableConfig)],
    data() {
        return {
            service_protocol,
            modelName,
            dataTableHeaders: [
                {
                    text: 'Id',
                    value: 'id',
                    hide: true,
                    align: ' d-none'
                },
                {
                    text: 'Dominio',
                    value: 'domain',
                    sortable: true,
                    type: 'link',
                    width: '20%'
                },
                {
                    text: 'Descripción',
                    value: 'description',
                    sortable: true,
                    width: '26%'
                },
                {
                    text: 'URL Callback',
                    value: 'callback_url',
                    sortable: true,
                    width: '20%'
                },
                {
                    text: 'Redirección por defecto',
                    value: 'default_redirect',
                    sortable: true,
                    width: '20%'
                },
                {
                    text: 'Bloqueado',
                    value: 'blocked',
                    sortable: true,
                    width: '14%'
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                    custom: true,
                    width: '40px',
                    actions: () => {

                        let entities = []

                        entities.push({
                            id: 'data_table_open',
                            icon: icons.I_VIEW,
                            title: 'Ver detalles',
                            enabled: true,
                        })
                        if (service_user.checkGroupPermission(P_PROCESSES)) {

                            entities.push({
                                id: 'open_processes',
                                icon: icons.I_PROCESS,
                                title: 'Ver procesos',
                                enabled: true,
                            })
                        }
                        return entities

                    }
                },
            ],
        }
    },
    computed: {
        actions() {
            let entities = []
            if (service_user.checkGroupPermission(P_EXPORT)) {
                entities.push({
                    id: 'export',
                    icon: this.$icons.I_EXPORT,
                    text: 'Exportar datos',
                    enabled: true,
                })
            }
            if (service_user.checkGroupPermission(P_COMMSERVICES_COMMSTRACKER_EDIT)) {
                entities.push({
                    id: 'new',
                    icon: this.$icons.I_COMMSTRACKER,
                    text: 'Nuevo dominio',
                    enabled: true,
                })
            }
            return entities
        }
    },
    methods: {
        actionClicked(action_id) {
            if (action_id === 'export') {
                this.showExportDialog = true
            }
            if (action_id === 'new') {
                this.openItem('new')
            }
        },
        openProcesses(item) {
            service_processes.openProcesses({
                'OR': {
                    [model_process.COMMSTRACKER_EMAIL]: item.domain,
                    [model_process.COMMSTRACKER_SMS]: item.domain
                }
            })
        },
    },
    filters: {}
}
</script>
